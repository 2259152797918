<template>
  <div 
    v-if="widgetData.properties.visibility" 
    class="widget"
  >
    <div
      :style="icon(widgetData.nodeFields[0])"
      class="widget__icon"
    ></div>
    <div 
      v-html="widgetData.nodeFields[1].properties.text"
      class="widget__text"
    ></div>
    <div
      :style="icon(widgetData.nodeFields[2])"
      class="widget__icon"
    ></div>
  </div>
</template>

<script>
export default {
    name: "PK_widgetChatTopBarCenterPageName",
    props: {
        widgetData: {
            type: Object
        }
    },
    methods: {
      icon(field){
        return {
          backgroundImage: field.properties.visibility 
            ? 'url(' + this.$staticUrl + field.properties.imageUrl + ')' 
            : ''
        }
      }
    }
}
</script>

<style lang="scss" scoped>
.widget{
  align-items: center;
  position: relative;
  justify-content: space-between;
  display: flex;
  gap: 8px;

  &__icon{
    overflow:hidden; 
    background-size: contain; 
    background-position: center;
    height: 30px;
    
    &:nth-child(1){
      flex-grow: 1;
      background-position: left !important;
    }
    &:nth-child(3){
      flex-grow: 1;
      background-position: right !important;
    }
  }
  &__text{
      flex-grow: 2;
      text-align: center;
  }
}

</style>