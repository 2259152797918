<template>
  <BuilderElement
      v-if="element.nodeType?.toLowerCase() !== 'wrapper'"
      :element="element"
      :data-id="element.id"
      :data-type="element.nodeType"
      :data-name="element.name || element.nodeType"
      class="builder-element page-builder__placeholder"
      :class="activeElementId === element.id ? 'active' : ''"
  />
  <div
    v-else
    @click.stop="openSettings(element)"
    class="page-builder__placeholder gray-placeholder"
    :class="activeElementId === element.id ? 'active' : ''"
    :data-type="element.nodeType"
    :key="element.id"
  >
    <draggable
      v-model="element.nodeFields"
      :move="checkMove"
      :options="bodyOptions"
      :style="correctStyles(element?.styles)"
      @change="changeElementPosition($event, element, element.id)"
      class="page-builder__dropzone page-builder__dropzone_wrapper"
      :class="element.class"
    >
      <div
        v-if="elem"
        v-for="elem in element.nodeFields"
        :key="uuid(elem)"
        style="width: 100%"
        @click.stop="openSettings(elem)"
      >
        <BuilderWrapper
          v-if="elem.hasOwnProperty('nodeFields')"
          :element="elem"
          class="builder-wrapper page-builder__placeholder gray-placeholder"
          :data-type="elem.nodeType"
          :data-id="elem.id"
          :class="activeElementId == elem.id ? 'active' : ''"
        />
        <BuilderElement
          v-else
          :element="elem"
          :data-id="elem.id"
          :data-name="element.name || element.nodeType"
          class="builder-element page-builder__placeholder"
          :data-type="elem.nodeType"
          :class="activeElementId == elem.id ? 'active' : ''"
        />
      </div>
    </draggable>
  </div>
</template>

<script>
import BuilderElement from "@/components/page-builder/BuilderElement.vue";
import draggable from "vuedraggable";
import pageBuilder from "@/mixins/pageBuilder";

import {EventBus} from "@/utils/event-bus";

export default {
  name: "BuilderWrapper",
  components: {draggable, BuilderElement},
  mixins: [pageBuilder],
  props: {
    element: {
      type: Object,
      required: true,
    }
  },
  methods: {
    openSettings(element) {
      EventBus.$emit("openSettings", element);
    },
    async replaceNode(nodePart, partId) {
      EventBus.$emit('changeElementPosition', nodePart, partId);
    }
  },
  computed: {
    activeElementId() {
      return this.$store.getters["pageBuilder/activeElementSettings"];
    }
  },
}
</script>

<style lang="scss" scoped>
.page-builder__placeholder {
  position: relative;

  &::before {
    content: attr(data-name);
    display: block;
    position: absolute;
    top: -20px;
    right: -1px;
    font-size: 11px;
    padding: 2px 4px;
    color: #888888;
    background-color: rgba(246, 246, 246, 0.7);
    border-radius: 4px 4px 0 0;
  }

  &:hover {
    z-index: 1;
    box-shadow: 0 0 0 1px #1a65ce;

    &::before {
      content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='10' viewBox='0 0 10 10'%3E%3Cpath fill='%231a65ce' d='M9.414 9.414L3.73 9.414 3.73 3.73 9.414 3.73zM2.156 7.844L2.156 2.156 7.844 2.156 7.844 3.145 3.438 3.145C3.273 3.145 3.145 3.273 3.145 3.438L3.145 7.844zM0.586 6.27L0.586 0.586 6.27 0.586 6.27 1.57 1.863 1.57C1.703 1.57 1.57 1.703 1.57 1.863L1.57 6.27zM9.707 3.145L8.43 3.145 8.43 1.863C8.43 1.703 8.297 1.57 8.137 1.57L6.855 1.57 6.855 0.293C6.855 0.133 6.727 0 6.562 0L0.293 0C0.133 0 0 0.133 0 0.293L0 6.562C0 6.727 0.133 6.855 0.293 6.855L1.57 6.855 1.57 8.137C1.57 8.297 1.703 8.43 1.863 8.43L3.145 8.43 3.145 9.707C3.145 9.867 3.273 10 3.438 10L9.707 10C9.867 10 10 9.867 10 9.707L10 3.438C10 3.273 9.867 3.145 9.707 3.145z'/%3E%3C/svg%3E") "  " attr(data-type);
      color: #1a65ce;
    }

    &.gray-placeholder {
      box-shadow: 0 0 0 1px #3f3f3f;

      &::before {
        content: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='10px' height='10px' viewBox='0 0 10 10' version='1.1'%3E%3Cg id='surface1'%3E%3Cpath style=' stroke:none;fill-rule:nonzero;fill:%237b848f;fill-opacity:1;' d='M 9.0625 0.9375 L 9.0625 9.0625 L 0.9375 9.0625 L 0.9375 0.9375 Z M 10 0 L 0 0 L 0 10 L 10 10 Z M 10 0 '/%3E%3C/g%3E%3C/svg%3E") "  " attr(data-type);
        color: #3f3f3f;
      }
    }
  }


  &.active {
    z-index: 2;
    box-shadow: 0 0 0 1px #1a65ce;

    &::before {
      content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='10' viewBox='0 0 10 10'%3E%3Cpath fill='%23ffffff' d='M9.414 9.414L3.73 9.414 3.73 3.73 9.414 3.73zM2.156 7.844L2.156 2.156 7.844 2.156 7.844 3.145 3.438 3.145C3.273 3.145 3.145 3.273 3.145 3.438L3.145 7.844zM0.586 6.27L0.586 0.586 6.27 0.586 6.27 1.57 1.863 1.57C1.703 1.57 1.57 1.703 1.57 1.863L1.57 6.27zM9.707 3.145L8.43 3.145 8.43 1.863C8.43 1.703 8.297 1.57 8.137 1.57L6.855 1.57 6.855 0.293C6.855 0.133 6.727 0 6.562 0L0.293 0C0.133 0 0 0.133 0 0.293L0 6.562C0 6.727 0.133 6.855 0.293 6.855L1.57 6.855 1.57 8.137C1.57 8.297 1.703 8.43 1.863 8.43L3.145 8.43 3.145 9.707C3.145 9.867 3.273 10 3.438 10L9.707 10C9.867 10 10 9.867 10 9.707L10 3.438C10 3.273 9.867 3.145 9.707 3.145z'/%3E%3C/svg%3E") "  " attr(data-type);
      display: inline-block;
      color: $main-white;
      background-color: #1a65ce;
    }

    &.gray-placeholder {
      box-shadow: 0 0 0 1px #7b848f;

      &::before {
        content: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='10px' height='10px' viewBox='0 0 10 10' version='1.1'%3E%3Cg id='surface1'%3E%3Cpath style=' stroke:none;fill-rule:nonzero;fill:%23ffffff;fill-opacity:1;' d='M 9.0625 0.9375 L 9.0625 9.0625 L 0.9375 9.0625 L 0.9375 0.9375 Z M 10 0 L 0 0 L 0 10 L 10 10 Z M 10 0 '/%3E%3C/g%3E%3C/svg%3E") "  " attr(data-type);
        color: $main-white;
        background-color: #7b848f;
      }
    }
  }
}
</style>