<template>
  <SpinnerLoader
    v-if="loading"
    color="blue"
    class="builder-element-settings__loader"
  />
  <div v-else>
    <p class="builder-element-settings__label"><strong>{{ element.name || element.nodeType }}</strong></p>
    <div class="builder-element-settings__info">
      <p><strong>info</strong></p>
      <div>nodeType: {{ editingElement.nodeType }}</div>
      <div>id: {{editingElement.id}}</div>
      <div v-if="editingElement.name">name: {{editingElement.name}}</div>
    </div>
    <p class="page-builder__settings-title">
      <strong>{{ $t("pageBuilder.options") }}</strong>
    </p>
    <div
      ref="pageBuilderSetting"
      v-for="prop in properties"
      :key="prop.id"
      class="page-builder__setting"
    >
      <div
        v-if="prop.type.toLowerCase() === 'string'"
        class="page-builder__setting-container"
      >
        <label class="page-builder__setting-title">{{ prop.name }}</label>
        <text-editor-quill
          v-model="element.properties[prop.name]"
          :tool-bar-id="`${prop.name}-${prop.id}`"
        />
      </div>

      <div
        v-else-if="prop.type.toLowerCase() === 'color'"
        class="page-builder__setting-container"
      >
        <label class="page-builder__setting-title">{{ prop.name }}</label>
        <div>
          <button
            @click="toggleColorPicker(prop.name)"
            class="color-picker-button"
            :class="[openColorPicker === prop.name ? 'active' : '']"
          >
            <span class="color-picker-container">
              <span
                class="color-circle"
                :style="{ backgroundColor: element.properties[prop.name] }"
              />
              <span>{{ element.properties[prop.name]?.hex }}</span>
            </span>
            <i
              aria-hidden="true"
              class="v-icon notranslate mdi mdi-chevron-down theme--light"
            />
          </button>
          <v-color-picker
            v-model="element.properties[prop.name]"
            v-show="openColorPicker === prop.name"
            width="396"
          />
        </div>
      </div>

      <div
        v-else
        class="page-builder__setting-container"
        :class="`type-${prop.type}`"
        >
        <label class="page-builder__setting-title">{{ prop.name }}</label>
        <component
          :is="elementRefs[prop.type]?.component"
          v-model="element.properties[prop.name]"
          :number="elementRefs[prop.type]?.number"
          :options="prop.refValues"
        />
      </div>

    </div>
    <v-btn color="primary" text @click="addNewProperties">
      <v-icon>
        {{ isAddNewProps ? 'mdi-minus' : 'mdi-plus' }}
      </v-icon>
      new property
    </v-btn>
    <div v-if="isAddNewProps">
      <CreatePropertyModal/>
      <v-text-field
          :label="$t('search')"
          v-model="propertySearch"
      />
      <div class="page-builder__properties">
        <div
          v-for="prop in filteredAllProperties"
          :key="prop.id"
        >
          <p>{{ prop.name }}</p>
          <v-icon
            :color="assignedPropertiesIds.includes(prop.id) ? 'red' : 'success'"
            @click="addProperty(prop)"
          >
            {{ assignedPropertiesIds.includes(prop.id) ? 'mdi-minus' : 'mdi-plus' }}
          </v-icon>
        </div>
      </div>
      <v-btn color="success" :disabled="!assignedProperties.length" text @click="saveNewProperties">
        Save new properties
      </v-btn>
    </div>
    <!-- TODO: integration -->
<!--    <div class="page-builder__setting page-builder__setting-container">-->
<!--      <SpacingEditor />-->
<!--    </div>-->
<!--    <div class="page-builder__setting page-builder__setting-container">-->
<!--      <PositionEditor />-->
<!--    </div>-->
<!--    <div class="page-builder__setting page-builder__setting-container">-->
<!--      <LayoutEditor />-->
<!--    </div>-->
<!--    <div class="page-builder__setting page-builder__setting-container">-->
<!--      <AlignEditor />-->
<!--    </div>-->
    <!---->
    <v-card-actions class="mt-3">
      <v-btn color="success" @click="saveProperties">{{ $t("save") + " props"}}</v-btn>
      <v-btn color="#F44336" style="color: #fff" @click="deleteElement">{{ $t("delete") }}</v-btn>
    </v-card-actions>

    <ConfirmModal ref="confirm" />
  </div>
</template>

<script>
import SpinnerLoader from "@/components/UI/SpinnerLoader.vue";

import textEditorQuill from "@/components/UI/textEditor/textEditorQuill.vue";
import LayoutEditor from "@/components/UI/LayoutEditor.vue";
import SpacingEditor from "@/components/UI/SpacingEditor.vue";
import PositionEditor from "@/components/UI/PositionEditor.vue";
import AlignEditor from "@/components/UI/AlignEditor.vue";

import JsonEditorVue from "json-editor-vue";

import {EventBus} from "@/utils/event-bus";
import ConfirmModal from "@/components/utils/ConfirmModal.vue";
import CreatePropertyModal from "@/components/page-builder/CreatePropertyModal.vue";

export default {
  name: "BuilderElementSettings",
  props: ['editingElement', 'editingProperties', 'loading'],
  components: {
    CreatePropertyModal,
    ConfirmModal,
    JsonEditorVue,
    SpinnerLoader,
    textEditorQuill,
    LayoutEditor,
    SpacingEditor,
    PositionEditor,
    AlignEditor,
  },
  data() {
    return {
      properties: [],
      openColorPicker: '',
      element: null,
      propertySearch: '',
      createDialog: false,
      assignedPropertiesIds: [],
      assignedProperties: [],
      inputEditorOption: {
        modules: {
          toolbar: false,
        }
      },
      isAddNewProps: false,
      elementRefs: {
        "bool": {
          component: "BpmCheckbox",
        },
        "url.image": {
          component: "BpmInput",
          number: false,
        },
        "url": {
          component: "BpmInput",
          number: false,
        },
        "regex":{
          component: "BpmSelect",
        },
        "number":{
          component: "BpmInput",
          number: true,
        },
        "object":{
          component: "JsonEditorVue",
        },
      },
      propsValues: {},
    }
  },
  created() {
    this.rewriteWorkData();
    console.log('q');
  },
  computed: {
    allProperties() {
      const ids = this.editingProperties.map(el => el.id);
      return this.$store.getters["pageBuilder/allProperties"].filter(pr => !ids.includes(pr.id));
    },
    filteredAllProperties() {
      return this.allProperties.filter(prop => prop.name.toLowerCase().includes(this.propertySearch.toLowerCase()));
    },
  },
  methods: {
    update: function(obj, prop, event) {
      this.$set(obj, prop, event);
      this.$forceUpdate();
    },
    rewriteWorkData() {
      this.properties = this.editingProperties;
      this.element = this.editingElement;
      this.properties.map(prop => {
        if ('refValues' in prop) {
          prop.refValues = prop.refValues.reduce((acc, currentValue) => {
            acc = [...acc, {
              id: currentValue.value,
              label: currentValue.value,
              value: currentValue.value,
            }]
            return acc;
          }, [])
        }
        this.propsValues[prop.name] = prop.value || prop.defaultValue;
      })
    },
    async addNewProperties() {
      this.isAddNewProps = !this.isAddNewProps;
      if (!this.allProperties.length) {
        await this.$store.dispatch('pageBuilder/getAllProperties', {offset: 0});
        await this.$store.dispatch('pageBuilder/getAllPropertyTypes', {offset: 0});
      }
    },
    addProperty(prop) {
      if (!this.assignedPropertiesIds.includes(prop.id)) {
        this.assignedPropertiesIds.push(prop.id);
        this.assignedProperties.push(prop);
        return;
      }
      this.assignedPropertiesIds = this.assignedPropertiesIds.filter(pr => pr !== prop.id);
      this.assignedProperties = this.assignedProperties.filter(pr => pr.id !== prop.id);
    },
    saveProperties() {
      this.$emit('saveProperties', this.editingElement);
    },
    saveNewProperties() {
      const element = this.editingElement;
      this.assignedProperties.forEach(prop => {
        element.properties[prop.name] = prop.defaultValue || '';
      })
      this.$emit('saveNewElementProperties', element);
    },
    async deleteElement() {
      if (await this.$refs.confirm.open(
          `delete ${this.editingElement.name || this.editingElement.nodeType}`,
          `Are you sure you want to delete ${this.editingElement.name || this.editingElement.nodeType}`
      )) {
        EventBus.$emit("deleteElement", this.editingElement);
      }
    },
    toggleColorPicker(colorName) {
      if (this.openColorPicker === colorName) {
        this.closeColorPicker();
      } else {
        this.openColorPicker = colorName;
      }
    },
    closeColorPicker() {
      this.openColorPicker = '';
    },
  },
  watch: {
    editingProperties() {
      console.log('new props - ', this.editingProperties);
      this.rewriteWorkData();
      this.assignedProperties = [];
      this.assignedPropertiesIds = [];
    }
  }
}
</script>

<style lang="scss" scoped>
.builder-element-settings {
  &__loader {
    margin: auto;
  }
  &__label {
    text-transform: uppercase;
  }
  &__info {
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid $main-dark;
    p {
      text-align: center;
    }
  }
}
.builder-element-settings__loader {
  margin: auto;
}

.page-builder__settings {
  &-title {
    font-size: 17px;
    font-weight: 500;
    margin-bottom: 30px;
    word-break: break-word;
    text-align: center;
  }
}

.page-builder__setting {
  border-bottom: 1px solid #E9ECF8;
  padding-bottom: 24px;
  margin-top: 24px;

  &:last-of-type {
    margin-bottom: 16px;
  }

  &-title {
    text-transform: capitalize;
    font-size: 16px;
    font-weight: 600;
  }

  .color-picker-container {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .color-picker-button {
    background-color: #F5F7FB;
    border-radius: 10px;
    margin: 16px 0;
    border: none;
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 10px;
    cursor: pointer;

    i {
      transition: transform 0.3s ease;
    }

    &.active {
      i {
        transform: rotate(-180deg);
      }
    }
  }

  .color-circle {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    margin-right: 10px;
    border: 4px solid $main-white;
  }

  .color-circle + span {
    color: #0E1A22;
    font-size: 15px;
  }

  &-container {
    .v-color-picker {
      border: 1px solid #e9e9e9;
      border-radius: 4px;

      .v-color-picker__controls {
        padding: 8px;
      }
    }

    &.type-bool {
      display: inline-flex;
      flex-direction: row-reverse;
      align-items: center;
      justify-content: flex-end;
      background-color: #F5F7FB;
      padding: 10px;
      border-radius: 10px;

      & > label {
        margin-right: 8px;
      }

      & > div {
        margin-top: 0;
      }
    }

    & > div {
      margin-top: 16px;
    }
  }
}
</style>