<template>
  <div 
    v-if="transition?.elementId"
    class="transition-card"
    :class="{'one-element': oneElement}"
  >
    <div v-if="!oneElement" class="inline mb-1">    
      <span class="transition-card__title">{{ $t('node.actions.from') }}</span>
      <span> {{ transition.nodeFromName + ' (' + transition.nodeFromId + ') ➔' }} </span>
      <span> {{ transition?.elementName + ' (' + transition.elementId + ')' }}</span>
    </div>

    <div v-if="oneElement" class="separator-line my-2"></div>

    <div class="inline ml-8 transition-card__to" @click="changeVisiblePopup">
      <span class="transition-card__title">{{ $t('node.actions.to') }}</span>
      <span v-if="transition?.nodeId"> {{ transition.nodeName + ' (' + transition.nodeId + ') ➔' }} </span>
      <span v-if="transition?.presetId"> {{ transition.presetName + ' (' + transition.presetId + ') ➔' }} </span>
      <span> {{ transition.screenName + ' (' + transition.screenId + ')' }} </span>
    </div>

    <popupConditions 
      v-if="isPopupShow"
      :rule="transition" 
      :position="topY"
      @save="savePopup($event)" 
      @cancel="changeVisiblePopup" 
    />

  </div>
  <div 
    v-else
    class="transition-card transition-card__default"
  >
    <template v-if="transition.internalNode">
      <div class="transition-card__to" @click="changeVisiblePopup($event, true)">
          <span class="transition-card__title">preset </span>
          <span>{{ transition.internalNode.preset }}</span> <br>
          <span class="transition-card__title">screen </span>
          <span>{{ transition.internalNode.screen }}</span>
      </div>

      <popupConditions 
        v-if="isPopupShow"
        :rule="transition" 
        :position="topY"
        :defaultTrans="hideFieldsInPopup"
        @save="savePopup($event)" 
        @cancel="changeVisiblePopup" 
      /> 
    </template>
    <template v-else>
        <div class="transition-card__to" @click="changeVisiblePopup($event, true)">
          <span class="transition-card__title">{{ $t('node.actions.from') }}</span>
          <span> {{ nodeTitle }} </span>
          <span> {{transition?.fromDisplayName}} </span>
          <span class="transition-card__title">{{ $t('node.actions.to') }}</span>
          <template v-if="transition?.nodeId">
            <span> {{ transition.nodeName + ' (' + transition.nodeId + ')' }} </span>
            <span> {{transition?.toDisplayName}} </span>
          </template>
        </div>

        <popupConditions 
          v-if="isPopupShow"
          :rule="transition" 
          :position="topY"
          :defaultTrans="hideFieldsInPopup"
          @save="savePopup($event)" 
          @cancel="changeVisiblePopup" 
        /> 
    </template>
  </div>
</template>

<script>
import searchConditions from '@/mixins/searchConditions';

import popupConditions from '@/components/nodes/nodeConditions/popupConditions.vue'
import popupPosition from "@/mixins/popupPosition";
export default {
  name: 'TransitionStructure',
  props: {
    transition: {
      type: Object
    },
    type: {
      type: String
    },
    last: {
      type: Boolean
    },
    oneElement: {
      type: Boolean,
      default: false
    },
    nodeTitle: {
      type: String
    }
  },
  mixins: [searchConditions, popupPosition],
  components: {
    popupConditions
  },
  data(){
    return{
      newConditionId: null,
      isPopupShow: false,
      topY: 0,
      hideFieldsInPopup: false,
    }
  },

  methods: {
    changeVisiblePopup(event, hideFields = false){
      this.topY = event?.clientY
      this.isPopupShow = !this.isPopupShow
      this.hideFieldsInPopup = hideFields
    },
    savePopup(condition){
      this.isPopupShow = !this.isPopupShow
      this.$store.dispatch('processes/editActionsProcess', condition)
    },
  },
  computed: {
    getContext() {
      return this.$store.getters['processes/getContext']
    },
  }
}
</script>

<style lang="scss" scoped>
  .separator-line{
    background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(140,161,203,1) 50%, rgba(255,255,255,1) 100%);
    width: 100%;
    height: 1px;
  }
  .transition-card{
    background: #F5F7FB;
    border-radius: 12px; 
    padding: 10px 16px;
    color: #5A717C;

    &__title{
      font-weight: 700; 
      font-family: Inter;
    }

    &__to{
      cursor: pointer;
      padding: 5px;
      border-radius: 10px;
      position: relative;
      z-index: 1;
      // transition: all .3s;

      // &:hover {
      //   background: #ffffff;
      // }
    }

    &__default{
      color: #685a7c;
      background: #f8f5fb !important;
    }
  }
  .one-element{
    margin-top: -15px !important;
  }
</style>