<template>
  <SpinnerLoader
    v-if="loading"
    color="deep-purple accent-2"
  />
  <div class="screen-edit d-flex flex-grow-1" v-else-if="currentScreen">
    <v-col
        v-if="findMethod === 1"
        class="screen-edit__general pa-4 d-flex"
        style="height: calc(100vh - 64px)"
    >
      <graph-editor
          v-if="!!editorData?.connections"
          v-bind.sync="editorData"
          :options.sync="canvasOptions"
          intoNode
          @nodeDblClick="setSelectNodeOnCanvas"
      />
      <SpinnerLoader
          v-else
          color="deep-purple accent-2"
      />
    </v-col>
    <v-col
        v-else
        class="screen-edit__general pa-4 d-flex"
        style="height: calc(100vh - 64px)"
    >
      <v-btn
        class="flex-grow-0"
        icon
        @click="close"
      >
        <v-icon>
          mdi-arrow-left
        </v-icon>
      </v-btn>
      <div class="screen-edit__content d-flex align-center justify-center flex-grow-1">
        <NodeScreen
          :setting="currentScreen"
          :active-actions="false"
        />
      </div>
    </v-col>
    <v-col 
      class="screen-edit__characteristics pa-0" 
      :class="{'hidden-scroll': isHiddenScroll}"
    >
      <div class="mx-6 my-4 d-flex justify-space-between" style="gap:25px;">
        <h2 class="text-h5">{{ $t('intoWidget.performance') }}: {{ currentScreen.name }}</h2>
        <button @click="showAddAction()">
          <h3 class="d-flex">{{ $t('node.actions.title') }}
            <v-icon color="#dee2e6">
              {{'mdi-plus-thick'}}
            </v-icon>
          </h3>
        </button>
      </div>
      <div class="screen-edit__characteristics__conditions">
        <tempalte v-for="action,i in actionsByScreen" :key="i">
          <actionBlock
              class="mr-2"
              :name="nameOfAction(action)"
              :btn="true"
              @deleteAction="popupDeleteRuleElement(action)"
              @editAction="popupEditRuleElement(action)"
          />
          <popupConditions
              v-if="isShowAddAction"
              :rule="action"
              :bg="false"
              :edit="isEditAction"
              isScreen
              :position="110"
              @cancel="closePopup"
              @save="changeCondition"
          />
        </tempalte>
        <popupConditions
            v-if="isShowAddAction && !actionsByScreen.length"
            :rule="actionForElementData"
            :bg="false"
            :edit="false"
            isScreen
            :position="110"
            @cancel="closePopup"
            @save="changeCondition"
        />
      </div>
      <NodeScreenEdit
        @save="saveScreenSettings"
        @reset="resetScreenSettings"
        @showPopupBg="showPopupBg"
      />
      <!-- modal window -->
      <portal-target
          v-if="isShowPopupBg"
          class="screen-edit__portal-target"
          name="action_for_element"
          :style="heightComponent"
      />
    </v-col>

    <portal to="action_for_element" v-if="isDeleteAction">
      <cardPopup
          title="Delete action"
          activeBtn="delete"
          class="node-screen-edit__popup"
          @passiveBtn="popupDeleteRuleElement"
          @activeBtn="deleteRuleElement"
      >
        <p>{{$t('node.intoCondition.deleteRule')}}</p>
      </cardPopup>
    </portal>
  </div>
</template>

<script>
import NodeScreen from "@/components/nodes/nodeScreens/NodeScreen.vue";
import NodeScreenEdit from "@/components/nodes/nodeScreens/settings/NodeScreenEdit.vue";
import { EventBus } from '@/utils/event-bus'
import popupConditions from "@/components/nodes/nodeConditions/popupConditions.vue";
import SpinnerLoader from "@/components/UI/SpinnerLoader.vue"
import actionBlock from '@/components/UI/actionBlock.vue';
import cardPopup from '@/components/UI/cardPopup.vue'
import conditionsMixin from '@/mixins/conditionsMixin.js'

export default {
  name: 'ScreenEdit',
  components: {
    NodeScreenEdit,
    NodeScreen,
    popupConditions,
    SpinnerLoader,
    actionBlock,
    cardPopup,
  },
  data () {
    return {
      previousPreview: null,
      currentScreen: null,
      previousCurrentScreen: null,
      loading: false,
      isShowPopupBg: false,
      isHiddenScroll: false,
      canvasOptions: {
        loading: false
      },
      isShowAddAction: false,
      actionForElementData: {},
      isEditAction: false,
    }
  },
  mixins: [conditionsMixin],
  computed: {
    screenId () {
     return this.$store.getters["node/currentScreenId"];
    },
    dialog () {
      return !!this.screenId;
    },
    heightComponent: {
      cache: false,
      get(){
        const height = this.$store.getters['node/heightScreenEditComponent']
        return height ? `height:${height}px` : 'height:100%'
      }
    },
    editorData: {
      cache: false,
      get(){
        return { ...this.$store.getters['processes/getProcessForAction'], availableNodes: this.$store.getters["node/availableNodes"]}
      }
    },
    findMethod: {
      cache: false,
      get(){
        return this.$store.getters['node/findMethod']
      }
    },

    actionsByScreen(){
      return this.actionsProcess
          .filter(action => !action.elementId)
          .filter(action => action.nodeFromId === this.currentScreen.id)
    }
  },
  async created () {
    await this.getCurrentScreen(this.screenId);

    EventBus.$on('setOverflowY', visible => {
      this.isHiddenScroll = visible
    })
  },
  methods: {
    close () {
      this.$store.dispatch('node/setCurrentField', null);
      this.$store.dispatch('node/setCurrentScreenId', null);
    },
    async getCurrentScreen (id) {
      this.loading = true;
      await this.$store.dispatch('node/setEditScreen', {id, showWidget: this.$route.query?.widgets}); // TODO: showWidget - Temporary decision for checking widgets
      this.previousCurrentScreen = JSON.parse(JSON.stringify(this.$store.getters["node/currentScreen"]));
      this.currentScreen = this.$store.getters["node/currentScreen"];
      this.loading = false;
    },
    async resetScreenSettings (rerender) {
      await this.getCurrentScreen(this.screenId);
      this.$store.dispatch('node/setCurrentField', null);
      rerender();
    },

    saveScreenSettings (currentScreen, textStyles) {
      // dispatch save
      this.$store.dispatch("node/saveScreenSettings", currentScreen);
      // save text styles in local store
      Object.keys(textStyles).forEach(styleKey => {
        localStorage.setItem(styleKey, textStyles[styleKey]);
      })
      this.close();
    },

    showPopupBg(){
      this.isShowPopupBg = !this.isShowPopupBg
    },

    setSelectNodeOnCanvas(node){
      this.$store.dispatch("node/setSelectNodeOnCanvas", node)
    },

    showAddAction(){
      this.isShowAddAction = !this.isShowAddAction
      this.actionForElementData = {
        name: this.currentScreen?.name,
        nodeFromId: this.currentScreen?.id,
      }
      this.isEditAction = false
      this.showPopupBg()
    },

    closePopup(){
      this.isShowAddAction = !this.isShowAddAction
      this.actionForElementData = {}
      this.showPopupBg()
    },

    changeCondition(data){
      this.isShowAddAction = !this.isShowAddAction
      this.$store.dispatch('processes/saveActionsProcess', data)
      this.showPopupBg()
    }
  }
}
</script>

<style scoped lang="scss">
.screen-edit {
  &__characteristics {
    background-color: #ffffff;
    box-shadow: 0 0 32px 0 rgb(136 152 170 / 15%);
    position: relative;
    overflow-y: scroll;

    &__conditions{
      margin: 15px;
      display: flex;
      flex-direction: column;
    }
  }

  &__portal-target {
    position: absolute;
    height: 100%;
    width: 100%;
    background: rgba(#212121, 0.4);
    top: 0;
    left: 0;
    z-index: 8;
    display: flex;
    justify-content: center;
    align-items: start;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.hidden-scroll{
  overflow-y: hidden !important;
}
</style>
