<template>
  <div>
    <template v-if="temporaryExpression && temporaryExpression.length">
      <v-row v-for="expression,i in temporaryExpression" :key="i">
        <OperatorButton 
          v-show="expression?.operator"
          :operator="expression.operator" 
          @changeOperator="changeOperator($event, i)"
        />
        <RuleSettings 
          v-if="expression?.rule || expression.rule === 0"
          :ruleId="expression.rule"
          :last="temporaryExpression.length - 1 === i"
          @deleteCondition="deleteCondition(i)"
          @changeCondition="changeCondition($event, i)"
          @addCondition="addCondition"
        />
      </v-row>
    </template>
    <v-row v-else>
      <OperatorButton v-show="false" />
      <RuleSettings 
        @changeCondition="createCondition($event)"
      />
    </v-row>
  </div>
</template>

<script>
import RuleSettings from "@/components/nodes/nodeConditions/RuleSettings.vue";
import OperatorButton from "@/components/nodes/nodeConditions/OperatorButton.vue";
import popupPosition from "@/mixins/popupPosition";
import searchConditions from "@/mixins/searchConditions";

export default {
  name: "ConditionSettings",
  components: {
    RuleSettings,
    OperatorButton
  },
  data() {
    return{
      temporaryExpression: null
    }
  },
  props: {
    transition: {
      type: Object
    },
    rule: {
      type: Object
    },
  },
  mixins: [popupPosition, searchConditions],
  methods: {
    updateExpression(){
      this.$emit('updateExpression', this.temporaryExpression)
    },
    createCondition(ruleId){
      this.temporaryExpression = [{ "rule": ruleId }]
      this.updateExpression()
    },

    addCondition(operator) {
      this.temporaryExpression.push({ "operator": operator },  { "rule": 0})
      this.updateExpression()
    },

    deleteCondition(index){
      if(this.temporaryExpression.length === 1) this.temporaryExpression.splice(index, 1)
      else this.temporaryExpression.splice(--index, 2)

      this.updateExpression()
    },

    changeOperator(operator, index){
      this.temporaryExpression[index].operator = operator
      this.updateExpression()
    },

    changeCondition(condition, index){
      this.temporaryExpression[index].rule = condition
      this.updateExpression()
    },
  },
  created(){
    this.temporaryExpression = this?.transition?.expression
  }
}
</script>