<template>
    <div id="nodePreset" ref="nodePreset"> 
      <p v-if="!transitions" class="empty-transition">{{ $t('node.conditions.empty') }}</p>
      <template v-else>

        <div class="btn-toggle">
          <button 
            @click="setTransitionType('internal')" 
            class="btn-toggle__type" 
            :class="{'active': internalType}"
          >
            {{ $t('node.conditions.internal.title') }}
          </button>
          <button 
            @click="setTransitionType('external')" 
            class="btn-toggle__type" 
            :class="{'active': !internalType}"
          >
            
            {{ $t('node.conditions.external.title') }}
          </button>
        </div>

        <template v-if="internalType">
          <p v-if="!transitions.internal.length" class="empty-transition">{{ $t('node.conditions.internal.empty') }}</p>
          <template v-else>
            <TransitionStructure 
              v-for="transition,i in transitions.internal" :key="transition.id"
              class="mt-5"
              :transition="transition"
              :last="transitions.internal.length === ++i"
              type="internal"
              :oneElement="transition.oneElement"
            />
          </template>
        </template>

        <template v-else>
          <p v-if="!transitions.external.length" class="empty-transition">{{ $t('node.conditions.external.empty') }}</p>
          <template v-else>
            <TransitionStructure 
              v-for="transition,i in transitions.external" :key="transition.id"
              class="mt-5"
              :transition="transition"
              :last="transitions.external.length === ++i"
              type="external"
              :oneElement="transition.oneElement"
              :nodeTitle="settings.title"
            />
          </template>
        </template>

      </template>
    </div>
  </template>
  
  <script>
  import popupPosition from "@/mixins/popupPosition";
  import TransitionStructure from "@/components/nodes/nodeTransitions/TransitionStructure.vue";
  import conditionsMixin from '@/mixins/conditionsMixin.js'
  export default {
      name: 'NodeTransitions',
      mixins: [popupPosition, conditionsMixin],
      components: {
        TransitionStructure,
     },
     data(){
      return{
        transitionsType: 'internal'
      } 
     },
     computed: {
      transitions: {
        cache: false,
        get(){
          let internal = []
          let external = []
          const currentNodeFromEditor = this.$store.getters['pageBuilder/currentNode']

          if(!this.screens) return

          const screensId = this.screens.map(item => item.id)
          const actions = this.actionsProcess.filter(action => {
            return screensId.includes(action.nodeFromId)
          })

          if(!actions || !actions.length) return 
          
          // currentActions - Filtered actions for current preset
          const currentActions = this.actionsProcess.filter(action => screensId.includes(action.nodeFromId))

          // screensIdCurrentNode - All screens at current node
          let screensIdCurrentNode = []
          let screensCurrentNode = []

          if(currentNodeFromEditor?.presets) 
            currentNodeFromEditor.presets.forEach(preset => preset.screens.forEach(screen => {
              if(!screensIdCurrentNode.includes(screen.id)) {
                screensIdCurrentNode.push(screen.id)
                screensCurrentNode.push({
                  screenId: screen.id, 
                  screenName: screen.name, 
                  presetId: preset.id, 
                  presetName: preset.name
                })
              }
            }))

          currentActions.forEach(action => {
            const screen = this.screens.find(screen => screen.id === action.nodeFromId)
            
            action.nodeFromName = screen.name
            if(action.elementId) {
              const name = screen.nodeFields.find(nodeField => nodeField.id === action.elementId)?.name
              const nameWidget = screen.nodeFields
                      .filter(widget => widget.nodeType === 'widget')
                      .map(widget => widget.nodeFields.find(node => node.id === action.elementId))
                      .filter(item => item)[0]?.name

              if(!name && !nameWidget) return
              
              action.elementName = name ?? nameWidget
            }

            const nodes = this.processN8N.nodes
            let fromDisplayName = Object.values(nodes)
                .find(node => node.db_id === this.settings.id)?.displayName

            fromDisplayName = fromDisplayName !== this.settings.title
              ? `(${fromDisplayName})`
              : ''


            if(screensIdCurrentNode.includes(action.nodeToId)) {              
              const toNext = screensCurrentNode.find(screen => screen.screenId === action.nodeToId)

              internal.push({...action, ...toNext, fromDisplayName})
            } else {
              const toNext = this.availableScreens.find(node => node.screenId === action.nodeToId)
              let toDisplayName = Object.values(nodes)
                  .find(node => node.db_id === toNext?.nodeId)?.displayName

              toDisplayName = toDisplayName !== toNext?.nodeName
                  ? `(${toDisplayName})`
                  : ''

              external.push({...action, ...toNext, fromDisplayName, toDisplayName})
            }
          })

          external = external.sort((a, b) => a.elementId - b.elementId)
          internal = internal.sort((a, b) => a.elementId - b.elementId)

          return {internal, external}
        }
      },
      internalType(){
        return this.transitionsType === 'internal'
      },
      isDefaultTransitions(){
        return this.transitions.external
          .find(transition => !transition.elementId)
      },
      settings() {
        return this.$store.getters["node/settings"]
      },
      getContext() {
        return this.$store.getters['processes/getContext']
      },
       processN8N() {
        return this.$store.getters["processes/processN8N"]
      },
    },
    methods: {
      setTransitionType(type){
        this.transitionsType = type
      }
    }
  }
  </script>

<style lang="scss" scoped>
.btn-toggle{
  border-radius: 12px;
  background: #F6F9FC;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 6px;
  gap: 4px;
  display: flex;

  &__type {
    padding: 4px;
    width: 50%;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 700; 
    color: #172B4D;

    &.active{
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      background: #FFF;
    }
  }
}
.empty-transition{
  background: #F5F7FB;
  text-align: center;
  border-radius: 12px;
  padding: 10px 16px;
  color: #5A717C;
  margin-top: 16px;
}

</style>