import { defaultApiInstance } from "@/api";

const path = 'editor/nodes'
export const pageBuilderService = {
    getElementsByType,
    getNode,
    createNode,
    changeNode,
    getTypesDictionary,
    getVariables,
    getVariableById,
    createVariable,
    updateVariable,
    deleteVariable,
    getNodeProperties,
    getFieldProps,
    deleteNode,
    getAllProperties,
    getAllPropertyTypes,
    createProperty
}

/**
 * @param {Number} nodeTypeId - type id for by which we can get elements for this type
 * */
async function getElementsByType(nodeTypeId) {
    return defaultApiInstance.get(`/${path}?nodeType=${nodeTypeId}`);
}

async function getNode(nodeId) {
    return defaultApiInstance.get(`/${path}/${nodeId}`);
}

async function getNodeProperties(nodeId) {
    return defaultApiInstance.get(`/properties?idNode=${nodeId}`);
}

async function changeNode(partId, nodePart) {
    return defaultApiInstance.patch(`/${path}/${partId}`, nodePart);
}

async function deleteNode(nodeId) {
    return defaultApiInstance.delete(`/editor/nodes/${nodeId}`);
}

async function getTypesDictionary() {
    return defaultApiInstance.get(`/${path}/types`);
}

async function createNode(payload) {
    return defaultApiInstance.post(`/${path}`, payload);
}

async function getAllProperties(payload) {
    let query = `?offset=${payload.offset}`
    return defaultApiInstance.get(`/properties${query}`);
}

async function createProperty(payload) {
    return defaultApiInstance.post('/properties', payload)
}

async function getAllPropertyTypes(payload) {
    let query = `?offset=${payload.offset}`
    return defaultApiInstance.get(`/properties/types${query}`);
}

async function getVariables(searchData) {
    let query = `&offset=${searchData.offset}`
    const type = searchData.isType
        ? `&type=${searchData.type}`
        : ''

    if(searchData?.type) query = `${searchData.search}${type}` + query

    return defaultApiInstance.get(`/${path}/fieldName?q=${query}`);
}

async function getVariableById(id){
    return defaultApiInstance.get(`/${path}/fieldName/${id}`);
}

async function createVariable(varData) {
    return defaultApiInstance.post(`/${path}/fieldName`, varData);
}

async function updateVariable(updateVariable) {
    return defaultApiInstance.patch(`/${path}/fieldName/${updateVariable.id}`, updateVariable);
}

async function deleteVariable(id) {
    return defaultApiInstance.delete(`/${path}/fieldName/${id}`);
}

async function getFieldProps() {
    return defaultApiInstance.get(`/${path}?nodeType=3`);
}

export const unHighlightBuilderServices = [{
    method: 'post',
    url: `/${path}/fieldName`
},{
    method: 'patch',
    url: `/${path}/fieldName`
},{
    method: 'delete',
    url: `/${path}/fieldName`
},{
    method: 'get',
    url: `/${path}/fieldName`
}]