<template>
  <div>
    <div v-if="bg" class="bg_popup"></div>
    <cardPopup
      v-if="!(isAddAction || isAddEvent)"
      :title="defaultTrans ? 'Default transition' : `Edit action for element '${rule.name}'`" 
      :style="popupPositionLocal"
      :isActiveBtn="permissionSave"
      class="popup"
      id="cardPopupRule"
      @passiveBtn="cancel"
      @activeBtn="save"
    >
      <div v-for="item in actionForElement" :key="item.type" class="mb-2">

        <div 
          v-if="!((item.type === 'eventId' || item.type === 'order') && defaultTrans)"
        >
          <label class="mr-2">{{ item.name }}</label>
          <!-- <button v-if="item.type === 'actionId'" @click="addAction">+</button>
          <button v-if="item.type === 'eventId'" @click="addEvent">+</button> -->
          <div @click="hideScrollY">
            <Switcher
              v-if="item.type === 'findMethod'"
              :params="switcherParams"
              :inlineStyle="{'background-color': '#e9ebf8', 'border-radius': '20px'}"
              :inlineStyleBtn="{'border-radius': '15px'}"
              :selectedParam="switcherParams[0]"
              @switchParam="switchFindMethod"
              :disabled="rule?.actionId === 1"
            />
            <v-autocomplete
              v-else
              :items="item.item"
              v-model="actionForElementData[item.type]"
              :item-value="typeData[item.type].value"
              :item-text="typeData[item.type].text"
              :disabled="item.type === 'goTo' && getFindMethod === 1"
              outlined
              placeholder="Select"
              hide-details
              class="mt-1"
            />
          </div>
        </div>
      </div>

      <label>Conditions</label>
      <ConditionSettings
        class="my-4"
        :transition="actionForElementData?.condition"
        :rule="rule"
        @updateExpression="updateExpression"
      />
    </cardPopup>

    <cardPopup
      v-if="isAddAction"
      class="popup"
      :style="popupPositionLocal"
      title="New action"
      @activeBtn="save2"
      @passiveBtn="addAction"
    >
        <v-text-field label="Name" outlined />
    </cardPopup>

    <cardPopup
      v-if="isAddEvent"
      class="popup"
      :style="popupPositionLocal"
      title="New event"
      @activeBtn="save3"
      @passiveBtn="addEvent"
    >
      <v-text-field label="Name" outlined/>
      <v-text-field label="Param 1" outlined />
      <v-text-field label="Param 2" outlined />
    </cardPopup>

  </div>
</template>

<script>
import cardPopup from '@/components/UI/cardPopup.vue'
import ConditionSettings from '@/components/nodes/nodeConditions/ConditionSettings.vue'

import conditionsMixin from '@/mixins/conditionsMixin'
import popupPosition from '@/mixins/popupPosition'
import overflowY from '@/mixins/overflowY'
import Switcher from "@/components/UI/switcher.vue";
export default{
  name: 'popupConditions',
  props: {
    rule: {
      type: Object
    },
    position: {
      type: Number
    },
    // for integrate for NodeScreenEdit
    isScreenEdit: {
      type: Boolean
    },
    bg: {
      type: Boolean,
      default: true
    },
    edit: {
      type: Boolean,
      default: true
    },
    defaultTrans: {
      type: Boolean,
      default: false
    },
    isScreen:{
      type: Boolean,
      default: false
    },
  },
  components: {
    cardPopup,
    ConditionSettings,
    Switcher
  },  
  mixins: [conditionsMixin, popupPosition, overflowY],
  data () {
    return {
      isShowAddAction: false,
      isAddAction: false,
      isAddEvent: false,

      actionForElementData: {
        actionId: null,
        eventId: null,
        process: null,
        findMethod: null,
        goTo: null,
        preset: null,
        screen: null,
        order: null,
        condition: null
      },
      positionLocal: 0,
      elementId: null,
      typeData: {
        'actionId': {value: 'id', text: 'name'},
        'eventId': {value: 'id', text: 'name'},
        'process': {value: 'id', text: 'process_name'},
        'goTo': {value: 'id', text: 'name'}, //TODO: rename "goTo" to "node"
        'preset': {value: 'presetId', text: 'presetName'},
        'screen': {value: 'screenId', text: 'screenName'},
        'order': {value: 'id', text: 'name'},
        'condition': {value: 'id', text: 'name'},
      },
      switcherParams: ['Manual', 'Canvas'],
    }
  },
  computed: {
    popupPositionLocal(){
      /* eslint-disable */

      const offset = this.positionLocal ? this.positionLocal : this.position + this.positionPopup 
      this.positionLocal = offset
      return `top: ${offset}px`
    },
    permissionSave(){
      let keys = [
        'actionId',
        'eventId',
        'goTo',
        'preset',
        'screen',
        'condition'
      ]

      if(this.defaultTrans) 
        keys = keys.filter(action => action !== 'eventId')

      return keys.every(action => this.actionForElementData[action])
    },

    getFindMethod(){
      return this.$store.getters['node/findMethod']
    },
    selectNodeOnCanvas(){
      return this.$store.getters['node/selectNodeOnCanvas']
    },
    defaultTransition(){
      return this.actionForElementData?.actionId === 1
        ? [this.actionForElementData?.nodeId, this.settings.id]
        : false
    }
  },
  methods: {
    editRuleElement() {
      this.elementId = this.rule.elementId
      this.actionForElementData = JSON.parse(JSON.stringify(this.rule))

      const connectionData = this.availableScreens.find(node => node.screenId === this.rule.nodeToId)
      if(!this.isScreenEdit){
        this.$set(this.actionForElementData, 'goTo', connectionData?.nodeId ?? connectionData?.nodeToId) //node
        this.$set(this.actionForElementData, 'screen', connectionData?.screenId ?? connectionData.screen) // screen
      }
    },

    updateExpression(expression){
      this.$set(this.actionForElementData, 'condition', {'expression': expression})
    },

    save(){
      const newCondition = {
        actionId: this.actionForElementData.actionId,
        eventId: this.actionForElementData.eventId,
        order: this.actionForElementData.order,
        condition: this.actionForElementData.condition,
        nodeFromId: this.actionForElementData.nodeFromId ?? null,
        nodeToId: this.actionForElementData.screen ?? null,
        id: this.actionForElementData.id ?? null,
        process: this.actionForElementData.process
      }
      const newConditionElement = {
        elementId: this.actionForElementData.elementId ?? null,
      }
      const dataToSave = this.isScreen
        ? newCondition
        : {...newCondition, ...newConditionElement}

      this.$emit('save', dataToSave)
      this.$store.dispatch('node/heightOfPopup', 0)
    },

    cancel(){
      this.$emit('cancel')
      this.$store.dispatch('node/heightOfPopup', 0)
      this.actionForElementData = {}

      this.switchFindMethod(null, 0)
    },
    // addAction(){
    //   console.log("addAction in popup:")
    //   this.isAddAction = !this.isAddAction
    // },
    // addEvent(){
    //   console.log("addEvent in popup:")
    //   this.isAddEvent = !this.isAddEvent
    // },

    save2(){

    },
    save3(){

    },
    setHeightBg(){
      const bottomOffset = 500
      const clientHeight = document.getElementById('cardPopupRule')?.clientHeight
      const characteristicsHeight = document.querySelectorAll('.screen-edit__characteristics')[0]?.clientHeight

      this.isScreenEdit
        ? this.$store.dispatch('node/heightScreenEditComponent', characteristicsHeight + Math.abs(this.positionPopup) + bottomOffset )
        : this.$store.dispatch('node/heightOfPopup',  this.position + Math.abs(this.positionPopup) + clientHeight + bottomOffset )
    },
    switchFindMethod(type, index){
      this.$set(this.actionForElementData, 'findMethod', index)
      this.$store.dispatch('node/setFindMethod', index)
    },
    setDefaultData(){
      const processId = this.$store.getters['processes/process'].id
      this.$set(this.actionForElementData, 'process', processId)
      if(this.isScreen) this.$set(this.actionForElementData, 'nodeFromId', this.rule.nodeFromId)

      this.switchFindMethod(null, 0)
    }
  },
  watch: {
    'actionForElementData.process': {
      handler: function (id) {
        if(id) this.$store.dispatch('processes/getProcessForAction', id)
        this.actionForElementData.goTo = null
        this.actionForElementData.preset = null
        this.actionForElementData.screen = null
      },
      deep: true
    },
    'actionForElementData.goTo': {
      handler: function (id) {
        if(id)  this.$store.dispatch('node/getPresetsOnSelectedNode', id)
        this.actionForElementData.preset = null
        this.actionForElementData.screen = null
      },
      deep: true
    },
    'actionForElementData.preset': {
      handler: function (id) {
        if(id) this.$store.dispatch('node/getScreensOnSelectedNode', id)
      },
      deep: true
    },
    selectNodeOnCanvas: {
      handler: function (node) {
        if(node) this.actionForElementData.goTo = node.db_id
      },
      deep: true
    }
  },
  created(){
    if(this.edit) this.editRuleElement()
    this.setDefaultData()
  },
  mounted(){
   this.setHeightBg()
  }
}

</script>

<style lang="scss" scoped>

.popup{
  width: 100% !important; 
  z-index: 20;
  position: absolute;
  left: 10px;
  width: 95% !important;
}
.bg_popup{
  position: absolute;
  height: 100%;
  width: 100%;
  background: rgba(#212121, 0.4);
  opacity: 0.4;
  top: 0;
  left: 0;
  z-index: 8;
  display: flex;
  justify-content: center;
  align-items: start;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>