<template>
  <div
    @click.stop="openSettings(element)"
    :class="[
      'builder-element__el',
      { 'builder-element__el--in-search': elInSearch },
    ]"
    :data-name="elInSearch && (element.name || element.nodeType)"
  >

    <!-- nodeTypeId = 4 -->
    <WidgetDisplay
      v-if="element.nodeType == 'widget'"
      :item="element"
      :default-img="{
        src: noPreviewImg,
        height: elInSearch && '50px',
      }" 
    />

    <component
      v-else-if="getComponent(element)"
      :is="getComponent(element)"
      v-bind="propSet"
    >
      Text
      <img
        v-if="element.name === 'block.box'"
        slot="icon"
        :src="alertIcon"
        alt="icon" 
      />
    </component>

    <div
      v-else-if="checkTextComponent(element.name)"
      :style="element.name === 'webTooltip' && tooltipStyle()"
    >
      <span v-html="element.properties?.text || 'Text'" />
    </div>

    <PreviewImage
      v-else-if="checkImageComponent(element.name)"
      max-width="100"
      max-height="100"
      :src="element.properties?.imageUrl || ''"
      class="builder-element__image" 
    />

    <img
      v-else
      :src="noPreviewImg"
      alt="builder-element"
      class="builder-element__default-img" 
    />
  </div>
</template>

<script>
import WidgetDisplay from "@/components/WidgetDisplay.vue";
import pageBuilder from "@/mixins/pageBuilder";
import { EventBus } from "@/utils/event-bus";

export default {
  name: "BuilderElement",
  components: { WidgetDisplay },
  data() {
    return {
      componentRefs: {
        "input.dropDownList": "BpmSelect",
        "input.OTPweb": "BpmWebOtpInput",
        "block.box": "AlertComponent",
        "button.main": "ButtonComponent",
        checkbox: "CheckboxComponent",
        progressBar: "ProgressIndicatorComponent",
        webProgressBar: "ProgressIndicatorComponent",
        "progressBar.discrete": "ProgressIndicatorComponent",
        radiobutton: "RadioButtonComponent",
        input: "InputComponent",
        "input.range": "RangeComponent",
        "input.OTP": "OtpInputComponent",
        "input.passcode": "OtpInputComponent",
        timer: "SpinnerComponent",
        changer: "SegmentedControlComponent",
      },
      propConditions: {
        "button.main":{
          fullwidth: true
        },
        "input.OTP": {
          number: 3,
        },
        "input.passcode": {
          number: 3,
        },
        webProgressBar: {
          min: 0,
          max: 100,
          value: 20,
        },
        progressBar: {
          min: 0,
          max: 100,
          value: 20,
        },
        "progressBar.discrete": {
          min: 1,
          max: 5,
          value: 1,
          variant: "stepped",
        },
        timer: {
          endTime: "00:00:10",
        },
        "input.range": {
          min: 0,
          max: 100,
          currency: "₹",
          "show-values": true,
          "show-input": true,
        },
        "input.OTPweb": {
          number: 3,
          regex: "^[0-9]+$",
          description: "Description",
        },
        input: {
          label: "Label",
        },
        "input.dropDownList": {
          label: "Label",
        },
        changer: {
          segments: [
            { id: 1, label: "1", selected: true },
            { id: 2, label: "2" },
          ],
        },
      },
      noPreviewImg: require("@/assets/icons/block-no-preview.svg"),
      alertIcon: require("@/assets/icons/alert-info.svg"),
    };
  },
  props: {
    element: {
      type: Object,
      required: true,
    },
    canSetting: {
      type: Boolean,
      default: true,
    },
    elInSearch: {
      type: Boolean,
      default: false,
    },
    nodeTypeId: {
      type: [String, Number],
      default: null,
    },
  },
  mixins: [pageBuilder],
  computed: {
    propSet() {
      return (
        this.propConditions[this.element.name ?? this.element.nodeType] || {}
      );
    },
  },
  methods: {
    openSettings(element) {
      if (this.canSetting) {
        EventBus.$emit("openSettings", element);
      }
    },
    checkImageComponent(name) {
      return name?.includes("webImage", "image", "image.message") ?? false;
    },
    checkTextComponent(name) {
      return name === "webTooltip" || name == "block";
    },
    getComponent(element) {
      return (
        this.componentRefs?.[element.name] ||
        this.componentRefs?.[element.nodeType]
      );
    },
    tooltipStyle() {
      return `
        background-color: ${this.element.properties?.backgroundColor}; 
        text-align: ${this.element.properties?.align};
        padding: 10px;
        border-radius: 10px;
        color: #fff;
      `;
    },
  },
};
</script>

<style lang="scss" scoped>
.builder-element {
  &__el {
    height: 100%;
    margin: 5px 0;
    padding: 2px;
    border: 1px dashed #cdcccc;
    transition: border-color 0.2s ease-in-out;

    &--in-search {
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 65px;
      max-height: 100px;
      margin: 0;

      overflow-x: hidden;
      overflow-y: scroll;
      -ms-overflow-style: none;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }

      &::before {
        content: attr(data-name);
        position: absolute;
        top: 0;
        left: 10px;
        font-size: 11px;
        color: #5e6061;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        transition: color 0.2s ease-in-out;
      }

      &:hover {
        border-color: black;

        &::before {
          overflow: visible;
          color: black;
        }
      }

      .builder-element__default-img {
        height: 50px;
      }
    }
  }

  &__default-img,
  .widget-display__dafault-img {
    object-position: center;
    object-fit: contain;
    height: 64px;
    width: 100%;
  }
}
</style>
