<template>
  <v-col 
    v-if="item?.properties?.visibility && widgetsView[item.name]"
    class="widget-display py-2 pa-0"
  >
    <component
      :is="widgetsView[item.name]"
      :widget-data="item"
    />
  </v-col>
 
  <img 
    v-else-if="defaultImg" 
    :src="defaultImg.src"
    :width="defaultImg.width || 'auto'"
    :height="defaultImg.height || 'auto'"
    alt="widget" 
    class="widget-display__dafault-img" 
  />
</template>

<script>
import MX_button from "@/components/UI/widgets/MX_button";
import MX_topBarCenterLogo from "@/components/UI/widgets/MX_topBarCenterLogo";
import MX_widgetCalendar from "@/components/UI/widgets/MX_widgetCalendar";
import MX_widgetEsignature from "@/components/UI/widgets/MX_widgetEsignature.vue";
import MX_widgetMap from "@/components/UI/widgets/MX_widgetMap.vue";
import MX_widgetModalWindow from "@/components/UI/widgets/MX_widgetModalWindow.vue";
import MX_widgetShortCodeLogin from "@/components/UI/widgets/MX_widgetShortCodeLogin.vue";
import PK_widgetButtonArrow from "@/components/UI/widgets/PK_widgetButtonArrow.vue";
import PK_widgetButtonCheckbox from "@/components/UI/widgets/PK_widgetButtonCheckbox.vue";
import PK_widgetChatTopBarCenterPageName from "@/components/UI/widgets/PK_widgetChatTopBarCenterPageName.vue";
import PK_widgetModalWindowChat from "@/components/UI/widgets/PK_widgetModalWindowChat.vue";
import PK_widgetModalWindowChatResend from "@/components/UI/widgets/PK_widgetModalWindowChatResend.vue";
import PK_widgetModalWindowExternalInfo from "@/components/UI/widgets/PK_widgetModalWindowExternalInfo.vue";
import PK_widgetModalWindowFiltersForHistoryDownload from "@/components/UI/widgets/PK_widgetModalWindowFiltersForHistoryDownload.vue";
import PK_widgetShortCodeLogin from "@/components/UI/widgets/PK_widgetShortCodeLogin.vue";
import PK_widgetSlyderCard from "@/components/UI/widgets/PK_widgetSlyderCard.vue";
import PK_widgetBiometryModalWindow from "@/components/UI/widgets/PK_widgetBiometryModalWindow.vue";
import PK_widgetTabBar from "@/components/UI/widgets/PK_widgetTabBar.vue";
import PK_widgetTopbarWithSearch from "@/components/UI/widgets/PK_widgetTopbarWithSearch.vue";
import PK_widgetTransactionsList from "@/components/UI/widgets/PK_widgetTransactionsList.vue";


export default {
  name: 'WidgetDisplay',
  props: {
    item: Object,
    defaultImg: Object // { src, width, height }
  },   
  data() {
    return {
      widgetsView: {
        "widgetModalWindow": MX_widgetModalWindow,
        "MX_button": MX_button,
        "MX_topBarCenterLogo": MX_topBarCenterLogo,
        "MX_widgetCalendar": MX_widgetCalendar,
        "MX_widgetEsignature": MX_widgetEsignature,
        "MX_widgetMap": MX_widgetMap,
        "MX_widgetModalWindow": MX_widgetModalWindow,
        "MX_widgetShortCodeLogin": MX_widgetShortCodeLogin,
        "MX_widgetSlyderCard": PK_widgetSlyderCard,
        "MX_widgetTopBarCenterPageName": MX_topBarCenterLogo,
        "PK_widgetAdvertisingCarouselHomescreen": PK_widgetSlyderCard,
        "PK_widgetBiometryModalWindow": PK_widgetBiometryModalWindow, // уточнение, разные properties у похожих полей
        "PK_widgetButton": MX_button,
        "PK_widgetButtonArrow": PK_widgetButtonArrow,
        "PK_widgetButtonCheckbox": PK_widgetButtonCheckbox,
        "PK_widgetChatTopBarCenterPageName": PK_widgetChatTopBarCenterPageName,
        "PK_widgetModalWindow": MX_widgetModalWindow,
        "PK_widgetModalWindowChat": PK_widgetModalWindowChat,
        "PK_widgetModalWindowChatResend": PK_widgetModalWindowChatResend,
        "PK_widgetModalWindowExternalInfo": PK_widgetModalWindowExternalInfo,
        "PK_widgetModalWindowFiltersForHistoryDownload": PK_widgetModalWindowFiltersForHistoryDownload,
        "PK_widgetShortCodeLogin": PK_widgetShortCodeLogin,
        "PK_widgetSlyderCard": PK_widgetSlyderCard,
        "PK_widgetTabBar": PK_widgetTabBar,
        "PK_widgetTopBarCenterLogo": MX_topBarCenterLogo,
        "PK_widgetTopBarCenterPageName": PK_widgetChatTopBarCenterPageName, 
        "PK_widgetTopbarWithSearch": PK_widgetTopbarWithSearch,
        "PK_widgetTransactionsList": PK_widgetTransactionsList
      }
    }
  }
}
</script>

<style scoped>

</style>