<template>
  <div class="widget pk_widget-tab-bar">
    <div 
      v-if="btn.properties?.visibility"
      v-for="btn,i in widgetData.nodeFields"
      :key="i"
      class="widget__btn"
      @click="selectBtn(i)"
    >
      <template >
        <img v-if="i === activeBtn" :src="$staticUrl + btn.properties.activeImageUrl" alt="">
        <img v-else :src="$staticUrl + btn.properties.inactiveImageUrl" alt="">
        <span v-html="btn.properties.text"></span>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: "PK_widgetTabBar",
  props: {
      widgetData: {
          type: Object
      }
  },
  data(){
    return{
      activeBtn: 0
    }
  },
  methods: {
    selectBtn(i){
      this.activeBtn = i
    }
  }
}
</script>

<style lang="scss" scoped>
.widget{
  display: flex;
  justify-content: space-around;

  position: fixed;
  bottom: 35px;
  left: -10px;
  width: calc(100% + 20px);
  background-color: #ffffff;
  z-index: 20;
  padding: 12px;
  border-radius: 20px 20px 0px 0px;
  border: 1px solid rgb(243, 251, 255);

  &__btn{
    display: flex;
    flex-direction: column;
    align-items: center;

    img{
      width: 35px;
    }
  }
}
</style>