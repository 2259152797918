<template>
  <div class="align-editor">
    <label
      class="align-editor__title"
      @click="toggleControls"
      :class="[controlsVisible ? 'active' : '']">
      Align
      <i
        aria-hidden="true"
        class="v-icon notranslate mdi mdi-chevron-down theme--light" 
      />
    </label>

    <div v-if="controlsVisible" class="align-editor__container">
      <button
        v-for="icon in icons"
        @click="selectAlignment(icon)"
        :key="icon.align"
        :class="selectedAlignment.toLowerCase() === icon.align ? 'active' : ''"
      >
        <img :src="icon.src" :alt="icon.align" />
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "PositionEditor",
  data() {
    return {
      controlsVisible: false,
      selectedAlignment: '',
      icons: [
        { src: require("@/assets/icons/align-left.svg"), align: "left" },
        { src: require("@/assets/icons/align-center.svg"), align: "center" },
        { src: require("@/assets/icons/align-right.svg"), align: "right" },
        { src: require("@/assets/icons/align-top.svg"), align: "top" },
        { src: require("@/assets/icons/align-center-v.svg"), align: "middle" },
        { src: require("@/assets/icons/align-bottom.svg"), align: "bottom" },
      ],
    };
  },
  methods: {
    toggleControls() {
      this.controlsVisible = !this.controlsVisible;
    },
    selectAlignment(icon) {
      this.selectedAlignment = icon.align;
    },
  },
};
</script>

<style lang="scss" scoped>
.align-editor {
  &__title {
    display: flex;
    justify-content: space-between;
    width: 100%;
    text-transform: capitalize;
    font-size: 16px;
    font-weight: 600;
    background-color: #f5f7fb;
    border-radius: 10px;
    border: none;
    padding: 10px;
    cursor: pointer;

    i {
      transition: transform 0.3s ease;
    }

    &.active {
      i {
        transform: rotate(-180deg);
      }
    }
  }

  &__container {
    margin-top: 16px;
    position: relative;
    padding: 4px;
    border-radius: 4px;
    display: flex;
    gap: 20px;
    justify-content: space-between;
    background-color: #E9ECF8;

    button {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      user-select: none;
      border-radius: 4px;
      transition: background-color 0.3s ease;

      &.active {
        background-color: white;
      }

      &:hover {
        background-color: white;

        img {
          filter: grayscale(100%);
        }
      }
    }

    img {
      height: 40px;
      width: 30px;
      transition: filter 0.3s ease;
    }
  }
}
</style>
