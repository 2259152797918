<template>
  <div class="widget">
    <div 
      v-if="widgetData.nodeFields[0].properties.visibility"
      v-html="widgetData.nodeFields[0].properties.label"
      class="widget__label"
    ></div>

    <BpmOtpInput :number="4" />
    <div class="widget__info">
      <span>Important : Please don't share your PIN code with anyone</span>
    </div>

    <div 
      v-if="widgetData.nodeFields[1].properties.visibility"
      class="widget__forgot-pass"
    >
      <span v-html="this.widgetData.nodeFields[1].properties.text"></span>
    </div>

    <div class="widget__numpad" v-if="widgetData.nodeFields[2].properties.visibility">
      <div class="widget__numpad-row">
        <div class="widget__numpad-num">1</div>
        <div class="widget__numpad-num">2</div>
        <div class="widget__numpad-num">3</div>
      </div>
      <div class="widget__numpad-row">
        <div class="widget__numpad-num">4</div>
        <div class="widget__numpad-num">5</div>
        <div class="widget__numpad-num">6</div>
      </div>
      <div class="widget__numpad-row">
        <div class="widget__numpad-num">7</div>
        <div class="widget__numpad-num">8</div>
        <div class="widget__numpad-num">9</div>
      </div>
      <div class="widget__numpad-row">
        <div class="widget__numpad-num">del</div>
        <div class="widget__numpad-num">0</div>
        <div class="widget__numpad-num widget__numpad-num-clear"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PK_widgetShortCodeLogin",
  props: {
      widgetData: {
          type: Object
      }
  }
}
</script>

<style lang="scss" scoped>
.widget{

  &__label{
    width: 100%;
    text-align: center;
  }

  &__forgot-pass{
    text-align: center;
    margin-top: 10px;
    margin-bottom: 20px;
  }

  &__info{
    width: 100%;
    text-align: center;
  }
  &__numpad{
    display: flex;
    flex-direction: column;
    gap: 8px;

    &-row{
      display: flex;
      gap: 8px;
    }

    &-num{
      flex-grow: 1;
      width: 100%;
      text-align: center;
      padding: 30px;
      background-color: rgb(234, 246, 255);
      border-radius: 50%;
      color: rgb(0, 0, 0);
      font-size: 20px;
      font-weight: 700;

      &-clear{
        background: none !important;
      }
    }
  }
}
</style>