/** The best solution to read stream is fetch, because axios based on xhr **/
import store from "@/store";
import {generateFormData} from "@/utils/utils-functions";

let CHATGPT_AUTH_TOKEN = `Bearer ${localStorage.getItem('accessToken')}` || '';
const CHATGPT_URL = process.env.VUE_APP_BACKEND_URL;

function getToken() {
  if (localStorage.getItem('accessToken')) {
    CHATGPT_AUTH_TOKEN = `Bearer ${localStorage.getItem('accessToken')}`;
  }
}

export const chatgptService = {
    sendRequest,
    showProcess,
    testStream,
    loadOpenApi,
    selectAction,
    showMethods
}

function joinContent(jsonString) {
  try {
    // Добавить запятые в качестве разделителей между объектами JSON
    const jsonArrayString = `[${jsonString.replace(/}{/g, '},{')}]`;
    console.log("jsonArray - ", jsonArrayString);

    // Преобразовать строку JSON в массив объектов
    const jsonArray = JSON.parse(jsonArrayString);

    // Используем метод map для получения массива значений свойства "content"
    const contentArray = jsonArray.map(item => item.content);

    // Используем метод join для объединения значений в одну строку
    return contentArray.join('');
  } catch (error) {
    console.error('Ошибка при обработке JSON:', error);
    return null;
  }
}

/**
 * @param {Response} resp - read stream from backend
 * */
 function readStream(resp) {
    const reader = resp.body.getReader();
    // read() returns a promise that resolves when a value has been received
    reader.read().then(function pump({ done, value }) {
        value = new TextDecoder().decode(value);
        let testValue = joinContent(value)
      console.log('testValue - ', testValue);
        let splittedValues = value.split('{"');
        console.log("splittedValues - ", splittedValues);
        splittedValues.shift();
        splittedValues = splittedValues.map(el => `{"${el}`).map(el => JSON.parse(el));
        store.dispatch('chatgpt/setStreamedText', splittedValues);
        if (done) {
            store.dispatch('chatgpt/setStreamedText', [{content: '', finish: true}]);
            return;
        }

        // Read some more, and call this function again
        return reader.read().then(pump);
    });
}

/**
 * @param {string} msg - send request to chatgpt with msg param
 * */
async function sendRequest(msg){
    const payload = {msg};
    getToken();
    fetch(`${CHATGPT_URL}/stream/chatgpt/chatgpt`, {
       method: "POST",
       headers: {
           "Content-Type": "application/json",
           Authorization: CHATGPT_AUTH_TOKEN
       },
       body: JSON.stringify(payload)
   })
        .then((response) => {
            readStream(response);
        })
        .catch((err) => store.dispatch('alert/error', err));
}

/**
 * function to answer about process
 * */
async function showProcess() {
    fetch(`${CHATGPT_URL}/plugin/chatgpt/showProcess`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: CHATGPT_AUTH_TOKEN
        },
    })
        .then((response) => {
            readStream(response);
        })
        .catch((err) => store.dispatch('alert/error', err));
}

/**
 * test function to test stream functional
 * */
async function testStream() {
    fetch(`${CHATGPT_URL}/stream/chatgpt/root`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: CHATGPT_AUTH_TOKEN
        },
    })
        .then((response) => {
            readStream(response);
        })
        .catch((err) => store.dispatch('alert/error', err));
}

/**
 * @param {object} file - file which we upload
 * load open api file to service, get stream text on response
 * */
async function loadOpenApi(file) {
    let isError = false;
    const payload = generateFormData(file);
    const resp = await fetch(`${CHATGPT_URL}/plugin/chatgpt/loadOpenapi`, {
        method: "POST",
        headers: {
            Authorization: CHATGPT_AUTH_TOKEN
        },
        body: payload,
    })
        .then((response) => {
            readStream(response);
        })
        .catch((err) => {
          isError = true
          store.dispatch('alert/error', err);
        })
  if (isError) {
    isError = false;
    return null;
  }
}

/**
 * @param {string} action - type of action
 * select action and post it to service, get stream text on response
 * */
async function selectAction(action) {
    const payload = JSON.stringify({action});
    fetch(`${CHATGPT_URL}/plugin/chatgpt/selectAction`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: CHATGPT_AUTH_TOKEN
        },
        body: payload,
    })
        .then((response) => {
            readStream(response);
        })
        .catch((err) => store.dispatch('alert/error', err));
}

/**
 * return list of methods for methods modal
 * */
async function showMethods() {
   let response = await fetch(`${CHATGPT_URL}/plugin/chatgpt/showMethods`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: CHATGPT_AUTH_TOKEN
        },
    })
       .catch((err) => store.dispatch('alert/error', err));

   if (response.ok) {
       response = await response.json();
       return response;
   }
}