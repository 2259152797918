export default {
  data(){
    return{
      dataDeletedNode: null,
      isDeleteAction: false,
    }
  },
  computed: {
    actionsProcess: {
      cache: false,
      get(){
        return this.$store.getters['processes/actionsProcess']
      }
    },  

    currentNode() {
      return this.$store.getters['node/currentNode']
    },

    currentPreset: {
      cache: false,
      get(){
        return this.$store.getters['node/currentPreset'].id
      }
    },

    availableScreens() {
      return this.$store.getters['node/availableScreens']
    },

    settings() {
      return this.$store.getters['node/settings']
    },

    screens() {
      return this.settings.screens
    },

    toCurrentScreen(){ 
      return this.actionsProcess.filter(action => {
        return this.screens
          .map(screen => screen.id === action.nodeFromId)[0]
        })
    },

    outerConnections() {
      return this.$store.getters['node/outerConnections']
    },

    actionForElement(){
      return [
        {name: 'Action', type: 'actionId', item: this.getActions}, 
        {name: 'Event', type: 'eventId', item: this.getEvents}, 
        {name: 'Process', type: 'process', item: this.processes},
        {name: 'Find method', type: 'findMethod'},
        {name: 'Select node', type: 'goTo', item: this.filterOfSelectNode},
        {name: 'Select preset', type: 'preset', item: this.selectPresets},
        {name: 'Select screen', type: 'screen', item: this.selectScreens},
        {name: 'Order', type: 'order', item: [{name:1, id:1},{name:2, id:2},{name:3, id:3},{name:4, id:4},]}
      ]
    },

    getActions(){
      const actions = this.$store.getters['actions/actions']
      actions.map(action => {
        action.name = action.actionName
        return action
      })

      return actions
    },

    getEvents(){
      const events = this.$store.getters['events/events']
      events.map(event => {
        event.name = event.eventName
        return event
      })

      return events
    },

    availableScreensAtNode: {
      cache: false,
      get(){
        return this.actionForElementData?.goTo
          ? this.availableScreens
            ? this.availableScreens.filter(screen => screen.nodeId === this.actionForElementData.goTo)
            : null
          : null
      }
    },

    currentScreenId() {
      return this.$store.getters['node/currentScreenId']
    },

    processes(){
      return this.$store.getters['processes/processes']
    },

    selectNode(){
      const nodes = this.$store.getters['processes/getProcessForAction']?.nodes

      return nodes
          ? Object.values(nodes)
              .map(node => {
                const name = node.displayName === node.title
                    ? node.title
                    : `${node.title} (${node.displayName})`

                return {id: node.db_id, name}
              })
              .map(node => {
                node.id === this.currentNode.db_id
                  ? node.name = this.$t('node.intoCondition.currentNode')
                  : node

                return node
              })
          : []
    },

    filterOfSelectNode(){
      return this.defaultTransition?.length
          ? this.selectNode.filter(node => {
                return this.defaultTransition?.some(item => item === node.id)
                  ? node
                  : null
            })
          : this.selectNode
    },

    selectPresets: {
      cache: false,
      get(){
        return this.$store.getters['node/presetsOnSelectedNode']
      }
    },
    selectScreens: {
      cache: false,
      get(){
        return this.$store.getters['node/screensOnSelectedNode']
      }
    },
  },
  methods: {
    popupDeleteRuleElement(ruleElementData){
      if(ruleElementData) this.dataDeletedNode = {processId: ruleElementData.processId, actionProcessId: ruleElementData.id}
      else this.dataDeletedNode = null

      this.$store.dispatch('node/heightScreenEditComponent', 0)
      this.isDeleteAction = !this.isDeleteAction
      this.showPopupBg()
    },

    deleteRuleElement(){
      if(!this.dataDeletedNode) console.log('dataDeletedNode is undefined')
      else this.$store.dispatch('processes/deleteActionsProcess', this.dataDeletedNode)

      this.isDeleteAction = !this.isDeleteAction
      this.showPopupBg()
    },

    nameOfAction(data) {
      return this.$t('node.actions.on')
          + this.actionForElement.find(item => item.type === 'eventId')?.item
              .find(item => item.id === data.eventId)?.name
          + this.$t('node.actions.do')
          + this.actionForElement.find(item => item.type === 'actionId')?.item
              .find(item => item.id === data.actionId)?.name
    },

    popupEditRuleElement(action) {
      this.isEditAction = true
      this.actionForElementData = action

      const connectionData = this.availableScreens.find(node => node.screenId === action.nodeToId)
      this.actionForElementData.goTo = connectionData?.nodeId
      this.actionForElementData.screen = connectionData?.screenId

      this.isShowAddAction = !this.isShowAddAction
      this.showPopupBg()
    }
  }
}