import {node} from "@/store/modules/node.module";

export default {
  data() {
      return {
          bodyOptions: {
              group: {
                  name: "elements",
              },
          },
          toolboxItemsOptions: {
              group: {
                  name: "elements",
                  pull: "clone",
                  put: false,
              },
              sort: false,
          },
      }
  },
  methods: {
      /**
       * Get types dictionary
       * */
      async getTypesDictionary() {
          await this.$store.dispatch('pageBuilder/getTypesDictionary');
      },

      /**
       * check rules for includes in body, wrappers
       * @param {Object} e - event
       */
      checkMove(e) {
          return true;
          // const blockedItemTypes = e.to.__vue__.$options.propsData?.options?.ignore.split(",") || e.to.__vue__.$options.propsData?.element?.options.ignore.split(",");
          // const movedItemType = e.dragged._underlying_vm_.type;
          // if (blockedItemTypes.includes(movedItemType.trim())) {
          //     return false;
          // }
      },

      /** made unique uuid
       * @param {Object} e - event
       * @return {String}
       * */
      uuid(e) {
          if (e.uid) return e.uid;

          const key = Math.random()
              .toString(16)
              .slice(2);

          this.$set(e, "uid", key);

          return e.uid;
      },

      /** Make right styles for builder elements
       * @param {Object} styles - object of element's styles
       * @return {Object} - right styles for element
       * */
      correctStyles(styles) {
          return {
              ...styles,
              marginTop: `${styles?.marginTop}px`,
              marginBottom: `${styles?.marginBottom}px`,
              marginLeft: `${styles?.marginLeft}px`,
              marginRight: `${styles?.marginRight}px`,
              paddingTop: `${styles?.paddingTop}px`,
              paddingBottom: `${styles?.paddingBottom}px`,
              paddingLeft: `${styles?.paddingLeft}px`,
              paddingRight: `${styles?.paddingRight}px`,
              width: `${styles?.width}px`,
              height: `${styles?.height}px`,
          }
      },

    /**
     * @param {object} added - when elem add to drag zone
     * @param {object} moved - when elem moved into drag zone
     * @param {object} removed - when elem removed from drag zone
     * @param {object} nodePart - part of node which we need to save
     * @param {number} partId - id of nodePart which we need to save
     * trigger when any event happened on drag zone
     * */
    async changeElementPosition({added, moved, removed}, nodePart, partId) {
      const currentNodeId = localStorage.getItem('currentNodeId');
      let isSave = false;
      if (added) {
          nodePart.nodeFields = nodePart.nodeFields.map(field => {
            // @See: https://gl-1.2pp.dev/bpm-system/bpm-system-admin/-/issues/131
            if (!field?.name) {
              field.name = nodePart?.name + "." + field?.nodeType;
            }
            
              if (field.id === added.element.id && !added.element?.isCreated) {
                  console.log("Добавляем isMove");
                  field.isMove = true;
              } else if (field.id === added.element.id && added.element?.isCreated) {
                  console.log("Не добавляем isMove, убираем isCreated");
                  isSave = true;
                  // Invalid field name:
                  field.fieldName = field.name;
                  // Fix invalid field case
                  field.fieldname = field.name;
                  delete field.isCreated;
                  this.openSettings(null);
              }
              return field;
          })
        if (isSave) {
          /** СОХРАНЕНИЕ ДЛЯ НОВОГО РЕДАКТОРА, НО НУЖНО ПЕРЕПИСАТЬ - ЭТА РЕАЛИЗАЦИЯ ТАК СЕБЕ. ЕСТЬ ЕЩЕ ДРУГИЕ РЕАЛИЗАЦИИ, НО Я НЕ ПОМНЮ, НА КАКИХ ВЕТКАХ))))) */
          await this.saveUnsavedParts(currentNodeId);
          await this.replaceNode(nodePart, partId);
          isSave = false;
          return;
        }
      }
      if (added || moved) {
        if (localStorage.getItem(`nodeParts-${currentNodeId}`)) {
          const localNodeParts = {
            ...JSON.parse(localStorage.getItem(`nodeParts-${currentNodeId}`)),
            [partId]: nodePart,
          }
          localStorage.setItem(`nodeParts-${currentNodeId}`, JSON.stringify(localNodeParts));
        } else {
          const localNodeParts = {
            [partId]: nodePart
          }
          localStorage.setItem(`nodeParts-${currentNodeId}`, JSON.stringify(localNodeParts));
        }
      }
    },
    /**
     * @param {number | string} mainId - id of node
     * save unsaved parts of node
     * */
    async saveUnsavedParts(mainId) {
      if (localStorage.getItem(`nodeParts-${mainId}`)) {
        console.log("Сохраняем");
        let unsavedParts = JSON.parse(localStorage.getItem(`nodeParts-${mainId}`));
        for (let key in unsavedParts) {
          await this.replaceNode(unsavedParts[key], unsavedParts[key].id);
        }
        localStorage.removeItem(`nodeParts-${mainId}`);
      }
    }
  }
}