<template>
  <div>
    <SpinnerLoader
        v-if="loading"
    />
    <div v-else>
      <NodeScreen
          v-if="screen && !error"
          :active-actions="false"
          :setting="screen"
      />
      <div v-else>
        Не передан id экрана
      </div>
    </div>
  </div>
</template>

<script>
import NodeScreen from "@/components/nodes/nodeScreens/NodeScreen.vue";
import SpinnerLoader from "@/components/UI/SpinnerLoader.vue";

export default {
  name: "NodeRedactorFrame",
  components: {SpinnerLoader, NodeScreen},
  data() {
    return {
      loading: false,
      error: false,
    }
  },
  computed: {
    screen() {
      return this.$store.getters["node/currentScreen"] || null;
    }
  },
  async mounted() {
    let nodeId = location.href.split('?')[1]
    await this.getScreen(nodeId)
  },
  methods: {
    async getScreen(id) {
      try {
        this.loading = true;
        await this.$store.dispatch('node/setEditScreen', {id});
        this.error = false;
      } catch (e) {
        this.error = true;
      } finally {
        this.loading = false;
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>