
import { EventBus } from "@/utils/event-bus";
export default{
  data(){
    return{
      overflowY: false
    }
  },
  methods: {
    hideScrollY(){ 
      const activeSelect = document.querySelectorAll('.v-select--is-menu-active')
      if(activeSelect.length){
          if(!this.overflowY) EventBus.$emit('setOverflowY', true)
          this.overflowY = true
      } else {
          EventBus.$emit('setOverflowY', false)
          this.overflowY = false

          return 
      }
      
      setTimeout(() => this.hideScrollY(), 300)
    }
  }
}