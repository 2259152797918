<template>
  <div class="layout-editor">
    <label
      class="layout-editor__title"
      @click="toggleControls"
      :class="[controlsVisible ? 'active' : '']"
    >
      Layout
      <i
        aria-hidden="true"
        class="v-icon notranslate mdi mdi-chevron-down theme--light" 
      />
    </label>

    <div v-if="controlsVisible" class="layout-editor__container">
      Display:
      <div class="layout-editor__positions-selector">
        <div v-for="(type, label) in layoutTypes" :key="type">
          <input
            type="radio"
            :id="type"
            :key="label"
            v-model="localLayout.type"
            :value="type"
            name="layout"
            @change="resetAndSaveLayoutType(type)" 
          />
          <label :key="type" :for="type">{{ label }}</label>
        </div>
      </div>

      <div
        v-if="localLayout.type.toLowerCase() === 'flex'"
        class="layout-editor__layout-settings"
      >
        <label v-for="(option, name) in flexSettings" :key="name">
          <span>{{ option.label }}:</span>
          <input
            v-if="option.type == 'number'"
            type="number"
            class="input-number"
            v-model="settingsValues[name]"
            @change="saveSetting(name)" 
          />
          <select
            v-else
            v-model="settingsValues[name]"
            @change="saveSetting(name)"
          >
            <option value="" disabled selected></option>
            <option v-for="value in option.values" :value="value" :key="value">
              {{ value }}
            </option>
          </select>
        </label>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LayoutEditor",
  data() {
    return {
      controlsVisible: false,
      localLayout: {
        type: "Block",
      },
      layoutTypes: {
        block: "Block",
        flex: "Flex",
        none: "None",
      },
      flexSettings: {
        direction: {
          label: "flex-direction",
          values: ["row", "column", "row-reverse", "column-reverse"],
        },
        flexWrap: {
          label: "flex-wrap",
          values: ["nowrap", "wrap"],
        },
        justifyContent: {
          label: "justify-content",
          values: [
            "flex-start",
            "center",
            "flex-end",
            "space-between",
            "space-around",
            "space-evenly",
          ],
        },
        alignItems: {
          label: "align-items",
          values: ["flex-start", "center", "flex-end", "stretch", "baseline"],
        },
        gap: {
          label: "gap",
          type: "number",
        },
      },
      settingsValues: {
        type: "",
      },
    };
  },
  methods: {
    toggleControls() {
      this.controlsVisible = !this.controlsVisible;
    },
    resetAndSaveLayoutType(layoutType) {
      this.settingsValues = {};
      this.$set(this.settingsValues, "type", layoutType);

      if (layoutType.toLowerCase() === "flex") {
        for (let key in this.flexSettings) {
          this.$set(this.settingsValues, key, "");
        }
      }
    },
    saveSetting(settingName) {
      this.$set(this.settingsValues, settingName, this.settingsValues[settingName]);
    },
  },
};
</script>

<style lang="scss" scoped>
.layout-editor {
  &__title {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    width: 100%;
    text-transform: capitalize;
    font-size: 16px;
    font-weight: 600;
    background-color: #f5f7fb;
    border-radius: 10px;
    border: none;
    cursor: pointer;

    i {
      transition: transform 0.3s ease;
    }

    &.active {
      i {
        transform: rotate(-180deg);
      }
    }
  }

  &__container {
    position: relative;
    padding: 8px;
    margin-top: 16px;
    border: 1px solid #e9e9e9;
    border-radius: 4px;
  }

  &__positions-selector {
    display: flex;
    width: 100%;
    gap: 8px;
    margin-top: 16px;
    font-size: 14px;

    label {
      display: inline-block;
      text-align: center;
      text-transform: capitalize;
      width: 100%;
      padding: 4px;
      border-radius: 5px;
      font-size: 14px;
      border: 1px solid rgb(226, 223, 223);
      background-color: #e9ecf8;
      transition: 0.3s background-color ease;
      cursor: pointer;
    }

    input[type="radio"] {
      display: none;
    }

    input[type="radio"]:checked ~ label {
      background-color: $main-white;
    }

    & > div {
      width: 100%;
    }
  }

  &__layout-settings {
    display: flex;
    flex-direction: column;
    margin-top: 15px;
    gap: 8px;

    select {
      width: 200px;
      margin-left: 8px;
      padding: 4px;
      text-align: center;
      font-size: 14px;
      border: 1px solid rgb(232, 228, 228);
      border-radius: 4px;
      outline: none;
      height: 28px;
      cursor: pointer;

      &::focus {
        border-color: #a09f9f;
      }
    }

    label {
      display: flex;
      justify-content: space-between;
    }

    input {
      position: static;

      &::placeholder {
        color: $main-black;
        font-size: 14px;
      }
    }

    input[type="number"] {
      border: 1px solid rgb(226, 223, 223);
      width: 200px;
      border-radius: 4px;
      background-color: white;
      font-size: 14px;
      text-align: center;
      z-index: 1;
      outline: none;
      margin-left: 8px;
      height: 28px;

      &:focus {
        border-color: #a09f9f;
      }
    }
  }
}
</style>
