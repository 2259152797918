<template>
  <div class="position-editor">
    <label
      class="position-editor__title"
      @click="toggleControls"
      :class="{ active: controlsVisible }"
    >
      Position
      <i
        aria-hidden="true"
        class="v-icon notranslate mdi mdi-chevron-down theme--light"
      />
    </label>

    <div v-if="controlsVisible" class="position-editor__container">
      <div>
        <label for="position">Position:</label>
        <select id="position" @change="resetAndSaveType($event.target.value)">
          <option v-for="option in positionOptions" :key="option.value" :value="option.value">{{ option.label }}</option>
        </select>
      </div>

      <div v-if="position.type.toLowerCase() !== 'static'">
        <div class="position-editor__square">
          <div class="position-editor__square-out">
            <input
              v-for="(input, key) in positionInputs"
              :key="key"
              type="number"
              :class="`position-editor__${positionInputs[key]}`"
              v-model="position[key]"
            />
            <div class="position-editor__square-inner" />
          </div>
        </div>

        <div>
          <label for="z-index">z-index:</label>
          <input type="number" id="z-index" v-model="position.zIndex" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PositionEditor",
  data() {
    return {
      controlsVisible: false,
      position: {
        type: "static",
        top: "",
        left: "",
        right: "",
        bottom: "",
        zIndex: "auto",
      },
      positionOptions: [
        { value: "static", label: "Static" },
        { value: "relative", label: "Relative" },
        { value: "absolute", label: "Absolute" },
        { value: "fixed", label: "Fixed" },
        { value: "sticky", label: "Sticky" },
      ],
      positionInputs: ["top", "left", "right", "bottom"],
      settingsValues: {},
    };
  },
  watch: {
    position: {
      handler() {
        this.saveSetting();
      },
      deep: true,
    },
  },
  methods: {
    toggleControls() {
      this.controlsVisible = !this.controlsVisible;
    },
    resetAndSaveType(type) {
      this.settingsValues = {};
      Object.assign(this.position, {
        type,
        top: "",
        left: "",
        right: "",
        bottom: "",
        zIndex: "auto",
      });
    },
    saveSetting() {
      if (this.position.type.toLowerCase() !== "static") {
        this.settingsValues = { ...this.position };
      } else {
        this.settingsValues = { type: "static" };
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.position-editor {
  display: flex;
  flex-direction: column;

  &__title {
    text-transform: capitalize;
    font-size: 16px;
    font-weight: 600;
    background-color: #f5f7fb;
    border-radius: 10px;
    border: none;
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 10px;
    cursor: pointer;

    i {
      transition: transform 0.3s ease;
    }

    &.active {
      i {
        transform: rotate(-180deg);
      }
    }
  }

  &__container {
    margin-top: 16px;
    position: relative;
    padding: 8px;
    border: 1px solid #e9e9e9;
    border-radius: 4px;
    padding: 15px 20px;
  }

  &__square {
    display: flex;
    position: relative;
    align-items: center;
    height: 150px;
    width: 100%;
    padding: 15px 0;
    text-align: center;

    &-out {
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 100%;
      background-color: #E9ECF8;
    }

    &-inner {
      position: relative;
      height: 50%;
      width: 50%;
      border: 1px dashed $main-white;
    }

    input {
      position: absolute;
      width: 45px;
      height: 21px;
      border: 1px solid rgb(226, 223, 223);
      border-radius: 2px;
      background-color: $main-white;
      font-size: 14px;
      text-align: center;
      z-index: 1;
      outline: none;

      &:focus {
        border-color: #a09f9f;
      }
    }
  }

  &__top {
    top: 5px;
    left: 50%;
    transform: translateX(-50%);
  }

  &__right {
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
  }

  &__bottom {
    bottom: 5px;
    left: 50%;
    transform: translateX(-50%);
  }

  &__left {
    top: 50%;
    left: 20px;
    transform: translateY(-50%);
  }

  #position,
  #z-index {
    text-align: center;
    padding: 4px;
    border: 1px solid rgb(232, 228, 228);
    border-radius: 4px;
    background-color: $main-white;
    color: #333;
    width: 200px;
    font-size: 14px;

    &:focus {
      outline: none;
      border-color: #a09f9f;
    }
  }

  #z-index {
    width: 50px;
  }

  #position {
    cursor: pointer;
    width: 100px;

    option {
      background-color: $main-white;
      color: #333;
    }
  }

  input {
    &::placeholder {
      color: $main-black;
      font-size: 14px;
    }

    &::focus {
      border-color: #a09f9f;
    }
  }

  label {
    margin-right: 16px;
  }
}
</style>
