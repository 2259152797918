<template>
  <div class="page-builder">
    <v-card class="page-builder__toolbox page-builder__column">
      <v-tabs 
        v-model="defaultTab" 
        color="#5A717C" 
        grow 
        height="40"
      >
        <v-tab>
          <v-icon left>
            mdi-code-json
          </v-icon>
          Explorer
        </v-tab>
        <v-tab>
          <v-icon left>
            mdi-arrange-send-backward
          </v-icon>
          Elements
        </v-tab>

        <v-tab-item v-if="$store.getters['pageBuilder/types'].length">
          <TreeNode
            v-if="Object.keys(currentNode).length"
            :node="currentNode"
            :disabled="elementsLoading"
            :target-id="currentWorkNode.id"
            :root="true"
          />
          <TreeNode
            v-else
            :node="currentWorkNode"
            :disabled="elementsLoading"
            :root="true"
            :target-id="currentWorkNode.id"
          />
        </v-tab-item>
        <v-tab-item>
          <v-text-field
            :label="$t('search')"
            append-icon="mdi-magnify"
            v-model="toolboxSearch"
            filled
            dense
            outlined
            color="#96ADB8C"
            hide-details
          />
          <v-expansion-panels 
            class="page-builder__expansion-panels-elements" 
            :disabled="elementsLoading" 
            flat
          >
            <v-expansion-panel
              v-for="tool in toolboxItems"
              :key="tool.id"
              :class="tool.class"
              @click="getElementsByType(tool.id)"
            >
              <v-expansion-panel-header>
                <span v-if="tool.label.toLowerCase() === 'wrappers'">
                  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18"><path d="M2 2h59.986v59.986H2Zm0 0" style="fill:none;stroke-width:4;stroke-linecap:butt;stroke-linejoin:miter;stroke:currentColor;stroke-opacity:1;stroke-miterlimit:10" transform="scale(.28125)"/></svg>
                  {{ tool.label }}
                </span>
                <span v-else>
                  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18"><path d="M16.945 16.945H6.715V6.715h10.23ZM3.883 14.117V3.883h10.234V5.66h-7.93a.525.525 0 0 0-.527.527v7.93Zm-2.828-2.832V1.055h10.23v1.773h-7.93a.53.53 0 0 0-.527.527v7.93ZM17.473 5.66h-2.301V3.355a.53.53 0 0 0-.527-.527H12.34v-2.3A.525.525 0 0 0 11.813 0H.527A.525.525 0 0 0 0 .527v11.286c0 .292.234.527.527.527h2.301v2.305c0 .289.238.527.527.527H5.66v2.3c0 .294.235.528.527.528h11.286a.525.525 0 0 0 .527-.527V6.188a.525.525 0 0 0-.527-.528" style="stroke:none;fill-rule:nonzero;fill:currentColor;fill-opacity:1"/></svg>
                  {{ tool.label }}
                </span>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <div v-if="tool.id != 3 && tool.id != 4">
                  <SpinnerLoader
                    v-if="palleteLoading || elementsLoading"
                    color="blue" 
                  />
                  <draggable
                    v-else
                    v-model="filteredToolboxElements[tool.id]"
                    :clone="handleClone"
                    :move="checkMove"
                    @change="canAdd($event)"
                    :options="toolboxItemsOptions"
                    class="page-builder__toolbox-container"
                  >
                    <div
                      v-for="toolElement in filteredToolboxElements[tool.id]"
                      :key="toolElement.id"
                      class="page-builder__toolbox-item"
                    >
                      <BuilderElement
                        :element="toolElement"
                        :canSetting="false" 
                      />
                    </div>
                  </draggable>
                </div>

                <!-- Tools with categories (id = 3, id = 4) -->
                <div v-if="tool.id == 3 || tool.id == 4">
                  <SpinnerLoader
                    v-if="palleteLoading || elementsLoading"
                    color="blue" 
                  />
                  <v-expansion-panels v-else flat>
                    <v-expansion-panel
                      v-for="(element, index) in getElementsWithCategories(filteredToolboxElements[tool.id])"
                      :key="`${element.id} - ${index}`"
                      class="page-builder__category"
                    >

                      <v-expansion-panel-header>
                        <span>
                          <svg v-if="element.name == 'image'" xmlns="http://www.w3.org/2000/svg" width="18" height="18"><path d="M15.75.703H2.25A1.55 1.55 0 0 0 .703 2.25v13.5a1.55 1.55 0 0 0 1.547 1.547h13.5a1.55 1.55 0 0 0 1.547-1.547V2.25A1.55 1.55 0 0 0 15.75.703Zm-13.5.844h13.5a.69.69 0 0 1 .496.207.69.69 0 0 1 .207.496v10.922l-3.515-3.52a.427.427 0 0 0-.297-.125.436.436 0 0 0-.301.125l-2.328 2.332-4.13-4.129a.473.473 0 0 0-.136-.093.45.45 0 0 0-.324 0 .473.473 0 0 0-.137.093l-3.738 3.739V2.25a.69.69 0 0 1 .207-.496.69.69 0 0 1 .496-.207ZM1.547 15.75v-2.965L5.582 8.75l7.703 7.703H2.25a.69.69 0 0 1-.496-.207.69.69 0 0 1-.207-.496Zm14.203.703h-1.27l-3.875-3.871 2.032-2.035 3.816 3.816v1.387a.69.69 0 0 1-.207.496.69.69 0 0 1-.496.207Zm0 0" /><path d="M9.324 6.965c.403 0 .793-.121 1.13-.344.331-.223.593-.539.745-.91.153-.371.196-.781.117-1.176A2.04 2.04 0 0 0 9.72 2.941c-.395-.078-.801-.039-1.172.118a2.025 2.025 0 0 0-1.254 1.875 2.031 2.031 0 0 0 2.031 2.031Zm0-3.219c.235 0 .465.07.66.2.196.132.348.316.438.534.09.215.113.454.066.684a1.22 1.22 0 0 1-.324.61 1.22 1.22 0 0 1-.61.324 1.196 1.196 0 0 1-1.219-.504 1.202 1.202 0 0 1-.108-1.114 1.178 1.178 0 0 1 .644-.644c.14-.059.297-.09.453-.09Zm0 0" /></svg>
                          <svg v-else-if="element.name == 'input'" xmlns="http://www.w3.org/2000/svg" width="18" height="18"><path style="stroke: none;fill-rule: nonzero;fill: #000;fill-opacity: 1;" d="M18 5.625c0-.676-.45-1.125-1.125-1.125H1.125C.449 4.5 0 4.95 0 5.625v6.75c0 .676.45 1.125 1.125 1.125h15.75c.676 0 1.125-.45 1.125-1.125Zm-1.125 6.75H1.125v-6.75h15.75Zm0 0" /><path style="stroke: none;fill-rule: nonzero;fill: #000;fill-opacity: 1;" d="M2.25 6.75h1.125v4.5H2.25Zm0 0" /></svg>
                          <svg v-else-if="element.name == 'button'" xmlns="http://www.w3.org/2000/svg" width="18" height="17" viewBox="0 0 17 17"><path d="M2.492 5.313h12.242c.09 0 .176.039.239.105a.351.351 0 0 1 .101.25v5.664a.351.351 0 0 1-.101.25.329.329 0 0 1-.239.105H2.492a.329.329 0 0 1-.238-.105.351.351 0 0 1-.102-.25V5.668c0-.094.035-.184.102-.25a.329.329 0 0 1 .238-.106Zm-1.36.355c0-.379.145-.738.4-1.004.253-.266.6-.414.96-.414h12.242c.36 0 .707.148.961.414s.399.625.399 1.004v5.664c0 .379-.145.738-.399 1.004a1.331 1.331 0 0 1-.96.414H2.491c-.36 0-.707-.148-.96-.414a1.452 1.452 0 0 1-.4-1.004Zm4.083 2.3a.5.5 0 0 0-.363.157.546.546 0 0 0 0 .75.5.5 0 0 0 .363.156h6.797a.5.5 0 0 0 .363-.156.546.546 0 0 0 0-.75.5.5 0 0 0-.363-.156Zm0 0" style="stroke: none;fill-rule: evenodd;fill: #000;fill-opacity: 1;" /></svg>
                          <svg v-else-if="element.name == 'icon'" xmlns="http://www.w3.org/2000/svg" width="18" height="17" viewBox="0 0 17 17"><path d="M15.477 6.46c-.063-.198-.18-.374-.336-.503a1.016 1.016 0 0 0-.547-.227l-3.785-.34-1.485-3.675a1.022 1.022 0 0 0-.375-.473.973.973 0 0 0-1.125 0 1.022 1.022 0 0 0-.375.473L5.97 5.39l-3.79.34c-.202.019-.39.097-.55.226a1.106 1.106 0 0 0-.336.504c-.063.2-.066.414-.012.617.055.2.164.379.317.516l2.875 2.62-.864 3.888c-.043.203-.03.414.04.609.07.2.195.367.355.492a1 1 0 0 0 1.129.05l3.25-2.058 3.258 2.059c.171.105.37.16.57.148a.989.989 0 0 0 .555-.199c.164-.125.289-.293.355-.492a1.08 1.08 0 0 0 .04-.61l-.86-3.89 2.875-2.617c.152-.137.261-.317.312-.52.055-.199.051-.414-.011-.613Zm-.973.333-2.875 2.613a1.13 1.13 0 0 0-.3.477 1.16 1.16 0 0 0-.024.566l.867 3.895-3.258-2.059a.984.984 0 0 0-1.055 0l-3.25 2.059.86-3.89c.039-.188.031-.388-.024-.571a1.093 1.093 0 0 0-.3-.473l-2.88-2.613v-.008l3.79-.34a1.022 1.022 0 0 0 .852-.648l1.48-3.672L9.867 5.8a1.001 1.001 0 0 0 .848.648l3.793.34v.008Zm0 0" style="stroke: none;fill-rule: nonzero;fill: #000;fill-opacity: 1;" />
                          </svg>
                          <svg v-else-if="element.name == 'text'" xmlns="http://www.w3.org/2000/svg" width="15" height="15"><path style="stroke: none;fill-rule: evenodd;fill: currentColor;fill-opacity: 1;" d="M15 0v4.5h-1.5c0-1.66-1.34-3-3-3H9.441c-.66 0-1.191.531-1.191 1.188V12.75c0 .414.336.75.75.75h1.5V15h-6v-1.5H6a.75.75 0 0 0 .75-.75V2.687c0-.656-.531-1.187-1.191-1.187H4.5c-1.66 0-3 1.34-3 3H0V0Zm0 0" /></svg>
                          <svg v-else xmlns="http://www.w3.org/2000/svg" width="18" height="18"><path d="M2 2h59.986v59.986H2Zm0 0" style="fill: none;stroke-width: 4;stroke-linecap: butt;stroke-linejoin: miter;stroke: currentColor;stroke-opacity: 1;stroke-miterlimit: 10;" transform="scale(.28125)" />
                          </svg>
                          {{ element.name }}
                        </span>
                      </v-expansion-panel-header>
                      
                      <v-expansion-panel-content>
                        <draggable
                          v-model="element.items"
                          :clone="handleClone"
                          :move="checkMove"
                          @change="canAdd($event)"
                          :options="toolboxItemsOptions"
                          class="page-builder__toolbox-container">
                          <div
                            v-for="toolElement in element.items"
                            :key="toolElement.id"
                            class="page-builder__toolbox-item">
                            <BuilderElement
                              :el-in-search="true"
                              :node-type-id="tool.id"
                              :element="toolElement"
                              :canSetting="false" />
                          </div>
                        </draggable>
                      </v-expansion-panel-content>

                    </v-expansion-panel>
                  </v-expansion-panels>
                </div>

              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>

        </v-tab-item>
      </v-tabs>
    </v-card>

    <div class="page-builder__phone-wrapper">
      <v-card class="page-builder__info">
        <a href="/" target="_blank">
          <img :src="phoneIcon" alt="book-icon" />
          <span> {{ $t("preview") }}</span>
        </a>

        <a href="/" target="_blank">
          <img :src="openBookIcon" alt="open-book" />
          <span> {{ $t("guides") }}</span>
        </a>
      </v-card>

      <PhoneWrapper
        :screen-name="currentWorkNode?.title ? currentWorkNode.title : ''"
        :node-name="currentNode?.title ? currentNode.title : ''"
        :cursor="'default'"
        :custom-class="'phone-screen__main_full'"
        class="page-builder__column page-builder__phone"
      >
        <SpinnerLoader
          v-if="elementsLoading"
          color="blue"
          style="margin: auto;"
        />
        <draggable
          v-else
          v-model="currentWorkNode.nodeFields"
          :options="bodyOptions"
          :move="checkMove"
          @change="changeElementPosition($event, currentWorkNode, currentWorkNode.id)"
          class="page-builder__dropzone page-builder__dropzone_invisible"
        >
          <BuilderWrapper
            v-if="field"
            v-for="field in currentWorkNode.nodeFields"
            :key="uuid(field) || field.id"
            :element="field"
            :data-id="field.id"
            :data-type="field.nodeType"
          />
        </draggable>
      </PhoneWrapper>
    </div>

    <div
      class="page-builder__column page-builder__settings"
      :class="editingProperties ? 'open-editor' : ''"
    >
      <BuilderElementSettings
        v-if="editingProperties"
        :editingElement="editingElement"
        :editingProperties="editingProperties"
        :loading="propertiesLoading"
        @saveProperties="saveNodeProperties"
        @saveNewElementProperties="saveNewElementProperties"
      />
      <div 
        class="icon" 
        v-if="!editingProperties && !propertiesLoading"
      >
        <img :src="saveIcon" alt="save-icon" />
      </div>
      <v-btn
        :disabled="elementsLoading"
        class="mt-3"
        @click="saveCurrentNode"
        v-if="!propertiesLoading"
      >
        {{ $t("save") + " screen" }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable';

import PhoneWrapper from "@/components/utils/PhoneWrapper.vue";
import BuilderElement from "@/components/page-builder/BuilderElement.vue";
import pageBuilder from "@/mixins/pageBuilder";
import BuilderWrapper from "@/components/page-builder/BuilderWrapper.vue";
import {pageBuilderService} from "@/services/pageBuilder.service";
import {EventBus} from "@/utils/event-bus";
import SpinnerLoader from "@/components/UI/SpinnerLoader.vue";
import TreeNode from "@/components/page-builder/TreeNode.vue";
import BuilderElementSettings from "@/components/page-builder/BuilderElementSettings.vue";

export default {
  name: "PageBuilder",
  components: {
    BuilderElementSettings,
    TreeNode,
    SpinnerLoader,
    BuilderWrapper,
    BuilderElement,
    PhoneWrapper,
    draggable,
  },
  mixins: [pageBuilder],
  data() {
    return {
      elementsLoading: false,
      propertiesLoading: false,
      palleteLoading: false,
      currentTypeId: null,
      toolboxElements: {},
      filteredToolboxElements: {},
      currentNode: {},
      currentWorkNode: {},
      toolboxSearch: '',
      toolboxItems: [
        {
          id: 3,
          type: 'field',
          label: 'Fields',
          class: "page-builder__fields"
        },
        {
          id: 4,
          type: 'widget',
          label: 'Widgets',
        }
        // {
        //   id: 10,
        //   type: 'wrapper',
        //   label: 'Wrappers',
        //   elements: [],
        //   options: {
        //     group: {
        //       name: "elements",
        //     },
        //     ignore: 'input',
        //   },
        //   class: 'page-builder__dropzone_wrapper'
        // },
      ],
      sections: [ ],
      editingElement: null,
      editingProperties: null,
      defaultTab: 1,
      openBookIcon: require("@/assets/icons/open-book.svg"),
      phoneIcon: require("@/assets/icons/phone.svg"),
      saveIcon: require("@/assets/icons/black-save.svg"),
    };
  },
  async created() {
    EventBus.$on('changeElementPosition', this.replaceNode);
    EventBus.$on('openSettings', this.openSettings);
    EventBus.$on('openNode', this.getNode);
    EventBus.$on('openNode', this.saveCurrentNode);
    EventBus.$on('deletePreset', this.deletePreset);
    EventBus.$on('deleteScreen', this.deleteScreen);
    EventBus.$on('deleteElement', this.deleteElement);
    this.elementsLoading = true;
    await this.getTypesDictionary();
    await this.getNode(this.$route.params.id);
    this.elementsLoading = false;
  },
  async beforeDestroy() {
    EventBus.$off('openSettings', this.openSettings);
    EventBus.$off('changeElementPosition', this.replaceNode);
    EventBus.$off('openNode', this.getNode);
    EventBus.$off('openNode', this.saveCurrentNode);
    EventBus.$off('deletePreset', this.deletePreset);
    EventBus.$off('deleteScreen', this.deleteScreen);
    EventBus.$off('deleteElement', this.deleteElement);
    this.$store.dispatch('pageBuilder/setActiveElementSettings', null);
  },
  methods: {
    findObjectWithParent(data, targetId, parent = null) {
      if (data.id === targetId) {
        return { object: data, parent: parent };
      }

      if (data.nodeFields) {
        for (const child of data.nodeFields) {
          const result = this.findObjectWithParent(child, targetId, data);
          if (result) {
            return result;
          }
        }
      }

      return null;
    },

    filterElements() {
      const query = this.toolboxSearch.toLowerCase();
      const filtered = {};
      for (const key in this.toolboxElements) {
        const items = this.toolboxElements[key]?.filter(item => item.name.toLowerCase().includes(query));
        if (items.length > 0) {
          filtered[key] = items;
        }
      }

      return filtered;
    },
    canAdd(obj) {
      console.log("obj - ", obj);
    },
    getNodeInfo(e) {
      console.log('e - ', e);
    },
    /**
     * @param {string, number} id - id of node
     * get node by id
     * */
    async getNode(id) {
      this.elementsLoading = true;
      localStorage.setItem('currentNodeId', id);
      pageBuilderService.getNode(id).then(resp => {
        if (resp.response.nodeType !== 'screen' && resp.response.nodeType !== "widget") {
          this.currentNode = resp.response;
          this.currentWorkNode = resp.response.presets[0].screens[0] || resp.response.screens[0] || {};
        } else {
          this.currentWorkNode = resp.response;
          this.openSettings(this.currentWorkNode);
        }
        this.elementsLoading = false;
      }).catch(e => {
        this.elementsLoading = false;
      });
    },
    async deletePreset(preset) {
      this.elementsLoading = true;
      this.currentNode.presets = this.currentNode.presets.filter(pr => pr.id !== preset.id);
      this.$store.dispatch('pageBuilder/changeNode', {partId: this.currentNode.id, nodePart: this.currentNode})
          .then(async resp => {
            this.currentNode = this.clone(this.currentNode, this.currentNode.id, resp.response);
          })
          .catch((e) => {
            console.log('e - ', e)
          })
          .finally(() => {
            this.elementsLoading = false;
          })
    },
    async deleteScreen(screen) {
      this.elementsLoading = true;
      let presetId = null;
      this.currentNode.presets.forEach((pr, index) => {
        pr.screens?.forEach(scr => {
          if (scr.id === screen.id) {
            presetId = index;
          }
        })
      });
      this.currentNode.presets[presetId].screens = this.currentNode.presets[presetId].screens.filter(sc => sc.id !== screen.id);
      this.$store.dispatch('pageBuilder/changeNode', {partId: this.currentNode.presets[presetId].id, nodePart: this.currentNode.presets[presetId]})
          .then(async resp => {
            this.currentNode.presets[presetId] = this.clone(this.currentNode.presets[presetId], this.currentNode.presets[presetId].id, resp.response);
          })
          .catch((e) => {
            console.log('e - ', e)
          })
          .finally(() => {
            this.elementsLoading = false;
          })
    },
    async deleteElement(element) {
      const result = this.findObjectWithParent(this.currentWorkNode, element.id);
      result.parent.nodeFields = result.parent.nodeFields.filter(ob => result.object.id !== ob.id);
      await this.replaceNode(result.parent, result.parent.id);
    },
    /**
     * @param {Array<Object>} elements - array of elements to categorize
     * @returns {Array<Object>} - array of categories with their respective items
     * get elements categorized by their names
     * */
    getElementsWithCategories(elements) {
      const categorizeElements = {
        button: [],
        input: [],
        text: [],
        table: [],
        image: [],
        icon: [],
        block: [],
        other: [],
      };
      const allCategories = Object.keys(categorizeElements);

      elements?.forEach((element) => {
        const foundCategory = allCategories.find((category) => category !== "other" && element.name.toLowerCase().includes(category));
        if (foundCategory) {
          categorizeElements[foundCategory].push(element);
        } else {
          categorizeElements.other.push(element);
        }
      });

      return Object.entries(categorizeElements)
        .filter(([_, items]) => items.length > 0)
        .map(([category, items]) => ({ name: category, items }));
    },
    /**
     * @param {number} id - id of elements type
     * get elements by type id
     * */
    async getElementsByType(id) {
      if (this.currentTypeId !== id && !this.toolboxElements?.id) {
        this.palleteLoading = true;
        this.currentTypeId = id;
        pageBuilderService.getElementsByType(id).then(resp => {
          this.toolboxElements[id] = resp.response;
          this.toolboxSearch = '';
          this.filteredToolboxElements = JSON.parse(JSON.stringify(this.toolboxElements));
          this.palleteLoading = false;
        });
      }
    },
    /** clone handler for items
     * @param {Object} item from toolbox
     * @return {Object}
     * */
    handleClone(item) {
      let cloneMe = JSON.parse(JSON.stringify(item));

      this.$delete(cloneMe, 'uid');
      cloneMe.isCreated = true;
      console.log('cloneMe - ', cloneMe);
      return cloneMe;
    },

    /** open settings of builder element
     * @param {Object} item - element of builder
     * */
    async openSettings(item) {
      if (item?.id) {
        this.$store.dispatch('pageBuilder/setActiveElementSettings', item.id);
        this.propertiesLoading = true;
        this.$store.dispatch('pageBuilder/getNodeProperties', item.id)
            .then(resp => {
              this.editingElement = item;
              this.editingProperties = resp.response.properties;
            })
            .catch(e => {
              this.editingProperties = null;
              this.editingElement = null;
            })
            .finally(() => this.propertiesLoading = false)
      } else {
        this.$store.dispatch('pageBuilder/setActiveElementSettings', null);
        this.editingProperties = null;
        this.editingElement = null;
      }
    },
    async saveNodeProperties(node) {
      await this.replaceNode(node, node.id);
    },
    async saveNewElementProperties(element) {
      this.propertiesLoading = true;
      await this.replaceNode(element, element.id).then(() => {
        setTimeout(() => {
          this.$store.dispatch('pageBuilder/getNodeProperties', element.id)
              .then(resp => {
                console.log('resp - ', resp);
                this.editingElement = element;
                this.editingProperties = resp.response.properties;
              })
              .catch(e => {
                this.editingProperties = null;
                this.editingElement = null;
              })
              .finally(() => this.propertiesLoading = false)
        }, 1000)
      });
    },
    async saveCurrentNode() {
      await this.replaceNode(this.currentWorkNode, this.currentWorkNode.id)
    },
    /**
     * @param {object} nodePart - part of node which we need to save
     * @param {number} partId - id of nodePart which we need to save
     * replace part of node json after save
     **/
    async replaceNode(nodePart, partId) {
      this.elementsLoading = true;
      this.$store.dispatch('pageBuilder/changeNode', {partId, nodePart})
          .then(async resp => {
            this.currentWorkNode = this.clone(this.currentWorkNode, partId, resp.response);
          })
          .catch((e) => {
            console.log('e - ', e)
          })
          .finally(() => {
            this.elementsLoading = false;
          })
    },

    /**
     * @param {object} obj - part of node which we need to replace
     * @param {number} targetId - id of part which we need to replace
     * @param {object} updatedData - new part of obj
     * */
    clone(obj, targetId, updatedData) {
      if (obj.id === targetId) {
        // Если id совпадает с целевым id, обновляем объект
        return { ...obj, ...updatedData };
      } else if (obj.nodeFields && obj.nodeFields.length > 0) {
        // Если у объекта есть вложенные объекты, рекурсивно вызываем эту функцию для каждого из них
        const updatedNodeFields = obj.nodeFields.map((node) =>
          this.clone(node, targetId, updatedData)
        );
        // Возвращаем объект с обновленными вложенными объектами
        return { ...obj, nodeFields: updatedNodeFields };
      } else {
        // Если id не совпадает и нет вложенных объектов, возвращаем объект без изменений
        return obj;
      }
    },
  },
  watch: {
    toolboxSearch() {
      this.filteredToolboxElements = this.filterElements();
    }
  },
  computed: {
    activeElementId() {
      return this.$store.getters["pageBuilder/activeElementSettings"];
    }
  },
}
</script>

<style lang="scss">
.page-builder {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  min-width: 1340px;
  width: 100%;
  border-radius: 30px;
  padding: 12px 24px;
  background-color: #f5f7fb;

  &__phone {
    position: sticky;
    top: 10px;

    &-wrapper {
      width: 100%;
    }
  }

  &__info {
    display: flex;
    min-width: 375px;
    width: 100%;
    padding: 24px;
    color: $main-black !important;
    border-radius: 24px !important;

    a {
      display: inline-flex;
      align-items: center;
      color: $main-black !important;
      text-decoration: none;
      transition: opacity 0.7s ease;
      cursor: pointer;

      &:hover {
        opacity: 0.5;
      }

      &:not(:first-child) {

        &::before {
          content: '';
          display: inline-block;
          height: 100%;
          width: 1px;
          background-color: #96ADB8;
          margin: 0 16px;
        }
      }

      & > span {
        margin-left: 8px;
      }
    }

    img {
      height: 100%;
      width: 100%;
    }
  }

  &__toolbox {
    width: 420px;
    padding: 24px 12px;
    border-radius: 32px !important;

    &-container {
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      max-height: 520px;
      overflow-y: auto;
      overflow-x: hidden;
      gap: 6px;
      padding-right: 8px;

      &::-webkit-scrollbar {
        width: 3px;
        padding-left: 16px;
      }

      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      &::-webkit-scrollbar-thumb {
        background: #888;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }
    }

    &-item {
      position: relative;
      max-width: 100%;
      flex: 0 1 100%;
      padding: 15px 8px 8px;
      cursor: pointer;
      background-color: #E9ECF8;
      text-align: center;
      border-radius: 8px;

      .builder-element__el--in-search::before {
        max-width: 335px;
      }
    }

    .v-text-field {
      border-radius: 16px !important;
    }
  }

  &__fields {
    .page-builder__toolbox-container {
      justify-content: left;
      padding-right: 2px;

      .page-builder__toolbox-item {
        flex: none;
        width: calc(100% / 2 - 6px);
      }

      .builder-element__el--in-search::before {
        max-width: 155px;
      }
    }
  }

  &__settings {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 420px;
    background-color: #ffffff;
    padding: 24px 12px;
    border-radius: 32px;
    box-shadow: 0 0 32px 0 rgba(136, 152, 170, 0.15);

    &.open-editor {
      align-items: flex-start;
      justify-content: flex-start;
    }

    .icon {
      width: 85px;
      height: 85px;
      margin-bottom: 40px;
      margin-top: 250px;

      img {
        height: 100%;
        width: 100%;
      }
    }

    & > div {
      width: 100%;
    }

    & > p {
      font-size: 15px;
      color: #5A717C;
      font-weight: 600;
    }
  }

  &__properties {
    min-height: 250px;
    max-height: 400px;
    overflow-y: scroll;
    overflow-x: hidden;
    div {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  &__column {
    flex-shrink: 0;
  }

  &__dropzone {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    border: 1px dotted #eee;
    word-break: break-word;

    &_invisible {
      border: none;
    }

    &_wrapper,
    &_form {
      overflow: visible;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      min-height: 50px;
      margin: 5px auto;
      padding: 1.5px;
      width: 100%;
    }

    &_form {
      margin-bottom: 5px;
      border: 1px dotted #00b2aa;

      &:hover {
        border: 2px solid #00b2aa;
      }
    }

    &-element {
      cursor: move;
      margin-bottom: 5px;
    }
  }

  &__category {
    & > button {
      padding-right: 0 !important;
      padding-left: 8px !important;
    }

    .v-expansion-panel-content {
      margin-right: 8px;
    }
  }

  .v-slide-group__content {
    background-color: #E9ECF8;
    border-radius: 16px;
    padding: 2px;
  }

  .v-tabs {
    &-slider-wrapper {
      display: none;
    }

    &-items {
      padding-top: 30px;
    }

    .v-tab {
      font-size: 15px;
      text-transform: capitalize;
      border-radius: 16px;
      transition: color 0.3s ease;

      &::before {
        content: none;
      }

      &:hover, &:focus {
        color: $main-black !important;
        background-color: transparent;
      }

      &.v-tab--active {
        background-color: $main-white;
        color: $main-black;
      }

      .v-ripple__container {
        display: none;
      }
    }
  }

  .phone-screen-custom {
    cursor: default !important;
  }

  .phone-screen {
    margin-bottom: 8px;

    &__wrapper {
      margin-top: 60px;
    }

    &__inner {
      border-radius: 45px;
    }

    &__main_full {
      padding: 72px 20px 25px;
    }
  }

  .v-input {
    margin-bottom: 18px;

    &__slot {
      background-color: #F5F7FB !important;

      fieldset {
        border: none;
      }
    }
  }

  .v-expansion-panel {
    margin-top: 0;

    &-header {
      padding: 16px 0;
      font-size: 15px;
      font-weight: 600;
      text-transform: capitalize;
      color: #5a717c;
      user-select: none;
      transition: color 0.3s ease;
      min-height: 40px;

      &:hover, &--active  {
        color: $main-black !important;

        i {
          color: $main-black !important;
        }
      }

      & > span {
        display: flex;
        align-items: center;
      }

      & > span > svg {
        margin-right: 12px;
      }
    }

    &-content__wrap {
      border-radius: 16px;
      padding: 0;

      .page-builder__toolbox-item:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__expansion-panels-elements {
    padding-right: 12px;
    padding-left: 12px;
  }

  .pk_widget-tab-bar {
    position: static;
    width: auto;
  }
}
</style>