<template>
  <v-app-bar
    app
    color="transparent"
    dark
    flat
    hide-on-scroll
    :height="64"
  >
    <v-btn icon @click="handleClick">
      <v-icon>{{ miniSidebar ? 'mdi-menu' : 'mdi-menu-open' }}</v-icon>
    </v-btn>

    <v-toolbar-title>{{ titleOfPage }}</v-toolbar-title>

    <v-spacer></v-spacer>

    <v-select
      class="mt-3 mr-3"
      placeholder="lang"
      :items="languages"
      item-text="name"
      item-value="value"
      v-model="language"
      style="max-width: 80px;"
      variant="outlined"
      @change="setLang"
    />
    <v-menu
      v-model="showMenu"
      close-on-click
      offset-y
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon v-bind="attrs" v-on="on">
          <v-icon>mdi-account</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item
          v-for="(item, index) in items"
          :key="index"
          @click="handleMenuAction(item.to)"
          :id="item.id"
        >
          <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <v-btn icon>
      <v-icon>mdi-bell</v-icon>
    </v-btn>

    <v-dialog
        v-model="chatGptDialog"
        width="500"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
            icon
            v-bind="attrs"
            v-on="on"
        >
          <v-icon>mdi-chat-plus-outline</v-icon>
        </v-btn>
      </template>
      <div class="chat-modal">
        <div class="chat-modal__header">
          <v-img
              :src="require('@/assets/images/logo.png')"
              height="60px"
              width="60px"
              contain
          />
          <v-img
              :src="require('@/assets/images/ChatGPT_logo.svg.png')"
              height="60px"
              width="60px"
              contain
          />
        </div>
        <p class="chat-modal__title">Finbeat & ChatGPT</p>
        <p class="chat-modal__description">
          This plugin allows you to <strong>generate API</strong> according to the documentation of external services, as well as automatically
          <strong>create business processes</strong> according to your requirements!
        </p>
        <v-btn
            color="primary"
            rounded
            @click="chatGptDialog = false; firstRequestDialog = true;"
        >
          Start
        </v-btn>
      </div>
    </v-dialog>

    <v-dialog
        width="500"
        v-model="firstRequestDialog"
    >
      <v-card :loading="isTyping || chatLoading" class="chat-modal__chat">
        <div class='chat-modal__messages' ref="chat_modal_messages" v-if="messages.length">
          <ChatMessage
              v-for='message in messages'
              :key='message.id'
              :class='["chat-modal__message", { right: message.isMine }]'
              :text='message.text'
              :author='message.author'
              @handleMethod="handleMessageMethod"
          />
        </div>
        <v-card class="chat-modal__chat-box">
          <ChatBox
              :disabled="chatLoading || isTyping"
              @submit='sendChatGptRequest'
          />
          <input
              type="file"
              ref="file_input"
              hidden
              accept=".yml,.yaml"
              @change="onFileChange"
          />
        </v-card>
      </v-card>
    </v-dialog>

    <v-dialog
        max-width="600px"
        content-class="chat-modal__methods methods"
        v-model="methodsDialog"
        persistent
    >
      <template v-slot:default="dialog">
        <v-card
            height="100%"
        >
          <v-card-subtitle
              height="auto"
              class="pa-5"
          >
            Мы определили методы для решения поставленной задачи. В случае необходимости использования дополнительных методов из загруженного файла openapi, пожалуйста, укажите их в списке ниже.
          </v-card-subtitle>
          <v-card-text>
            <div
                v-for="method in modalMethods"
                :key="method.endpoint"
                class="d-flex flex-row align-center"
            >
              <v-checkbox
                  v-model="method.active"
              />
              <div
                  :class="{active: method.active}"
                  class="d-flex flex-row align-center methods__method"
              >
                <div
                    :class="`methods__type_${method.type.toLowerCase()}`"
                    class="methods__type"
                >
                  {{ method.type }}
                </div>
                <div>
                  {{ method.endpoint }}
                </div>
              </div>
            </div>
          </v-card-text>
          <v-toolbar
              color="#DFF1FF"
          >
            <v-card-actions class="ma-auto">
              <v-btn
                  rounded
                  color="#87AFFF"
                  class="white--text"
                  @click="dialog.value = false"
              >
                Продолжить
              </v-btn>
            </v-card-actions>
          </v-toolbar>
        </v-card>
      </template>
    </v-dialog>
  </v-app-bar>
</template>

<script>
import menuOptions from "@/mixins/menuOptions";
import systemLang from '../mixins/systemLang'
import ChatBox from "@/components/chat/ChatBox.vue";
import ChatMessage from "@/components/chat/ChatMessage.vue";
import {chatgptService} from "@/services/chatgpt.service";
import {chatgptServiceOld} from "@/services/chatgptold.service";

export default {
  name: "HeaderComponent",
  components: {ChatMessage, ChatBox},
  mixins: [
    menuOptions,
    systemLang
  ],
  props: {
    miniSidebar: Boolean
  },
  data () {
    return {
      showMenu: false,
      items: [{ title: 'exit', to: "/logout", id: 'user__logout' }],
      chatGptDialog: false,
      firstRequestDialog: false,
      methodsDialog: false,
      requestText: '',
      requestError: '',
      chatLoading: false,
      messages: [ ],
      currentFillingTextId: null,
      isTyping: false,
      modalMethods: [],
      isShowMethods: false,
    };
  },

  methods: {
    /**
     * @param {string} path - path to page
     * redirect to page by action menu
     * */
    async handleMenuAction (path) {
      if (path === "/logout") {
        await this.$store.dispatch('authentication/logout');
      }
    },

    /**
     * handle click for wide sidebar
     * */
    handleClick () {
      const value = !this.miniSidebar
      this.$emit('click', value)
    },

    /**
     * @param {string} text - text of message
     * This method will be called when a new message is sent
     * then get request from api to type it in chat
     * */
    async sendChatGptRequest (text) {
      this.chatLoading = true;
      this.addUserMessage(text);
      this.addEmptyBotMessage();
      // await chatgptService.sendRequest(text);
      await chatgptServiceOld.sendRequest(text);
      this.chatLoading = false;
    },

    /**
     * Push empty message from bot to messages array
     * */
    addEmptyBotMessage() {
      this.currentFillingTextId = this.messages.length + 1;
      this.messages.push({
        id: this.currentFillingTextId,
        text: '',
        isMine: false,
      })
    },

    /**
     * @param {string} text - text of message
     * add message from user to chat
     * */
    addUserMessage(text) {
      this.messages.push({
        id: this.messages.length + 1,
        text,
        isMine: true,
      });

      this.scrollToEnd();
    },

    /**
     * @param {object} customHandler - catch handle on right element inside chatbox
     * */
    handleMessageMethod(customHandler) {
      switch(customHandler.customEvent) {
        case 'uploadFile':
          this.chooseFile();
          break;
        case 'action':
          this.selectAction(customHandler.customAction)
          break;
        case 'redirect':
          this.redirectToProcess(customHandler.processId);
          break;
      }
    },

    /**
     * method to choose file in chat
     * */
    chooseFile() {
      const fileInput = this.$refs.file_input;
      fileInput.click();
    },

    /**
     * @param {event} e - handle file change and make request to load openapi file
     * */
    async onFileChange(e) {
      this.chatLoading = true;
      const files = e.target.files
      this.addUserMessage(`Файл: ${files[0].name}`);
      this.addEmptyBotMessage();
      // const response = await chatgptService.loaqdOpenApi(files);
      const response = await chatgptServiceOld.loaqdOpenApi(files);
      console.log('response - ', response);
      this.isShowMethods = true;
      this.chatLoading = false;
    },

    /**
     * get methods to display them in modal window
     * */
    async showMethods() {
      this.chatLoading = true;
      const methodsResponse = await this.$store.dispatch('chatgpt/showMethods');
      this.modalMethods = methodsResponse.response || [];
      this.methodsDialog = true;
      this.chatLoading = false;
    },

    /**
     * @param {string} action - choose action from chat and send it to service
     * */
    async selectAction(action) {
      this.chatLoading = true;
      const actionsMessages = {
        add_process: "Добавить в эти процессы",
        profile: 'Продолжить изменения только в разделе «Профиль»',
      }
      this.addUserMessage(actionsMessages[action]);
      this.addEmptyBotMessage();
      // await chatgptService.selectAction(action);
      await chatgptServiceOld.selectAction(action);
      this.chatLoading = false;
    },

    /**
     * @param {string} processId - redirect to process by process id
     * */
    async redirectToProcess(processId) {
      this.firstRequestDialog = false;
      this.$store.dispatch('processes/setOutsideProcessId', processId);
    },

    scrollToEnd: function() {
      let messageContainer = this.$refs.chat_modal_messages;
      if (messageContainer) {
        // messageContainer.scrollIntoView({behavior: 'smooth'});
        messageContainer.scrollTop = messageContainer.scrollHeight;
      }
    },
    getDataAction(content) {
      let tempArray = content.split('.');
      tempArray = tempArray[tempArray.length - 1].split('}');
      return tempArray[0];
    }
  },
  computed: {
    titleOfPage () {
      let title = this.sidebarItems.find(link => link.name === this.$route.name)?.title;
      if(!title) {
        const subTitle = []

        this.sidebarItems
          .filter(link => link.subItems)
          .forEach(link => {
            subTitle.push(...link.subItems)
          })

        title = subTitle.find(link => link.name === this.$route.name)?.title || this.$route.name;
        console.log('title 2 - ', title);
      }

      return this.$route.params.id ? (this.$t(`menu.${title}`) + ` ${this.$route.params.id}`) : this.$t(`menu.${title}`);
    },
    streamedText() {
      return this.$store.getters["chatgpt/streamedString"];
    }
  },
  watch: {
    /**
     * @param {Array} contentArray - watch for change content inside stream object
     * replace element chunks
     * */
    async streamedText(contentArray) {
      const msgId = this.currentFillingTextId - 1;
      // regex for replace
      const btnUploadRegex = /\{\{btn\[[^\]]+\]\.[^\}]+\}\}/;

      const getTextRegex = /\[(.*?)\]/;

      const btnActionRegex = /\{\{btn\[[^\]]+\]\.action\.[^\}]+\}\}/;
      // const getActionRegex = /\.([a-zA-Z0-9_]+)\}/;

      const btnProcessRegex = /\{\{btn\[[^\]]+\]\.process\.[0-9]+\}\}/;

      // NEED TO REFACTOR MAYBE
      contentArray.forEach(content => {
        if (content.content.match(btnProcessRegex)) {
          let btnText = content.content.match(getTextRegex)[1];
          let processId = this.getDataAction(content.content);
          this.messages[msgId].text = this.messages[msgId].text + `<button data-event="redirect" data-id="${processId}" class="chat-modal__btn">${btnText}</button>`
        } else if (content.content.match(btnActionRegex)) {
          let btnText = content.content.match(getTextRegex)[1];
          let btnAction = this.getDataAction(content.content);
          this.messages[msgId].text = this.messages[msgId].text + `<button data-event="action" data-action="${btnAction}" class="chat-modal__btn">${btnText}</button>`
        } else if (content.content.match(btnUploadRegex)) {
          let btnText = content.content.match(getTextRegex)[1];
          this.messages[msgId].text = this.messages[msgId].text + `<button data-event="uploadFile" class="chat-modal__btn">${btnText}</button>`
        } else {
          this.messages[msgId].text = this.messages[msgId].text + content.content
        }
      })
      this.isTyping = !contentArray[contentArray.length - 1].finish;

      if (!this.isTyping && this.isShowMethods) {
        await this.showMethods();
        this.isShowMethods = false;
      }

      if (this.isTyping) {
        this.scrollToEnd();
      }
    }
  },
  methodsDialog(dialog_value) {
    this.chatLoading = !!dialog_value;
  }
};
</script>

<style lang="scss">
.chat-modal {
  &__methods {
    position: absolute;
    top: 0;
    right: 0;
    margin: 5% 0 0 0 ;
    -webkit-border-top-left-radius: 10px;
    -webkit-border-bottom-left-radius: 10px;
    -moz-border-radius-topleft: 10px;
    -moz-border-radius-bottomleft: 10px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  .v-text-field {
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  border-radius: 30px;
  background-image: linear-gradient(to bottom, #dfdef3, #ffffff, #ffffff, #ffffff);
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: center;
  padding: 20px;
  &_solo {
    display: block;
    background: #ffff;
  }
  &__header {
    display: flex;
    justify-content: space-around;
    gap: 50px;
  }
  &__title {
    font-size: 25px;
    background: #00038f;
    background: -webkit-linear-gradient(to right, #00038f, #74aa9c);
    background: linear-gradient(to right, #00038f, #74aa9c);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
  }
  &__description {
    text-align: center;
  }

  &__chat {
    background: rgba(255, 255, 255, .5);
    border-radius: 20px;
  }

  &__messages {
    flex-grow: 1;
    overflow: auto;
    padding: 1rem;
    height: 500px;

    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }

  }
  &__message {
    margin-top: 1rem;
    &.right {
      background: #D9EBD6;
      color: #000;
      margin-left: auto;
    }
  }

  &__btn {
    border: 1px solid gray;
    padding: 2px;
    margin: 0 5px;
  }
}

.methods {
  &__method {
    width: 100%;
    padding: 10px;
    border-radius: 8px;
    &.active {
      border: 1px solid #9F77ED;
    }
  }
  &__type {
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 10px;
    color: #34438F;
    margin-right: 5px;
    &_get {
      background: #A1B0FF;
    }
    &_put {
      background: #FEBF70;
    }
    &_post {
      background: #60E4A8;
    }
    &_delete {
      background: #d34917;
    }
  }
}
</style>
