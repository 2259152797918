import { render, staticRenderFns } from "./NodeConstructor.vue?vue&type=template&id=f50f0636&scoped=true"
import script from "./NodeConstructor.vue?vue&type=script&lang=js"
export * from "./NodeConstructor.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f50f0636",
  null
  
)

export default component.exports