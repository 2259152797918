
<template>
  <div class="widget" :style="[backgroundColor]">
      <img class="widget__icon" :src="$staticUrl + widgetData.nodeFields[0].properties.imageUrl" alt=""> 
      <div class="widget__name">
        <span v-html="widgetData.nodeFields[0].properties.title"></span>
        <span v-html="widgetData.nodeFields[0].properties.text"></span>
      </div>
      <v-icon class="mr-2" style="color: green" v-if="widgetData.nodeFields[0].properties.state">mdi-check</v-icon>
  </div>
</template>

<script>
export default {
  name: "PK_widgetButtonCheckbox",
  props: {
      widgetData: {
          type: Object
      }
  },
    computed:{
      backgroundColor(){
        const bgColor = this.widgetData.nodeFields[0].properties.selectedBackgroundColor
        return {'background-color': bgColor}
      }
    }
}
</script>

<style lang="scss" scoped>
.widget{
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 15px;
  gap: 10px;

  &__name{
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__icon{
    width: 60px;
  }
}
</style>