<template>
  <div v-if="widgetData.properties.visibility" class="widget">
    <div 
      v-for="field,i in widgetData.nodeFields" 
      :key="i"
      :style="icon(field)"
      class="widget__icon"
    >
    </div>
  </div>
</template>

<script>
export default {
    name: "MX_topBarCenterLogo",
    props: {
        widgetData: {
            type: Object
        }
    },
    methods: {
      icon(field){
        return {
          backgroundImage: field.properties.visibility 
            ? 'url(' + this.$staticUrl + field.properties.imageUrl + ')' 
            : ''
        }
      }
    }
}
</script>

<style lang="scss" scoped>
.widget{
  align-items: center;
  position: relative;
  justify-content: space-between;
  display: flex;
  gap: 8px;

  &__icon{
    overflow:hidden; 
    background-size: contain; 
    background-position: center;
    height: 30px;
    
    &:nth-child(1){
      flex-grow: 1;
      background-position: left !important;
    }
    &:nth-child(3){
      flex-grow: 1;
      background-position: right !important;
    }
    &:nth-child(2){
      flex-grow: 2;
    }
  }
}

</style>