<template>
  <div class="widget">
    <span 
      v-if="widgetData.nodeFields[0]?.properties.header"
      v-html="widgetData.nodeFields[0].properties.header"
      class="widget__label"
    ></span>
    <div class="widget__img">
      <img 
        :src="$staticUrl + widgetData.nodeFields[0]?.properties.imageUrl" 
        alt=""
      >
    </div>
    <div 
      v-html="widgetData.nodeFields[0]?.properties.text"
      class="widget__text"
    ></div>
  </div>
</template>

<script>
export default {
  name: "PK_widgetSlyderCard",
  props: {
      widgetData: {
          type: Object
      }
  }
}
</script>

<style lang="scss" scoped>
.widget{
  position: relative;

  &__label{
    background-color: #ffe84d;
    position: absolute;
    width: 270px;
    text-align: center;
    position:absolute;
    top:16px; 
    left:50%;
    transform: translateX(-50%);
    border-radius: 8px;
    padding: 4px;
  }

  &__img{
    width: 100%;
    
    img{
      width: 100%;
    }
  }

  &__text{
    margin-top: 10px;
    width: 100%;
    text-align: center;
  }
}
</style>