<template>
  <div class="widget">
    <div 
      v-for="field,i in widgetData.nodeFields[0].properties.dataArray" 
      :key="i"
      class="widget__transactions"
    >
      <p 
        v-if="showDate(i)"
        class="widget__date"
      >{{ formatDate(field.date) }}</p>

      <div class="widget__inside">
        <img :src="$staticUrl + widgetData.nodeFields[0].properties.imageUrl" />
        <div class="widget__inside__data">
          <div class="widget__inside__data__info">
            <span>{{ field.paymentType }}</span>
            <span>{{ field.beneficiaryBankName }}</span>
          </div>
          <div class="widget__inside__data__status">
            <span>{{ count(field) }}</span>
            <span v-html="status(field)"></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PK_widgetTransactionsList",
  props: {
      widgetData: {
          type: Object
      }
  },
  data(){
    return {
      daysOfWeek: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
      months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
    }
  },
  methods: {
    count(field){
      const typeOperation = field.typeOperation === 'incoming' 
          ? '+' 
          : '-'
    
      return typeOperation + field.currency + ' ' + field.amount
    },
    status(field){
      return field.status === 'successfully'
          ? '<span style="color: green;">Complete</span>'
          : '<span style="color: red;">Error</span>'
    },
    formatDate(inputDate) {
      const [time, date] = inputDate.split(' ')
      const [hours, minutes] = time.split(':')
      const [day, month, year] = date.split('.')

      const dateToday = new Date(Date.now())
      const today = {
        'year': dateToday.getFullYear(),
        'month': dateToday.getMonth(),
        'day': dateToday.getDate(),
      }

      if(today.year === +year && ++today.month === +month && today.day === +day) return 'Today'


      const formattedDate = new Date(year, month - 1, day, hours, minutes)
      
      const dayOfWeek = this.daysOfWeek[formattedDate.getDay()]
      const monthName = this.months[formattedDate.getMonth()]

      return `${dayOfWeek} ${day} ${monthName} ${year}`
    },
    showDate(index){
      if(index == 0) return true

      const [timeC, dateC] = this.widgetData.nodeFields[0].properties.dataArray[index].date.split(' ')
      const [timeP, dateP] = this.widgetData.nodeFields[0].properties.dataArray[index - 1].date.split(' ')

      return dateC !== dateP
    }
  }
}
</script>

<style lang="scss" scoped>
.widget{
  display: flex;
  flex-direction: column;
  gap: 15px;

  &__date{
    margin-top: -16px;
    background: white;
    width: 100%;
    z-index: 99;
    position: relative;
    padding: 15px 0 10px;
  }

  &__inside{
    display: flex;
    gap: 10px;
    width: 100%;
    padding-bottom: 15px;
    border-bottom: 0.5px solid;

    img{
      width: 45px;
      height: fit-content;
    }

    &__data{
      display: flex;
      justify-content: space-between;
      width: 100%;

      &__status{
        display: flex;
        flex-direction: column;
        align-items: end;
      }

      &__info{
        display: flex;
        flex-direction: column;
      }
    }
  }
}

</style>