var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'builder-element__el',
    { 'builder-element__el--in-search': _vm.elInSearch },
  ],attrs:{"data-name":_vm.elInSearch && (_vm.element.name || _vm.element.nodeType)},on:{"click":function($event){$event.stopPropagation();return _vm.openSettings(_vm.element)}}},[(_vm.element.nodeType == 'widget')?_c('WidgetDisplay',{attrs:{"item":_vm.element,"default-img":{
      src: _vm.noPreviewImg,
      height: _vm.elInSearch && '50px',
    }}}):(_vm.getComponent(_vm.element))?_c(_vm.getComponent(_vm.element),_vm._b({tag:"component"},'component',_vm.propSet,false),[_vm._v(" Text "),(_vm.element.name === 'block.box')?_c('img',{attrs:{"slot":"icon","src":_vm.alertIcon,"alt":"icon"},slot:"icon"}):_vm._e()]):(_vm.checkTextComponent(_vm.element.name))?_c('div',{style:(_vm.element.name === 'webTooltip' && _vm.tooltipStyle())},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.element.properties?.text || 'Text')}})]):(_vm.checkImageComponent(_vm.element.name))?_c('PreviewImage',{staticClass:"builder-element__image",attrs:{"max-width":"100","max-height":"100","src":_vm.element.properties?.imageUrl || ''}}):_c('img',{staticClass:"builder-element__default-img",attrs:{"src":_vm.noPreviewImg,"alt":"builder-element"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }