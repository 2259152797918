<template>
  <div class="widget">
    <div 
      v-if="field.nodeType === 'image' && field.properties.visibility"
      v-for="field,i in widgetData.nodeFields" 
      :key="i"
      :style="icon(field)"
      class="widget__icon"
    >
    </div>
    <div 
      v-else
      class="widget__field"
    >
      <span
        v-if="widgetData.nodeFields[1].properties.visibility"
        v-html="widgetData.nodeFields[1].properties.placeholder"
      ></span>
    </div>
  </div>
</template>

<script>
export default {
  name: "PK_widgetTopbarWithSearch",
  props: {
      widgetData: {
          type: Object
      }
  },
  methods: {
      icon(field){

        let url = field.properties.imageUrl
            ? field.properties.imageUrl
            : field.properties.withNotifiImageUrl

        return {
          backgroundImage: field.properties.visibility 
            ? 'url(' + this.$staticUrl + url + ')' 
            : ''
        }
      }
    }
}
</script>

<style lang="scss" scoped>
.widget{

  display: flex;
  gap: 10px;
  align-items: center;

  &__icon{
    overflow:hidden; 
    background-size: contain; 
    background-position: center;
    height: 20px;
    flex-grow: 1;
  }

  &__field{
    flex-grow: 2;
  }
}
</style>