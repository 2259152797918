<template>
  <div 
    v-if="widgetData.properties.visibility"
    class="widget"
  >
    <img class="widget__logo" :src="$staticUrl + widgetData.nodeFields[0].properties.imageUrl " alt="img2">
    <div class="widget__field">
      <span v-html="widgetData.nodeFields[1].properties.title"></span>
      <span v-html="widgetData.nodeFields[1].properties.text"></span>
    </div>
    <svg class="widget__arrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z"/></svg>
  </div>
</template>

<script>
export default {
    name: 'PK_widgetButtonArrow',
    props: {
        widgetData: {
            type: Object
        }
    }
}
</script>

<style scoped lang="scss">
.widget{
  display: flex;
  gap: 15px;
  justify-content: space-between;
  align-items: center;

  &__logo{
    width: 50px;
  }

  &__field{
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__arrow{
      height: 30px;
  }
}

</style>