
<template>
  <div class="widget">
    <img 
      v-if="widgetData.nodeFields[0].properties.visibility"
      class="widget__logo" 
      :src="$staticUrl + widgetData.nodeFields[0].properties.imageUrl " 
      alt="img2"
    >
    <div 
      v-if="widgetData.nodeFields[1].properties.visibility"
      class="widget__fields"
    >
      <span v-html="widgetData.nodeFields[1].properties.text"></span>
      <span v-html="widgetData.nodeFields[2].properties.text"></span>
    </div>
    <div 
      v-if="widgetData.nodeFields[3].properties.visibility"
      class="widget__fields"
    >
      <span v-html="widgetData.nodeFields[3].properties.text"></span>
      <span v-html="widgetData.nodeFields[4].properties.text"></span>
    </div>
    <div 
      v-if="widgetData.nodeFields[5].properties.visibility"
      class="widget__fields"
    >
      <span v-html="widgetData.nodeFields[5].properties.text"></span>
      <span v-html="widgetData.nodeFields[6].properties.text"></span>
    </div>
    <span 
      v-if="widgetData.nodeFields[7].properties.visibility"
      v-html="widgetData.nodeFields[7].properties.text"
    ></span>
    <div class="widget__btns mt-4">
      <ButtonWidget :btnData="this.widgetData.nodeFields[8]" />
      <ButtonWidget :btnData="this.widgetData.nodeFields[9]" />
    </div>
  </div>
</template>

<script>
import ButtonWidget from './components/ButtonWidget.vue'
export default {
  name: "PK_widgetModalWindowChat",
  props: {
      widgetData: {
          type: Object
      }
  },
  components: { 
    ButtonWidget 
  }
}
</script>

<style lang="scss" scoped>

.widget{
  display: flex;
  flex-direction: column;
  gap: 15px;

    &__logo{
      width: 30%;
      margin: 0 auto;
    }

    &__fields{
      display: flex;
      flex-direction: column;
    }

    &__info{
      text-align: center;
    }

    &__btns{
      display: flex;
      gap: 10px;
    }
  }
</style>