<template>
  <div class="phone-screen__wrapper">
    <div class="phone-screen phone-screen-custom" :style="{cursor: cursor ? cursor : 'move'}">
      <div class="phone-screen__inner">
        <div class="phone-screen__border phone-screen-custom__border"><img src="@/assets/images/phone-2.png" alt="phone-border"></div>
        <div class="phone-screen__main" :class="customClass">
          <slot></slot>
        </div>
      </div>
    </div>
    <div v-if="nodeName === screenName">{{ $t('node.node') }}: {{ nodeName }}</div>
    <div v-else>
      <div class="phone-screen__name"> {{ $t('node.node') }}: {{ nodeName }} </div>
      <div class="phone-screen__name" v-if="screenName"> {{ $t('node.screen') }}: {{ screenName }}</div>
    </div>
  </div>
</template>

<script>
import {node} from "@/store/modules/node.module";

export default {
  name: "PhoneWrapper",
  computed: {
    node() {
      return node
    }
  },
  props: {
    nodeName: {
      type: String,
      required: true,
    },
    screenName: {
      type: String,
      default: '',
    },
    cursor: {
      type: String,
    },
    customClass: {
      type: String,
    }
  }
}
</script>

<style lang="scss" scoped>
  .phone-screen__inner {
    background: #fff;
    border-radius: 35px;
  }
  .phone-screen__main_full {
    padding: 47px 25px 10px;
  }
</style>