 <template>
    <ButtonWidget :btnData="this.widgetData.nodeFields[0]" />
  </template>
  
  <script>
  import ButtonWidget from './components/ButtonWidget.vue'
  export default {
    name: "MX_button",
    props: {
        widgetData: {
            type: Object
        }
    },
    components: { 
      ButtonWidget 
    },
  }
  </script>
  
  <style lang="scss" scoped>
  </style>