<template>
  <v-dialog
      :disabled="loading"
      v-model="dialogShow"
      width="500"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
          color="primary"
          dark
          v-bind="attrs"
          v-on="on"
      >
        Create Property
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        Create Property
      </v-card-title>
      <v-card-text>
        <v-text-field
            :disabled="loading"
            label="Name"
            v-model="propertyForm.name"
            :error-messages="validation.name"
        />
        <v-select
            :disabled="loading"
            label="Type"
            :items="allPropertyTypes"
            item-text="name"
            item-value="id"
            v-model="propertyForm.typeId"
        />
      </v-card-text>
      <v-card-actions>
        <v-btn
            color="primary"
            rounded
            @click="createProperty"
        >
          {{ $t('create') }}
        </v-btn>
        <v-btn
            color="secondary"
            rounded
            @click.stop="dialogShow = false;"
        >
          {{ $t('cancel') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "CreatePropertyModal",
  data() {
    return {
      dialogShow: false,
      loading: false,
      propertyForm: {
        name: '',
        typeId: null,
        isGlobal: null,
      },
      validation: {
        name: '',
      }
    }
  },
  computed: {
    allPropertyTypes() {
      return this.$store.getters["pageBuilder/allPropertyTypes"];
    }
  },
  methods: {
    async createProperty() {
      const regexName = /^[a-zA-Z0-9_-]+$/;
      if (regexName.test(this.propertyForm.name)) {
        this.loading = true;
        await this.$store.dispatch('pageBuilder/createProperty', this.propertyForm);
        this.loading = false;
        this.dialogShow = false;
        this.validation.name = '';
        this.propertyForm.name = '';
        this.propertyForm.typeId = null;
      } else {
        this.validation.name = 'incorrect name';
      }
    }
  },
}
</script>

<style lang="scss" scoped>

</style>