<template> 
      <div v-if="properties.visibility">
        <table class="widget">
          <thead>
              <tr class="widget__head">
                  <th class="widget__month" colspan="2">
                      {{ years[0] }} - {{ years.at(-1) }}
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M201.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 338.7 54.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"/></svg>
                  </th>
                  <th class="widget__month-selected" colspan="1">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256 246.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z"/></svg>
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z"/></svg>
                  </th>
              </tr>
          </thead>
          <tbody class="widget__years">
            <tr v-for="row,rowI in rows" :key="rowI">
                <td 
                    v-for="col,colI in columns" 
                    :key="colI"
                    @click="chooseYear(showYear(rowI,colI))"
                    :ref="showYear(rowI,colI)"
                >
                    {{ showYear(rowI,colI) }}
                </td>
            </tr>
          </tbody>
        </table>
      </div>
</template>

<script>
  export default {
    name: "MX_widgetCalendar",
    data(){
        return {
            selectedYear: 2023,
            rows: 4,
            columns: 3,
            years: [
                2022, 2023, 2024,
                2025, 2026, 2027,
                2028, 2029, 2030,
                2031, 2032, 2033,
            ]
        }
    },
    computed: {
        properties(){
            return this.widgetData.nodeFields[0].properties
        },
        activeColor(){
            return {background: this.properties.color}
        }
    },
    props: {
      widgetData: {
          type: Object
      }
    },
    methods: {
        chooseYear(year){
            this.$refs[this.selectedYear][0].classList.value = ''
            this.$refs[this.selectedYear][0].style.background = ''

            this.$refs[year][0].classList.value = 'widget__years-active'
            this.$refs[year][0].style.background = this.properties.color

            this.selectedYear = year
        },
        showYear(x,y){
            let indexYear = 0

            if(x > 0) indexYear = (this.columns * x) + y
            else indexYear = x + y

            return this.years[indexYear]
        }
    },
    mounted(){
        this.chooseYear(this.selectedYear)
    }
  }
</script>

<style lang="scss" scoped>
    .widget {
        background: #fff;
        border-radius: 13px;

        &__head th{
            text-align: center;
            font-weight: 600;
            font-size: 20px;
            padding-left: 8px;
            padding-bottom: 15px;
            svg {
                height: 18px;
                fill:gray;
            }
        }
        &__month{
            text-align: left !important;
            svg{
                height: 13px !important;
                margin: 0 0 2px 5px;
                fill:gray;
            }
            &-selected{
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 16px;
            }
        }
        &__years {
            td {
                text-align: center;
                font-weight: 400;
                font-size: 20px;
                padding: 8px 28px;
            }
            &-active{
                border-radius: 30px;
                color: #fff;
            }
        }
    }
</style>