<template>
  <div class="action">
    <span>{{ name }}</span>
    <div v-if="btn" class="action__btns">
        <button @click="$emit('editAction', $event)">
            <v-icon :color="iconColor" size="large" class="mx-1">{{ 'mdi-pencil' }}</v-icon>
        </button>
        <button @click="$emit('deleteAction', $event)">
            <v-icon :color="iconColor" size="large">{{ 'mdi-delete' }}</v-icon>
        </button>
    </div>
  </div>

</template>

<script>
export default {
    name: 'actionBlock',
    props: {
        name: String,
        btn: Boolean
    },
    data(){
        return{
            iconColor: "#abafb6"
        }
    }
}
</script>
<style lang="scss" scoped>
    .action{
        background-color: #f5f7fb;
        color:#5a717c;
        border-radius: 8px; 
        display: flex;
        margin: 6px 0;
        padding: 4px 12px;
        justify-content: space-between;

      &__btns{
        display: flex;
      }
    }
</style>