<template>
  <v-dialog
      v-model="dialogShow"
      width="500"
      :loading="disabled"
  >
    <v-card>
      <v-card-title>
        {{ isChange ? $t('pageBuilder.changeModalName') : $t('pageBuilder.createModalName') }}
      </v-card-title>
      <v-card-text>
        <v-text-field
            :disabled="isChange"
            :label="$t('pageBuilder.nodeName')"
            v-model="nodeForm.name"
        />
        <v-text-field
            :label="$t('pageBuilder.displayName')"
            v-model="nodeForm.title"
        />
        <v-text-field
            :label="$t('description')"
            v-model="nodeForm.description"
        />
        <v-select
            disabled
            :label="$t('pageBuilder.nodeType')"
            :items="nodeTypes"
            item-text="typeName"
            item-value="id"
            :value="typeId"
        />
      </v-card-text>
      <v-card-actions>
        <v-btn
            color="primary"
            rounded
            @click="createNode"
        >
          {{ isChange ? $t('change') : $t('create') }}
        </v-btn>
        <v-btn
            color="secondary"
            rounded
            @click.stop="dialogShow = false;"
        >
          {{ $t('cancel') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "NodeInfoModal",
  props: ['disabled', 'value', 'formInfo', 'isChange', 'typeId'],
  data() {
    return {
      nodeForm: {
        nodeType: this.typeId,
        name: '',
        title: '',
        description: '',
      },
    }
  },
  computed: {
    nodeTypes() {
      return this.$store.getters["pageBuilder/types"];
    },
    dialogShow: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  methods: {
    createNode() {
      this.nodeForm.nodeType = this.typeId;
      this.$emit('createNode', this.nodeForm);
      this.dialogShow = false;
    }
  },
  watch: {
    formInfo: {
      handler(newVal, oldVal) { // watch it
        if (newVal) {
          this.nodeForm = newVal;
        }
      },
      deep: true
    },
  },
}
</script>

<style lang="scss" scoped>

</style>