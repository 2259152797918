<template>
    <bpm-button 
        v-if="btnData?.properties?.visibility"
        :fullwidth="true"         
        :style="btnStyle(btnData)"
      ><span v-html="btnData?.properties?.text"></span>
    </bpm-button>
</template>

<script>
export default {
  name: "ButtonWidget",
  props: {
    btnData: {
      type: Object,
      require: true
    }
  },
  methods: {
    btnStyle(btn){
      return {
        'border-color': btn.properties.strokeColor, 
        'background': btn.properties.backgroundColor 
            ? btn.properties.backgroundColor
            : btn.properties.color
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>