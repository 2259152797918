 <template>
    <div class="widget">
      <div 
        v-if="widgetData.nodeFields[0].properties.visibility"
        v-html="widgetData.nodeFields[0].properties.text"
      ></div>
      
      <div class="widget__img mt-4">
        <img 
          v-if="widgetData.nodeFields[1].properties.visibility"
          :src="$staticUrl + widgetData.nodeFields[1].properties.imageUrl" 
          alt=""
        >
        <div 
          v-if="widgetData.nodeFields[2].properties.visibility"
          v-html="widgetData.nodeFields[2].properties.text"
        ></div>
      </div>

      <div class="widget__btns mt-4">
        <ButtonWidget :btnData="this.widgetData.nodeFields[3]" />
        <ButtonWidget :btnData="this.widgetData.nodeFields[4]" />
      </div>
    </div>
  </template>
  
  <script>
  import ButtonWidget from './components/ButtonWidget.vue'
  export default {
    name: "PK_widgetBiometryModalWindow",
    props: {
        widgetData: {
            type: Object
        }
    },
      components: { 
        ButtonWidget 
      }
  }
  </script>
  
  <style lang="scss" scoped>
  .widget{
    &__img{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      img{
        width: 25%;
      }
    }

    &__btns{
      display: flex;
      gap: 10px;
    }
  }
  </style>