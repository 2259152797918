<template>
  <div class="node-constructor">
    <v-tabs
        background-color="indigo"
        v-model="tab"
        dark
    >
      <v-tab
          v-for="type in filteredNodeTypes"
          :key="type.id"
          @change="accordionList(type.id)"
      >
        {{ type.prefixName }} list
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab" class="pa-2">
      <div class="node-constructor__header mt-5">
        <div class="d-flex justify-space-between">
          <v-btn
              color="primary"
              class="mb-5"
              :disabled="createLoading"
              @click="createDialog = true"
          >
            {{ $t('create') }}
          </v-btn>
          <v-text-field
              class="ml-3"
              v-model="searchNode"
              append-icon="mdi-magnify"
              outlined
              color="#fff"
              dense
              label="Search"
              hide-details
          ></v-text-field>
        </div>
        <NodeInfoModal
            :disabled="createLoading"
            v-model="createDialog"
            :typeId="currentListId"
            @createNode="createNode"
        />
      </div>
      <v-tab-item
          v-for="type in filteredNodeTypes"
          :key="type.id"
      >
        <SpinnerLoader
            color="deep-purple accent-2"
            v-if="listLoading"
        />
        <v-card
            v-else
            v-for="list in currentSearchList"
            :key="list.id"
            class="mb-2"
        >
          <v-card-title class="d-flex justify-space-between">
            <div>
              {{ list.name }}
            </div>
            <v-card-actions>
              <v-btn icon @click="openNode(list.id)">
                <v-icon>mdi-logout-variant</v-icon>
              </v-btn>
              <v-btn icon @click="deleteNode(list)">
                <v-icon>mdi-trash-can-outline</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card-title>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
    <ConfirmModal ref="confirm" />
  </div>
</template>

<script>
import pageBuilder from "@/mixins/pageBuilder";
import SpinnerLoader from "@/components/UI/SpinnerLoader";
import NodeInfoModal from "@/components/page-builder/NodeInfoModal.vue";
import ConfirmModal from "@/components/utils/ConfirmModal.vue";
import {EventBus} from "@/utils/event-bus";
export default {
  name: "NodeConstructor",
  components: {
    ConfirmModal,
    NodeInfoModal,
    SpinnerLoader,
  },
  mixins: [pageBuilder],
  data() {
    return {
      createDialog: false,
      tab: null,
      nodeForm: {
        nodeType: 2,
        name: '',
        title: '',
        description: '',
      },
      searchNode: '',
      currentListId: null,
      createLoading: false,
      listLoading: false,
    }
  },
  computed: {
    currentList() {
      return this.$store.getters["pageBuilder/currentList"];
    },
    currentSearchList() {
      return this.currentList.length ? this.currentList.filter(item => item.name.toLowerCase().includes(this.searchNode.toLowerCase())) : this.currentList;
    },
    nodeTypes() {
      return this.$store.getters["pageBuilder/types"];
    },
    filteredNodeTypes() {
      const showIds = [1,4,3];
      return this.nodeTypes?.length ? this.nodeTypes.filter(el => showIds.includes(el.id)).sort() : [];
    }
  },
  async mounted() {
    await this.getTypesDictionary();
    await this.accordionList(this.filteredNodeTypes[0].id);
  },
  methods: {

    /**
     * Create node by submit create form))0
     * */
    async createNode(nodeForm) {
      this.createLoading = true;
      // get response
      this.$store.dispatch('pageBuilder/createNode', nodeForm).then(resp => {
        this.$router.push(`/page-builder/${resp.response.id}`);
      });
     // close dialog
      this.createLoading = false;
      this.createDialog = false;

    },

    async accordionList(id) {
      if (id !== this.currentListId) {
        this.searchNode = '';
        this.listLoading = true;
        await this.getListByType(id);
        this.currentListId = id;
        this.listLoading = false;
      }
    },

    /**
     * @param {number} id - id of node
     * Open any type of node by id and redirect to page with nodeBuilder
     * */
    openNode(id) {
      this.$router.push(`/page-builder/${id}`);
    },

    async deleteNode(node) {
      if (await this.$refs.confirm.open(
          `delete ${node.name}`,
          `Are you sure you want to delete ${node.name}`
      )) {
        this.listLoading = true;
        const response = await this.$store.dispatch('pageBuilder/deleteNode', node.id);
        if (response.status === 1) {
          await this.getListByType(this.currentListId);
        }
        this.listLoading = false;
      }
    },

    /**
     * @param {number} nodeType - number of type
     * get list of elements by nodeType
     * */
    async getListByType(nodeType) {
      await this.$store.dispatch('pageBuilder/getElementsByType', nodeType);
    }
  }

}
</script>

<style lang="scss" scoped>

</style>