<template>
  <div class="spacing-editor">
    <label
      class="spacing-editor__title"
      @click="toggleControls"
      :class="{ active: controlsVisible }"
    >
      Spacing
      <i
        aria-hidden="true"
        class="v-icon notranslate mdi mdi-chevron-down theme--light" 
      />
    </label>

    <div v-if="controlsVisible" class="spacing-editor__position-controls">
      <div class="spacing-editor__square">
        <span>Margin</span>
        <input
          v-for="(value, key) in directions"
          :key="key"
          type="number"
          v-model="margin[value]"
          :class="`spacing-editor__margin-${value}`" 
        />
        <div class="spacing-editor__square-out">
          <span>Padding</span>
          <input
            v-for="(value, key) in directions"
            :key="key"
            type="number"
            v-model="padding[value]"
            :class="`spacing-editor__padding-${value}`" 
          />
          <div class="spacing-editor__square-inner" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SpacingEditor",
  data() {
    return {
      controlsVisible: false,
      directions: ["top", "right", "bottom", "left"],
      margin: { top: "", right: "", bottom: "", left: "" },
      padding: { top: "", right: "", bottom: "", left: "" },
    };
  },
  watch: {
    margin: {
      handler() {},
      deep: true,
    },
    padding: {
      handler() {},
      deep: true,
    },
  },
  methods: {
    toggleControls() {
      this.controlsVisible = !this.controlsVisible;
    },
  },
};
</script>

<style lang="scss" scoped>
.spacing-editor {
  &__title {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 10px;
    text-transform: capitalize;
    font-size: 16px;
    font-weight: 600;
    background-color: #f5f7fb;
    border-radius: 10px;
    border: none;
    cursor: pointer;

    i {
      transition: transform 0.3s ease;
    }

    &.active {
      i {
        transform: rotate(-180deg);
      }
    }
  }

  &__position-controls {
    position: relative;
    margin-top: 16px;
    padding: 20px;
    border: 1px solid #e9e9e9;
    border-radius: 4px;
  }

  &__square {
    display: flex;
    position: relative;
    align-items: center;
    height: 160px;
    width: 100%;
    padding: 25px 55px;
    border: 1px dashed $main-black;
    user-select: none;

    &-out {
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 100%;
      background-color: #e9ecf8;
    }

    &-inner {
      position: relative;
      height: 50%;
      width: 50%;
      border: 1px dashed $main-white;
    }

    input {
      position: absolute;
      width: 45px;
      height: 21px;
      border: 1px solid rgb(226, 223, 223);
      border-radius: 2px;
      background-color: $main-white;
      font-size: 14px;
      text-align: center;
      z-index: 1;
      outline: none;

      &:focus {
        border-color: #a09f9f;
      }
    }

    span {
      position: absolute;
      top: 0;
      font-size: 12px;
      left: 2px;
    }
  }

  &__margin-top {
    top: 2px;
    left: 50%;
    transform: translateX(-50%);
  }

  &__margin-right {
    top: 50%;
    right: 5px;
    transform: translateY(-50%);
  }

  &__margin-bottom {
    bottom: 2px;
    left: 50%;
    transform: translateX(-50%);
  }

  &__margin-left {
    top: 50%;
    left: 5px;
    transform: translateY(-50%);
  }

  &__padding-top {
    top: 2px;
    left: 50%;
    transform: translateX(-50%);
  }

  &__padding-right {
    top: 50%;
    right: 5px;
    transform: translateY(-50%);
  }

  &__padding-bottom {
    bottom: 2px;
    left: 50%;
    transform: translateX(-50%);
  }

  &__padding-left {
    top: 50%;
    left: 5px;
    transform: translateY(-50%);
  }
}
</style>
