
<template>
  <div class="widget">
    <div v-html="widgetData.nodeFields[0].properties.text"></div>
    <div class="widget__imgs">
      <img class="widget__rightImg" :src="$staticUrl + widgetData.nodeFields[1].properties.imageUrl" alt="img2">
      <img class="widget__rightImg" :src="$staticUrl + widgetData.nodeFields[2].properties.imageUrl" alt="img2">
      <img class="widget__rightImg" :src="$staticUrl + widgetData.nodeFields[3].properties.imageUrl" alt="img2">
    </div>
    <div class="widget__info" v-html="widgetData.nodeFields[4].properties.text"></div>

    <div class="widget__btns mt-4">
      <ButtonWidget :btnData="this.widgetData.nodeFields[6]" />
      <ButtonWidget :btnData="this.widgetData.nodeFields[7]" />
    </div> 
  </div>
</template>

<script>
import ButtonWidget from './components/ButtonWidget.vue'
export default {
  name: "PK_widgetModalWindowChat",
  props: {
      widgetData: {
          type: Object
      }
  },
  components: { 
      ButtonWidget 
  }
}
</script>

<style lang="scss" scoped>

.widget{
  display: flex;
  flex-direction: column;
  gap: 15px;

    &__imgs{
      display: flex;
      justify-content: space-around;
      align-items: center;

      img{
        width: 20%;
      }
    }

    &__info{
      text-align: center;
    }

    &__btns{
      display: flex;
      gap: 10px;
      flex-direction: column;
    }
  }
</style>