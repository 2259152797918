<template>
  <div>
    <div v-for="nodeField,i in widgetData.nodeFields" :key="i">
      <div 
        v-if="nodeField.properties.visibility"
        class="widget__field"
      >
        <span v-html="nodeField.properties?.placeholder"></span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MX_widgetEsignature",
  props: {
      widgetData: {
          type: Object
      }
  }
}
</script>

<style lang="scss" scoped>
.widget{
  &__field{
    text-align: center;
  }
}
</style>