
<template>
  <div class="widget">
    <span 
      v-if="widgetData.nodeFields[0].properties.visibility"
      v-html="widgetData.nodeFields[0].properties.text"
    ></span>
    <div class="widget__filters">
      <span 
        v-if="widgetData.nodeFields[1].properties.visibility"
        v-html="widgetData.nodeFields[1].properties.text"
      ></span>
      <span 
        v-if="widgetData.nodeFields[2].properties.visibility"
        v-html="widgetData.nodeFields[2].properties.text"
      ></span>
    </div>
    <div class="widget__inputs mt-3">
      <span 
        v-if="widgetData.nodeFields[3].properties.visibility"
        v-html="widgetData.nodeFields[3].properties.text"
      ></span>
      <span 
        v-if="widgetData.nodeFields[4].properties.visibility"
        v-html="widgetData.nodeFields[4].properties.text"
      ></span>
    </div>

    <table class="widget__calendar" v-for="month in months" :key="month.month">
      <thead>
          <tr class="widget__calendar__head">
              <th class="widget__calendar__month" colspan="2">{{ month.month }}</th>
          </tr>
      </thead>
      <tbody class="widget__calendar__months">
        <tr class="widget__calendar__days-of-week">
          <td v-for="day,dayI in daysOfWeek" :key="dayI">
            {{ day }}
          </td>
        </tr>
        <tr v-for="row,rowI in rows" :key="rowI">
            <td 
                v-for="col,colI in columns" 
                :key="colI"
              >
                {{ showDate(rowI,colI, month.month) }}
            </td>
        </tr>
      </tbody>
    </table>

    <div class="widget__btns mt-4">
      <ButtonWidget :btnData="this.widgetData.nodeFields[6]" />
      <ButtonWidget :btnData="this.widgetData.nodeFields[7]" />
    </div> 
  </div>
</template>

<script>
import ButtonWidget from './components/ButtonWidget.vue'
export default {
  name: "PK_widgetModalWindowChat",props: {
    widgetData: {
      type: Object
    }
  },
  components: { 
    ButtonWidget 
  },
  data(){
    return {
      selectedDate: {
        month: "Fubruary",
        day: 16
      },
      rows: 5,
      columns: 7,
      daysOfWeek: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
      months: [
        {
          month: "Fubruary",
          days: [
            30, 31, 1, 2, 3, 4, 5,
            6, 7, 8, 9, 10, 11, 12,
            13, 14, 15, 16, 17, 18, 19,
            20, 21, 22, 23, 24, 25, 26,
            27, 28, 29, 30, 1, 2, 3
          ]
        },   
        {
          month: "March",
          days: [
            30, 31, 1, 2, 3, 4, 5,
            6, 7, 8, 9, 10, 11, 12,
            13, 14, 15, 16, 17, 18, 19,
            20, 21, 22, 23, 24, 25, 26,
            27, 28, 29, 30, 1, 2, 3
          ]
        } 
      ]
    }
  },
  methods: {
    showDate(x,y, monthName){
        let indexDate = 0

        if(x > 0) indexDate = (this.columns * x) + y
        else indexDate = x + y

        const currentMonth = this.months.find(item => item.month === monthName)
        if(currentMonth) return currentMonth.days[indexDate]
        else return null
    }
  }
}
</script>

<style lang="scss" scoped>

.widget{
  &__filters{
    display: flex;
    justify-content: space-between;
    gap: 10px;
    margin-top: 20px;

    span{
      padding: 8px 15px;
      border-radius: 20px;
      background-color: aliceblue;
      width: 100%;
      text-align: center;
    }
  }

  &__inputs{
    display: flex;
    justify-content: space-between;
    gap: 10px;

    span{
      padding: 12px 15px;
      border-radius: 10px;
      background-color: rgb(248, 252, 255);
      width: 100%;
      color: rgb(163, 163, 163);
    }
  }
    &__btns{
      display: flex;
      flex-direction: column;
      gap: 10px;
    }

    &__calendar{
      margin-top: 15px;
      width: 100%;

      &__month{
        text-align: left;
      }
      
      &__months{
        width: 100%;
        display: flex;
        flex-direction: column;
        
        tr{
          display: flex;
          
          td{
            width: 100%;
            text-align: center;
            padding: 4px;
          }
        }
      }

      &__days-of-week{
        color: rgb(184, 184, 184);
      }
    }
  }
</style>