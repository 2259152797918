<template>
  <div class="widget">
    <div v-if="widgetData.nodeFields[0].properties.visibility">
      <span class="widget__title" v-html="widgetData.nodeFields[0].properties.label"></span>
    </div>
    <div v-if="widgetData.nodeFields[1].properties.visibility">
      <span class="widget__sub-title" v-html="widgetData.nodeFields[1].properties.text"></span>
    </div>
    <div class="widget__numpad" v-if="widgetData.nodeFields[2].properties.visibility">
      <div class="widget__numpad-row">
        <div class="widget__numpad-num">1</div>
        <div class="widget__numpad-num">2</div>
        <div class="widget__numpad-num">3</div>
      </div>
      <div class="widget__numpad-row">
        <div class="widget__numpad-num">4</div>
        <div class="widget__numpad-num">5</div>
        <div class="widget__numpad-num">6</div>
      </div>
      <div class="widget__numpad-row">
        <div class="widget__numpad-num">7</div>
        <div class="widget__numpad-num">8</div>
        <div class="widget__numpad-num">9</div>
      </div>
      <div class="widget__numpad-row">
        <div class="widget__numpad-num">del</div>
        <div class="widget__numpad-num">0</div>
        <div class="widget__numpad-num widget__numpad-num-clear"></div>
      </div>
    </div>
  </div>
  </template>
  
  <script>
  export default {
      name: "MX_widgetShortCodeLogin",
      props: {
          widgetData: {
              type: Object
          }
      },
      data(){
        return {
          numpad: {
            row: 4,
            column: 3
          }
        }
      }
  }
  </script>
  
<style lang="scss" scoped>
.widget{
  &__title{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
  }

  &__sub-title{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
  }

  &__numpad{
    display: flex;
    flex-direction: column;
    gap: 8px;

    &-row{
      display: flex;
      gap: 8px;
    }

    &-num{
      flex-grow: 1;
      width: 100%;
      text-align: center;
      padding: 30px;
      background-color: black;
      border-radius: 50%;
      color: white;
      font-size: 20px;
      font-weight: 700;

      &-clear{
        background: none !important;
      }
    }
  }
}  
</style>