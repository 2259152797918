<template>
  <div class="widget">
    <div 
      v-if="widgetData.nodeFields[1].properties.visibility"
      v-html="widgetData.nodeFields[1].properties.text"
    ></div>
    <img 
      v-if="widgetData.nodeFields[2].properties.visibility"
      class="widget__img" 
      :src="$staticUrl + widgetData.nodeFields[2].properties.imageUrl " 
      alt=""
    >
    <div class="widget__subtitle" v-html="widgetData.nodeFields[3].properties.text"></div>

    <div class="widget__btns mt-4">
      <ButtonWidget :btnData="this.widgetData.nodeFields[4]" />
      <ButtonWidget :btnData="this.widgetData.nodeFields[5]" />
    </div>
  </div>
  </template>
  
<script>
import ButtonWidget from './components/ButtonWidget.vue'
export default {
    name: "MX_widgetModalWindow",
    props: {
        widgetData: {
            type: Object
        }
    },
    components: { 
      ButtonWidget 
    },
}
</script>
  
<style lang="scss" scoped>
.widget{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;

  &__img{
    width: 100px;
  }

  &__subtitle{
    display: flex;
  }

  &__btns{
    display: flex;
    gap: 10px;
    width: 100%;
  }
}  
</style>