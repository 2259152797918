<template>
  <div
      v-if="!invisibleFields.includes(nodeName)"
    class="tree-node"
    :class="{
      active: isActive,
      activeItem: workNode?.id === activeElementId
    }"
  >
    <div
      :class="!root && isObject(workNode) ? 
        'tree-node__title' : root ? 
        'tree-node__root-title' : ''"
    >
      <span
        v-if="!root && isObject(workNode)"
        style="cursor: pointer"
        class="tree-node__icon"
        @click="toggleList"
      >
        <i
          class="v-icon notranslate mdi"
          :class="isOpen ? 'mdi-chevron-down' : 'mdi-chevron-right'" 
        />
      </span>

      <span
        class="tree-node__node-icon"
        v-if="workNode?.isField && workNode?.nodeType?.toLowerCase() !== 'wrapper'"
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10"> <path style="stroke: none;fill-rule: nonzero;fill: currentColor;fill-opacity: 1;" d="M9.414 9.414H3.73V3.73h5.684Zm-7.258-1.57V2.156h5.688v.989H3.437a.29.29 0 0 0-.292.292v4.407ZM.586 6.27V.586H6.27v.984H1.863a.295.295 0 0 0-.293.293V6.27Zm9.121-3.125H8.43V1.863a.295.295 0 0 0-.293-.293H6.855V.293A.293.293 0 0 0 6.562 0H.293A.295.295 0 0 0 0 .293v6.27c0 .164.133.292.293.292H1.57v1.282c0 .16.133.293.293.293h1.282v1.277c0 .16.128.293.292.293h6.27c.16 0 .293-.133.293-.293v-6.27a.293.293 0 0 0-.293-.292" /></svg>
      </span>

      <span class="tree-node__node-icon" v-if="workNode?.nodeType?.toLowerCase() === 'wrapper'">
        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10"><path style="stroke: none;fill-rule: nonzero;fill: currentColor;fill-opacity: 1;" d="M9.063.938v8.124H.937V.938ZM10 0H0v10h10Zm0 0" /></svg>
      </span>

      <span
        @click="openNode"
        @contextmenu="showContextMenu"
        style="cursor: pointer"
        class="tree-node__node-title"
      >
        <i
            @click.stop="showContextMenu"
            v-if="itemsWithContextMenu.includes(workNode?.nodeType?.toLowerCase())"
            class="v-icon notranslate mdi mdi-dots-vertical-circle-outline small"
        />
        {{ nodeSpanTitle }}
      </span>
    </div>

    <ul v-if="isOpen && isObject(workNode)">
      <li v-if="!Object.keys(workNode).length">Empty</li>
      <template v-else>
        <li v-for="(value, key) in workNode" :key="key">
          <tree-node
            :disabled="disabled"
            :node="value"
            :node-name="key"
            :target-id="targetId" 
          />
        </li>
      </template>
    </ul>

    <v-menu
      v-model="showMenu"
      :position-x="menuX"
      :position-y="menuY"
      absolute
      offset-y
      class="tree-node__context context"
    >
      <v-list>
        <v-list-item
          v-for="(item, index) in items[workNode?.nodeType]"
          :key="index">
          <v-list-item-title
            class="context__item"
            @click="doMenuAction(item)"
          >
            {{ item.title }}
          </v-list-item-title
          >
        </v-list-item>
      </v-list>
    </v-menu>
    
    <NodeInfoModal
      v-model="openDialog"
      :form-info="nodeForm"
      :isChange="!isCreate"
      :type-id="nodeForm.nodeType"
      @createNode="nodeChange"
    />

    <ConfirmModal ref="confirm" />
  </div>
</template>

<script>
import {EventBus} from "@/utils/event-bus";
import NodeInfoModal from "@/components/page-builder/NodeInfoModal.vue";
import pageBuilder from "@/mixins/pageBuilder";
import ConfirmModal from "@/components/utils/ConfirmModal.vue";

export default {
  name: "TreeNode",
  components: {ConfirmModal, NodeInfoModal},
  mixins: [pageBuilder],
  props: ['node', 'root', 'nodeName', 'targetId', 'disabled'],
  data() {
    return {
      isOpen: this.root,
      openDialog: false,
      showMenu: false,
      menuX: 0,
      menuY: 0,
      workNode: this.node,
      treeLoading: false,
      invisibleFields: ['uid', 'isField', 'id', 'name', 'title', 'nodeType', 'properties'],
      nodeForm: {
        nodeType: 2,
        name: '',
        title: '',
        description: '',
      },
      isCreate: false,
      items: {
        'node': [
          {
            title: 'edit node info',
            action: this.editNodeInfo,
            nodeType: this.$store.getters["pageBuilder/types"].find(type => type.prefixName.toLowerCase() === 'node').id
          },
          {
            title: 'add preset',
            action: this.createNode,
            nodeType: this.$store.getters["pageBuilder/types"].find(type => type.prefixName.toLowerCase() === 'preset').id
          },
          { title: 'delete preset DEVELOP' },
          { title: 'duplicate preset DEVELOP' },
        ],
        'screen': [
          {
            title: 'edit screen info',
            action: this.editNodeInfo,
            nodeType: this.$store.getters["pageBuilder/types"].find(type => type.prefixName.toLowerCase() === 'screen').id
          },
          {
            title: 'delete screen',
            action: this.deleteScreen,
            nodeType: this.$store.getters["pageBuilder/types"].find(type => type.prefixName.toLowerCase() === 'screen').id
          },
          // {
          //   title: 'screen settings',
          //   action: this.openElementSettings,
          // },
        ],
        'preset': [
          {
            title: 'edit preset info',
            action: this.editNodeInfo,
            nodeType: this.$store.getters["pageBuilder/types"].find(type => type.prefixName.toLowerCase() === 'preset').id
          },
          {
            title: 'add screen',
            action: this.createNode,
            nodeType: this.$store.getters["pageBuilder/types"].find(type => type.prefixName.toLowerCase() === 'screen').id
          },
          {
            title: 'delete preset',
            action: this.deletePreset,
            nodeType: this.$store.getters["pageBuilder/types"].find(type => type.prefixName.toLowerCase() === 'screen').id
          },
          { title: 'duplicate screen DEVELOP' },
        ],
        'widget': [
          { title: 'test widget DEVELOP' },
        ],
      },
      itemsWithContextMenu: ['screen', 'node', 'preset', 'widget'],
    }
  },
  created() {
    if (this.nodeName === 'nodeFields') {
      this.workNode.map(el => el.isField = true);
    }
  },
  computed: {
    isActive() {
      return this.workNode?.id == this.targetId;
    },
    nodeSpanTitle() {
      return `${this.workNode?.name || this.workNode?.title || this.workNode?.text || this.nodeName} ${!this.isObject(this.workNode) ? this.workNode : ''}`
    },
    activeElementId() {
      return this.$store.getters["pageBuilder/activeElementSettings"];
    }
  },
  methods: {
    createNode() {
      this.nodeForm.name = '';
      this.nodeForm.title = '';
      this.nodeForm.description = '';
      this.isCreate = true;
      this.openDialog = true;
    },
    async deletePreset() {
      if (await this.$refs.confirm.open(
          `delete ${this.workNode.nodeType}`,
          `Are you sure you want to delete ${this.workNode.nodeType} ${this.workNode.name}`
      )) {

        EventBus.$emit('deletePreset', this.workNode);
      }
    },
    async deleteScreen() {
      if (await this.$refs.confirm.open(
          `delete ${this.workNode.nodeType}`,
          `Are you sure you want to delete ${this.workNode.nodeType} ${this.workNode.name}`
      )) {

        EventBus.$emit('deleteScreen', this.workNode);
      }
    },
    editNodeInfo() {
      this.nodeForm.name = this.workNode.name;
      this.nodeForm.title = this.workNode.title;
      this.isCreate = false;
      this.openDialog = true;
    },
    async nodeChange(info) {
      if (this.isCreate && !this.treeLoading) {
        this.treeLoading = true;
        this.$store.dispatch('pageBuilder/createNode', info)
            .then(resp => {
              if (this.workNode.nodeType === 'node') {
                // eslint-disable-next-line no-prototype-builtins
                if (!this.workNode.hasOwnProperty('presets')) {
                  this.workNode.presets = [];
                }
                this.workNode.presets.push(resp.response);
              }
              if (this.workNode.nodeType === 'preset') {
                // eslint-disable-next-line no-prototype-builtins
                if (!this.workNode.hasOwnProperty('screens')) {
                  this.workNode.screens = [];
                }
                this.workNode.screens.push(resp.response);
              }
              this.$store.dispatch('pageBuilder/changeNode', {partId: this.workNode.id, nodePart: this.workNode})
                  .then(nd => {
                    this.workNode = nd.response;
                    this.treeLoading = false;
                  })
                  .catch(e => {
                    console.log("err while save node after create node from explorer - ", e);
                    this.treeLoading = false;
                  })
            })
            .catch(err => {
              this.treeLoading = false;
              console.log("err on create nod from explorer - ", err)
            });
      } else {
        this.$store.dispatch('pageBuilder/changeNode', {partId: this.workNode.id, nodePart: info})
            .then(resp => {
              this.workNode = resp.response;
              this.treeLoading = false;
            })
      }
    },
    toggleList() {
      this.isOpen = !this.isOpen;
    },
    showContextMenu(e) {
      if (!this.itemsWithContextMenu.includes(this.workNode?.nodeType?.toLowerCase())) return;
      e.preventDefault();
      this.showMenu = false
      this.menuX = e.clientX
      this.menuY = e.clientY
      this.$nextTick(() => {
        this.showMenu = true
      })
    },
    doMenuAction(item) {
      if (item.nodeType) {
        this.nodeForm.nodeType = item.nodeType;
      }
      item.action();
    },
    isObject(obj) {
      return obj !== null && typeof obj === 'object';
    },
    openNode() {
      if (this.workNode?.nodeType === 'screen' && !this.treeLoading) {
        EventBus.$emit("openNode", this.workNode.id);
      }
      if (this.workNode?.isField) {
        this.openElementSettings(this.workNode);
      }
    },
    openElementSettings() {
      EventBus.$emit("openSettings", this.workNode);

      const container = document.querySelector(".phone-screen__main");
      const target = container?.querySelector(`[data-id="${this.workNode.id}"]`);

      if (target) {
        const offsetTop = target.offsetTop - container.offsetTop;
        container.scrollTo({ top: offsetTop, behavior: "smooth" });
      }
    },
  },
  watch: {
    node(new_val) {
      this.workNode = new_val;
    },
    disabled(new_val) {
      this.treeLoading = new_val;
    }
  }
}
</script>

<style lang="scss" scoped>
.tree-node {
  ul {
    list-style-type: none;
    padding: 0;

    li {
      word-break: break-word;
      margin-left: 6px;
    }
  }

  .tree-node__title {
    width: 100%;
  }

  &.activeItem {
    background-color: rgba(26, 101, 206, 0.05);

    .tree-node__title {
      color: $main-black;
    }
  }

  &__title {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 16px;
    font-weight: 600;
    color: #5a717c;

    span {
      margin-left: 24px;
      transition: color 0.3s ease;

      &:nth-of-type(2) {
          &:hover {
          color: $main-black;
        }
      }
    }
  }

  &__icon {
    position: absolute;
    left: 0;
    margin-left: 0 !important;

    i {
      font-size: 22px;
    }

    &:hover {
      color: rgba(90, 113, 124, 0.7);
    }
  }

  &__node-icon {
    margin-right: -12px;
  }

  &__root-title {
    font-family: Inter;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    color: #5a717c;
    margin-left: 2px;
    margin-bottom: 2px;
  }

  &__node-title {
    word-break: break-word;
    margin: 4px 0;
  }
}

.context {
  &__item {
    cursor: pointer;
    padding: 5px;

    &:hover {
      background: #eee;
    }
  }
}
</style>