import MX_button from './MX_button.json'
import MX_topBarCenterLogo from './MX_topBarCenterLogo.json'
import MX_widgetCalendar from './MX_widgetCalendar.json'
import MX_widgetEsignature from './MX_widgetEsignature.json'
import MX_widgetMap from './MX_widgetMap.json'
import MX_widgetModalWindow from './MX_widgetModalWindow.json'
import MX_widgetShortCodeLogin from './MX_widgetShortCodeLogin.json'
import MX_widgetSlyderCard from './MX_widgetSlyderCard.json'
import MX_widgetTopBarCenterPageName from './MX_widgetTopBarCenterPageName.json'
import PK_widgetAdvertisingCarouselHomescreen from './PK_widgetAdvertisingCarouselHomescreen.json'
import PK_widgetBiometryModalWindow from './PK_widgetBiometryModalWindow.json'
import PK_widgetButton from './PK_widgetButton.json'
import PK_widgetButtonArrow from './PK_widgetButtonArrow.json'
import PK_widgetButtonCheckbox from './PK_widgetButtonCheckbox.json'
import PK_widgetChatTopBarCenterPageName from './PK_widgetChatTopBarCenterPageName.json'
import PK_widgetModalWindow from './PK_widgetModalWindow.json'
import PK_widgetModalWindowChat from './PK_widgetModalWindowChat.json'
import PK_widgetModalWindowChatResend from './PK_widgetModalWindowChatResend.json'
import PK_widgetModalWindowExternalInfo from './PK_widgetModalWindowExternalInfo.json'
import PK_widgetModalWindowFiltersForHistoryDownload from './PK_widgetModalWindowFiltersForHistoryDownload.json'
import PK_widgetShortCodeLogin from './PK_widgetShortCodeLogin.json'
import PK_widgetSlyderCard from './PK_widgetSlyderCard.json'
import PK_widgetTabBar from './PK_widgetTabBar.json'
import PK_widgetTopBarCenterLogo from './PK_widgetTopBarCenterLogo.json'
import PK_widgetTopBarCenterPageName from './PK_widgetTopBarCenterPageName.json'
import PK_widgetTopbarWithSearch from './PK_widgetTopbarWithSearch.json'
import PK_widgetTransactionsList from './PK_widgetTransactionsList.json'

export default {
  MX_button,
  MX_topBarCenterLogo,
  MX_widgetCalendar,
  MX_widgetEsignature,
  MX_widgetMap,
  MX_widgetModalWindow,
  MX_widgetShortCodeLogin,
  MX_widgetSlyderCard,
  MX_widgetTopBarCenterPageName,
  PK_widgetAdvertisingCarouselHomescreen,
  PK_widgetBiometryModalWindow,
  PK_widgetButton,
  PK_widgetButtonArrow,
  PK_widgetButtonCheckbox,
  PK_widgetChatTopBarCenterPageName,
  PK_widgetModalWindow,
  PK_widgetModalWindowChat,
  PK_widgetModalWindowChatResend,
  PK_widgetModalWindowExternalInfo,
  PK_widgetModalWindowFiltersForHistoryDownload,
  PK_widgetShortCodeLogin,
  PK_widgetSlyderCard,
  PK_widgetTabBar,
  PK_widgetTopBarCenterLogo,
  PK_widgetTopBarCenterPageName,
  PK_widgetTopbarWithSearch,
  PK_widgetTransactionsList
}