<template>
   <v-dialog
     v-model="show"
     persistent
     fullscreen
     hide-overlay
     transition="dialog-bottom-transition"
     no-click-animation
   >
     <v-card class="d-flex flex-column node-edit__card">
       <v-toolbar
         :style="{'background': $colorAdmin}"
         elevation="0"
         class="flex-grow-0 justify-space-between"
       >
         <v-toolbar-title
           v-if="!nodeEditLoading"
           class="d-flex justify-space-between align-center"
         >
           <v-btn
               icon
               dark
               @click="close"
           >
             <v-icon class="light">mdi-close</v-icon>
           </v-btn>
           <span class="grey--text text--lighten-2 text-body-1">{{ $t('node.performanceNode') }}</span>
           <span class="ml-2">{{ settings.title }} {{nodeDisplayName}}<span class="text-caption"> | <span @click="copyId">{{ settings?.id }}</span></span></span>
           <v-btn
               class="ml-2"
               @click="redirectToPageBuilderNode"
           >
             Open in Node Editor
           </v-btn>
         </v-toolbar-title>
         <v-spacer></v-spacer>
         <div v-show="!nodeEditLoading">
           <v-select
              class="mt-5"
              placeholder="lang"
              :items="languages"
              item-text="name"
              item-value="value"
              v-model="language"
              style="max-width: 80px;"
              variant="outlined"
              @change="setLang"
            />
         </div>
       </v-toolbar>

       <SpinnerLoader
         v-if="nodeEditLoading"
         color="deep-purple accent-2"
       />
       <!-- настройка экрана -->
       <ScreenEdit v-else-if="screenId" />
 
       <!-- настройка нод -->
       <div v-else class="d-flex flex-grow-1">
         <!-- пресеты -->
         <graph-editor
             v-if="!!editorData?.connections && findMethod === 1"
             class="node-edit__canvas"
             v-bind.sync="editorData"
             :options.sync="canvasOptions"
             @nodeDblClick="selectNode"
             intoNode
         />
         <template v-else>
           <v-col
               class="node-edit__nav px-6 py-4 align-start"
               ref="column1"
               id="node-edit__column1"
           >
             <h2 class="text-h5">{{ $t('node.presets') }}</h2>
             <PresetSettings @hideGeneral="isGeneral = $event" :nodeId="settings.id"/>
           </v-col>
           <!-- left-resize -->
           <div
               class="node-edit__slider"
               @mousedown.stop="dragResize($event, 'column1', 'left')"
           >
             <div class="node-edit__slider__line"></div>
             <div
                 v-if="isLeftExpand"
                 class="node-edit__expand-arrow node-edit__expand-arrow_right"
                 @click="isLeftExpand = false; expandBlock('column1')"
             >
               <v-icon>
                 mdi-arrow-expand-right
               </v-icon>
             </div>
           </div>
           <!-- экраны -->
           <v-col class="node-edit__screens px-6 py-4">
             <div class="node-edit__content">
               <h2 class="text-h5">{{ $t('node.screens') }}</h2>
               <div v-if="settings.id !== 999">
                 <NodeScreen
                     v-for="setting in settings.screens"
                     :key="setting.name"
                     :canHide="setting.name.includes('hello') || setting.name.includes('anket')"
                     :screen-settings="settings.name.includes('anket')"
                     :setting="setting"
                     :active-actions="true"
                     @openScreenSettings="openScreenSettings"
                 />
               </div>
               <div v-else>
                 <v-img width="150" height="280" contain src="@/assets/images/main-page.png" />
               </div>
             </div>
           </v-col>
           <!-- right-resize -->
           <div
               class="node-edit__slider"
               @mousedown.stop="dragResize($event, 'column3', 'right')"
           >
             <div class="node-edit__slider__line"></div>
             <div
                 v-if="isRightExpand"
                 class="node-edit__expand-arrow node-edit__expand-arrow_left"
                 @click="isRightExpand = false; expandBlock('column3')"
             >
               <v-icon>
                 mdi-arrow-expand-left
               </v-icon>
             </div>
           </div>
         </template>
         <!-- условные переходы -->
         <v-col
           class="node-edit__nav px-6 py-4 align-start"
           ref="column3"
           :style="heightColon"
         >
           <div class="d-flex align-center mb-2">
            <h2 class="text-h5 mr-2 mb-0 font-weight-bold">{{ $t('node.conditions.title') }}</h2>
           </div>
           <div class="d-flex flex-column" id="into-column3">
              <NodeTransitions />
            </div>
         </v-col>
       </div>

       <!-- модалка -->
       <ConfirmModal ref="confirm" />
     </v-card>
   </v-dialog>
 </template>


<script>
import NodeScreen from "@/components/nodes/nodeScreens/NodeScreen.vue";
import { EventBus } from "@/utils/event-bus";
import ConfirmModal from "@/components/utils/ConfirmModal";
import PresetSettings from "@/components/nodes/nodePresets/PresetSettings.vue";
import ScreenEdit from "@/components/nodes/nodeScreens/ScreenEdit.vue";
import modalMixin from "@/mixins/modalMixin";
import popupPosition from "@/mixins/popupPosition";
import NodeTransitions from "@/components/nodes/nodeTransitions/NodeTransitions.vue";
import copyId from '@/mixins/copyId.js';
import systemLang from '@/mixins/systemLang';
import SpinnerLoader from '@/components/UI/SpinnerLoader.vue'

export default {
   name: 'NodeEdit',
   components: {
      ScreenEdit,
      PresetSettings,
      ConfirmModal,
      NodeScreen,
      NodeTransitions,
      SpinnerLoader
   },
   props: {
      node: {
        required: true,
      },
      outGroupContext: {
        require: false
      },
      parentNodeId: {
        require: false
      },
      localContext: {
        required: true,
      }
   },
   mixins: [modalMixin, popupPosition, systemLang, copyId],
   data() {
      return {
         isGeneral: true,
         editScreenId: false,
         screenDialog: false,
         isLeftExpand: false,
         isRightExpand: false,
         preset: localStorage.getItem(`preset-for-node-${this.node.db_id}`) ? Number(localStorage.getItem(`preset-for-node-${this.node.db_id}`)) : null,
         newItemPreset: {
            item: '',
            index: ''
         },
        heightOfColumn3: 0,
        canvasOptions: {
          loading: false
        },
      }
   },
   computed: {
      settings() {
        return this.$store.getters["node/settings"]
      },
      defaultSettings() {
        return this.$store.getters["node/defaultSettings"];
      },
      screenId() {
        return this.$store.getters["node/currentScreenId"];
      },
      outerConnections() {
        return this.$store.getters["node/outerConnections"];
      },
      nodeEditLoading() {
        return this.$store.getters["node/nodeEditLoading"];
      },
      /* eslint-disable */
      connections() {
        const connections = this.outerConnections.length
          ? this.outerConnections.filter(id => !!id).join(',')
          : null

        this.$store.dispatch('node/setAvailableScreens', this.outerConnections ?? [])
        this.$store.dispatch('node/setConnections', connections)

        return connections
      },
      penultimateNodeInGroup(){
        const idGroupOutput = Object.values(this.localContext.connections[this.node.id])[0][0].endNodeId
        if(this.localContext.nodes[idGroupOutput].type === "groupOutput") return this.localContext.nodes[idGroupOutput].id
      },

      heightColon: {
        cache: false,
        get(){
          const height = this.$store.getters['node/heightOfPopup']
          const column1 = document.querySelectorAll('#node-edit__column1')
          const intoColumn3 = document.querySelectorAll('#into-column3')

          if(intoColumn3[0]?.scrollHeight > height && height > 0) return `height: ${intoColumn3[0]?.scrollHeight + intoColumn3[0]?.offsetTop}px`
          
          return height > column1[0]?.scrollHeight 
            ? `height: ${height}px` 
            : `height: ${column1[0]?.scrollHeight}px`
        }
      },
     editorData: {
       cache: false,
       get(){
         return {...this.$store.getters['processes/getProcessForAction'], availableNodes: this.$store.getters["node/availableNodes"]}
       }
     },

     findMethod: {
       cache: false,
       get() {
         return this.$store.getters['node/findMethod']
       }
     },

     nodeDisplayName(){
       const nodes = this.$store.getters["processes/processN8N"].nodes
       const displayName = Object.values(nodes)
           .find(node => node.db_id === this.settings.id)?.displayName

       return this.settings.title === displayName
        ? ''
        : `(${displayName})`
     }
    },
    // set node
    async created() {
      EventBus.$on('setOverflowY', visible => {
        const dialog = document.querySelectorAll('.v-dialog--fullscreen')

        if(visible) dialog[0].style.overflowY = 'hidden'
        else dialog[0].style.overflowY = 'auto'
      })
      await this.setSettings();
   },
  methods: {
    redirectToPageBuilderNode() {
      const payload = {
        node: this.node,
        outGroupContext: this.outGroupContext,
        parentNodeId: this.parentNodeId,
        localContext: this.localContext,
      }
      this.$store.dispatch('processes/setTempNodeEditData', payload);
      this.$router.push(`/page-builder/${this.node.db_id}`);
    },
      /*
       * expand block to max block width
       * */
      expandBlock(blockRef) {
        let block = this.$refs[blockRef];
        if (blockRef === 'column1') {
          block.style.width = window.screen.width * 0.25 + 'px'
          block.style.display = 'flex'
          return block.style['flex-direction'] = 'column'
        }
        block.style.width = window.screen.width * 0.25 + 'px'
        block.style.display = 'block'
      },
      /*
       * change width of node edit blocks while dragging slider
       * */
      dragResize: function (e, blockRef, site) {
         let dragX = e.clientX;
         let block = this.$refs[blockRef];
         const self = this;
         let minMove = window.screen.width * 0.03

         document.onmousemove = function onMouseMove(e) {
            if (site === 'left') {
               if ((block.offsetWidth - dragX) > minMove) {
                  block.style.display = 'none';
                  self.isLeftExpand = true;
               }
               block.style.width = block.offsetWidth + e.clientX - dragX + "px";
               if (block.offsetWidth < 10) {
                  self.isLeftExpand = true;
               } else {
                  self.isLeftExpand = false;
               }
            } else {
               if ((block.offsetWidth - (window.innerWidth - dragX)) > minMove) {
                  block.style.display = 'none';
                  self.isRightExpand = true;
               }

               block.style.width = block.offsetWidth + (window.innerWidth - e.clientX) - (window.innerWidth - dragX) + 'px';
               if (block.offsetWidth < 10) {
                  self.isRightExpand = true;
               } else {
                  self.isRightExpand = false;
               }
            }
            dragX = e.clientX;
         }

         document.onmouseup = () => document.onmousemove = document.onmouseup = null;
      },

      /*
       * reset node settings
       * */
      async resetSettings() {
        if (await this.$refs.confirm.open(
            "Сброс настроек ноды",
            "Вы уверены, что хотите поставить дефолтные настройки ноды?"
          )) {
          await this.$store.dispatch('node/setSettings', {
            id: null,
            preset: null,
            type: null
          });
          // emit global reset settings with bus
          EventBus.$emit('nodeGlobalReset');
        }
      },
      async setSettings() {
        this.$store.commit('node/setNodeEditLoading', true);
        await this.$store.dispatch("node/setSettings", {
          id: this.node.db_id,
          preset: this.preset,
          type: this.node.type,
          connections: this.connections
        })
        this.$store.commit('node/setNodeEditLoading', false);
      },
      // save node settings
      async saveNode() {
        await this.$store.dispatch('node/saveNode', this.settings);
      },
      // open touched screen settings
      openScreenSettings(screenId) {
        this.editScreenId = screenId;
        this.$store.dispatch('node/currentScreenId', screenId)
      },
      // close settings modal
      close() {
        this.show = false;
        this.$store.dispatch('node/setCurrentField', null);
        this.$store.dispatch('node/setCurrentScreenId', null);
      },
      updateConditions(){
        this.$forceUpdate()
      },

      selectNode(node){
        this.$store.dispatch("node/setSelectNodeOnCanvas", node)
      }
   },
   beforeDestroy(){
     EventBus.$off('setOverflowY')
   }
}
</script>

<style scoped lang="scss">
.node-edit {
    &__card {
      background-color: #f8f9fa !important;
    }

    &__nav {
      position: relative;
      background-color: #ffffff;
      box-shadow: 0 0 32px 0 rgb(136 152 170 / 15%);
      min-height: 100vh;
      min-width: 400px;
    }

    &__views {
      row-gap: 0px;
      column-gap: 60px;
      .phone-screen__wrapper{
        margin-bottom: -5em;
      }
    }

    &__slider {
      position: relative;
      width: 1px;
      border: none;
      cursor: col-resize;
      user-select: none;
      text-align: center;

      &__line {
        width: 1px;
      }
    }

    &__expand-arrow {
      transition: .5s all ease;
      cursor: pointer;
      position: absolute;
      z-index: 10;
      top: 50%;

      &_right {
        right: -30px;
      }

      &_left {
        left: -30px;
      }
    }

    &__portal-target {
      position: absolute;
      height: 100%;
      width: 100%;
      background: rgba(#212121, 0.4);
      top: 0;
      left: 0;
      z-index: 8;
      display: flex;
      justify-content: center;
      align-items: start;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    // ?
    &__screens {

      &-move,
      &-enter-active,
      &-leave-active {
        transition: all 0.5s ease;
      }

      &-enter-from,
      &-leave-to {
        opacity: 0;
        transform: translateX(30px);
      }

      &-leave-active {
        position: absolute;
      }
    }
    &__canvas{
      height: calc(100vh - 80px) !important;
      margin: 10px;
    }
  }
.separator-line{
  background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(140,161,203,1) 50%, rgba(255,255,255,1) 100%);
  width: 100%;
  height: 1px;
  &__hide{
    display: none;
  }
}

.hidden-scroll{
  overflow-y: hidden !important;
}
</style>
