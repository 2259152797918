<template>
  <div v-if="widgetData.nodeFields[0].properties.visibility">
    <img class="widget__img" src="@/assets/images/map.png" alt="">
  </div>
</template>

<script>
  export default {
    props: {
        widgetData: {
            type: Object
        }
    },
  }
</script>

<style lang="scss" scoped>
.widget{
  &__img{
    width: 100%;
  }
}
</style>