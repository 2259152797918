<template>
  <div class="text-editor" :style="{width: parentWidth - 48 + 'px'}">
      <div class="text-editor__toolbar" :id="toolBarId || 'toolBarId'">
        <div class="text-editor__toolbar__block">
          <button class="text-editor__toolbar__option-button" @click="modifyText('underline')">
            <v-icon>mdi-format-underline</v-icon>
          </button>
          <button class="text-editor__toolbar__option-button" @click="modifyText('italic')">
            <v-icon>mdi-format-italic</v-icon>
          </button>

          <button class="text-editor__toolbar__option-button" @click="openLinkField">
            <v-icon>mdi-link</v-icon>
          </button>

          <select v-model="fontColor" class="text-editor__toolbar__select-color">
            <option v-for="color,i in colors" :key="i" :value="color.value">{{ color.name }}</option>
          </select>
          <select v-model="fontWeight" class="text-editor__toolbar__select-weight">
            <option v-for="weight,i in weights" :key="i" :value="weight.value">{{ weight.name }}</option>
          </select>
        </div>

        <div class="text-editor__toolbar__block">
          <select v-model="fontFormat" class="text-editor__toolbar__select-format">
            <option v-for="font,i in format" :key="i" :value="font.value">{{ font.name }}</option>
          </select>

          <button class="text-editor__toolbar__option-button" @click="clearText">
            <v-icon>mdi-delete-variant</v-icon>
          </button>
        </div> 
      </div>
      <div 
        v-if="isOpenLink"
        class="text-editor__link"
      >
        <input type="text" 
          placeholder="Input link"
          v-model="link"
        >
        <button class="text-editor__toolbar__option-button-success" @click="modifyLink">
          <v-icon>mdi-check</v-icon>
        </button>
        <button class="text-editor__toolbar__option-button-cancel" @click="closeLinkField">
          <v-icon>mdi-close</v-icon>
        </button>
        
      </div>
      
      <quillEditor
        ref="quillEditor"
        v-model="editData"
        @input="emitInput($event)"
        :options="editorOption"
      /> 
    </div>
</template>

<script>
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'

import customClassQuill from './customClassQuill.js'

export default{
  name: 'textEditorQuill',
  mixins: [customClassQuill],
  props: {
    value: {
      type: String
    },
    parentWidth: {
      type: Number
    },
    toolBarId: {
      type: String,
    }
  },
  components: {
    quillEditor
  },
  data(){
    return{
      editorOption: {  
        modules: {
          toolbar: this.toolBarId ? `#${this.toolBarId}` : '#toolBarId',
        },
        theme: 'snow'
      },
      editData: null,
      fontWeight: '',
      fontColor: '',
      fontFormat: '',
      isOpenLink: false,
      link: '',

      weights: [
        {name: 'Regular', value: 'regular'}, 
        {name: 'Medium', value: 'medium'}, 
        {name: 'Bold', value: 'bold'}
      ],
      colors: [
        {name: 'White', value: 'light-first'}, 
        {name: 'Black', value: 'dark-first'}, 
        {name: 'Light Black', value: 'dark-second'},
        {name: 'Purple', value: 'major-first'},
        {name: 'Vivid purple', value: 'major-second'},
        {name: 'Green', value: 'major-third'},
        {name: 'DarkBlue', value: 'major-fourth'},
        {name: 'Gray 1', value: 'minor-first'},
        {name: 'Gray 2', value: 'minor-second'},
        {name: 'Error', value: 'accent-error'}
      ],
      format: [
        {name: 'Vernum', value: 'font-text-vernum'}, 
        {name: 'Apprsum', value: 'font-text-apprsum'}, 
        {name: 'Availsum', value: 'font-text-availsum'}, 
        {name: 'Ctabtn', value: 'font-text-ctabtn'}, 
        {name: 'Btn', value: 'font-text-btn'}, 
        {name: 'Title', value: 'font-text-title'}, 
        {name: 'Large', value: 'font-text-large'}, 
        {name: 'Body', value: 'font-text-body'}, 
        {name: 'Small', value: 'font-text-small'}, 
        {name: 'Interlinear', value: 'font-text-interlinear'}, 
        {name: 'Navbar', value: 'font-text-navbar'},
        {name: 'H1', value: 'font-text-h1'}, 
        {name: 'H2', value: 'font-text-h2'}, 
        {name: 'H3', value: 'font-text-h3'}, 
        {name: 'H4', value: 'font-text-h4'}, 
        {name: 'H5', value: 'font-text-h5'}, 
        {name: 'H6', value: 'font-text-h6'}, 
      ],
      modText: {
        'underline': 'mod-text-underline',
        'italic': 'mod-text-italic',
      },
    }
  },
  watch: {
    fontFormat(newVal){
      if(newVal) this.quill.formatText(0, this.quill.getLength() - 1, 'format', newVal)

      this.fontFormat = ''
    },

    fontWeight(newVal){
      if(newVal) this.quill.format('weight', 'mod-text-weight-' + newVal)

      this.fontWeight = ''
    },

    fontColor(newVal){
      if(newVal) this.quill.format('color', 'color-text-' + newVal)

      this.fontColor = ''
    }
  },
  computed: {
    quill: {
      cache: false,
      get(){
        return this.$refs.quillEditor.quill
      }
    }
  },
  methods: {

    /**
     * get format of selected text
     * @param {string} type - format type
     */

    getFormatText(type){    
      const format = this.quill.getFormat()
      return Object.keys(format).includes(type)
    },

    /**
     * clear text of all styles
     */

    clearText(){
      const clearText = this.quill.editor.delta.ops.reduce((allText, part) => {
        allText += part.insert
        return allText
      }, '')

      this.quill.setText(clearText)
    },

     /**
     * function to modify selected text
     * @param {string} type - format type
     */

    modifyText(type){
      const className = this.modText[type]
      const marked = this.getFormatText(type)

      if(!marked) return this.quill.format(type, className)
      this.quill.format(type, '')
    },

     /**
     * open field for edit link
     * if selected text have link, remove link
     */

    openLinkField(){
      const marked = this.getFormatText('link')

      if(marked) return this.quill.format('link', '')
      this.isOpenLink = !this.isOpenLink
    },

     /**
     * set link for selected text
     */

    modifyLink(){
      this.isOpenLink = false

      this.quill.format('link', this.link)
      this.link = ''
    },

     /**
     * close field for edit link
     */

    closeLinkField(){
      this.link = ''
      this.isOpenLink = !this.isOpenLink
    },

     /**
     * send change to preview
     * @param e - event
     */

    emitInput(e){
      const text = e
        .split('<p><br></p>').join('<br>')
        .split('<p>').join('<span>')
        .split('</p>').join('</span><br>')
          
      this.$emit('input', text)
    }
  },

  created(){
    this.editData = this.value
  },
}

</script>

<style lang="scss">

// this variables must match for structure with: weights, colors, format
$text-colors: (
  white: #FFFFFF,
  black: #0E1A22,
  light_black: #242424,
  purple: #B51ACE,
  vivid_purple: #E127FF,
  green: #43B02A,
  darkBlue: #5A717C,
  gray1: #707070,
  gray2: #A0A0A0,
  error: #E32A5F,  
);

$text-weight: ( Regular, Medium, Bold );

$text-format: (
  Vernum, Apprsum, Availsum, Ctabtn, Btn,
  Title, Large, Body, Small, Interlinear,
  Navbar, H1, H2, H3, H4, H5, H6
);

.ql-editor{
  display: flex;
  flex-direction: column;
}
.text-editor {

  // fonts for show weight text
  // .quill-editor .ql-editor{
  //   .mod-text-weight-regular, 
  //   .mod-text-weight-medium, 
  //   .mod-text-weight-bold{
  //     font-family:'Open Sans', sans-serif !important;
  //   }
  // }

  &__link{
    display: flex;
    justify-content: space-around;
    padding: 2px 4px;
    background: white;
    margin: 0;
    border: 1px solid #b5b5b5;
    border-bottom: 0;
    width: 100%;

    input{
      width: 85%;
      padding: 2px;
      font-size: 13px;
      outline:none;
    }
  }

  .ql-snow.ql-toolbar:after, .ql-snow .ql-toolbar:after {
      display: none;
  }
  .ql-toolbar.ql-snow{
    border-bottom: 0;
    border-radius: 8px 8px 0 0;
  }
  .ql-container.ql-snow {
    border-radius: 0 0 8px 8px;
  }

  &__toolbar {
    display: flex;
    align-items: center;
    justify-content: space-between;


    &__select-weight{
      .ql-picker-label:before {
        content: "Weight"; 
        margin-right: 20px;
      }

      span.ql-picker-item{
        @for $i from 1 through length($text-weight) {
          $name: nth($text-weight, $i);
          
          &:nth-child(#{$i}):before {
            content: "#{$name}";
          }
        }
      }

    }

    &__select-color{
      .ql-picker-label:before {
        content: "Color";
        margin-right: 20px;
      }

      &.ql-picker.ql-expanded .ql-picker-options {
        display: flex !important;
        gap: 2px !important;
        width: 105px !important;
        flex-wrap: wrap !important;
      }

      span.ql-picker-item{
        width: 15px;
        height: 15px;
        border: 1px solid;

        @for $i from 1 through length($text-colors) {
          $name: nth(map-keys($text-colors), $i);
          $color: map-get($text-colors, $name);
        
          &:nth-child(#{$i}) {
            background-color: $color;
          }
        }
      }
    }

    &__select-format{
      .ql-picker-label:before {
        content: "Format";
        margin-right: 20px;
      }

      span.ql-picker-item{
        @for $i from 1 through length($text-format) {
          $name: nth($text-format, $i);
          
          &:nth-child(#{$i}):before {
            content: "#{$name}";
          }
        }
      }
    }

    &__block{
      display: flex;
      flex-wrap: wrap;
      align-items: baseline;
      gap: 8px;

      .v-icon{
        font-size: 20px;
      }
    }

    &__option-button{
      &-success{
        &:hover{
          i::before {
            color: #14cc00;
          }
        }
      }
      &-cancel{
        &:hover{
          i::before {
            color: #cc000a;
          }
        }
      }
      &:hover{
        i::before {
          color: #0065cc;
        }
      }
    }
  }
}

// titles
.font-text{
  &-h1, &-h2, &-h3, &-h4, &-h5, &-h6{
    font-weight: 600;
    font-family: inter;
  }
  &-h1{ font-size: 28px;}
  &-h2{ font-size: 22px;}
  &-h3{ font-size: 20px;}
  &-h4{ font-size: 16px;}
  &-h5{ font-size: 15px;}
  &-h6{ font-size: 12px;}
}

//style text
.mod-text{
  &-italic{
    font-style: italic;
  }
  &-underline{
    font-style: underline;
  }
  &-weight{
    &-regular{
      font-weight: 400;
    }
    &-medium{
      font-weight: 500;
    }
    &-bold{
      font-weight: 600;
    }
  }
  &-link{
    text-decoration: underline;
    color: rgb(0, 119, 255);
    cursor: pointer;
  }
}

// colors
.color-text{
  &-light-first{
    color: #FFFFFF;
  }
  &-dark{
    &-first{
      color: #0E1A22;
    }
    &-second{
      color: #242424;
    }
  }
  &-major{
    &-first{
      color: #B51ACE;
    }
    &-second{
      color: #E127FF;
    }
    &-third{
      color: #43B02A;
    }
    &-fourth{
      color: #5A717C;
    }
  }
  &-minor{
    &-first{
      color: #707070;
    }
    &-second{
      color: #A0A0A0;
    }
  }
  &-accent-error{
    color: #E32A5F;
  }
}
</style>